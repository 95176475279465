import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const dateFormat = (dt, type) => {
    const date = new Date(dt);
    if (type == 'date') {
        const formatted = date.toLocaleString('en', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        return formatted
    } else if (type == 'dmy') {
        const formatted = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        })
        return formatted
    } else if (type == 'hhmm') {
        const formatted = date.toLocaleString('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })
        return formatted
    } else {
        const formatted = date.toLocaleString('en', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })

        return formatted
    }
}

const timeAgo = (time) => {
    const timeDifferenceInSec = (new Date() - new Date(time)) / 1000;
    if (timeDifferenceInSec >= 86400) return `${Math.floor(timeDifferenceInSec / 86400)} day's ago`
    if (timeDifferenceInSec >= 3600) return `${Math.floor(timeDifferenceInSec / 3600)} hour's ago`
    if (timeDifferenceInSec >= 60) return `${Math.floor(timeDifferenceInSec / 60)} minute's ago`
    return "a few seconds ago"
}


const disabledDate = (current) => {
    return current && current < moment().startOf('day')
}

const generateRandomText = (minLength, maxLength) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ';
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;
};

const openInNewTab = (url, params) => {
    // const sessionKey = `${Date.now()}`

    // sessionStorage.setItem(sessionKey,JSON.stringify(params));
    // window.open(`${url}?search=${sessionKey}`, '_blank')

    localStorage.setItem('searchParams', JSON.stringify(params));
    window.open(url, '_blank');
}

const avgRatingCalculate = (reviews) => {
    let total = 0;
    let weightSum = 0;

    reviews.forEach(review => {
        total += 1;
        weightSum += Math.floor(review.rating);
    });

    return total > 0 ? (weightSum / total).toFixed() : 0;
}

const reviewOfSummary = (reviews) => {
    const ratingCounts = Array(5).fill(0);
    let totalRating = 0;

    reviews.forEach(review => {
        const rating = Number(review.rating)
        if (rating >= 1 && rating <= 5) {
            const round = Math.round(rating)
            ratingCounts[round - 1] += 1;
            totalRating += rating
        }
    });

    const totalReviews = reviews.length;
    const avgRating = totalReviews > 0 ? (totalRating / totalReviews).toFixed(1) : 0

    const summary = ratingCounts.map((count, index) => {
        const value = totalReviews > 0 ? (count / totalReviews) * 100 : 0;
        return { rating: index + 1, value, count };
    });

    return { summary, avgRating }
}


const useCountryGet = () => {
    const [country1, setCountry] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await fetch(`https://get.geojs.io/v1/ip/geo.json`);
                const data = await response.json();

                setCountry(data.country);
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLocation();
    }, []);

    return { country1, loading };
};

const customStyle = {
    placeholder: (provied) => ({
        ...provied,
        fontSize: '12px'
    }),
    singleValue: (provied) => ({
        ...provied,
        fontSize: '12px'
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
    }),
    menuPortal: base => ({ ...base, zIndex: 98889999, }),
    control: (provided) => ({
        ...provided,
        height: '35px',
        minHeight: '35px',
        borderColor: 'transparent',
        boxShadow: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    menuList: (provided) => ({
        ...provided,
        fontSize: 12,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }),
}

const lookingForOptions = ['Holiday Packages', 'Hotel', 'Vehicle', 'Guider', 'Photographer', 'Tickets', 'Visa', 'Activities'];


const carTypes = [
    { icon: '/icons/tempo.png', name: 'Tempo Traveller', id: '6753bd96553d654548c3c726' },
    { icon: '/icons/sedan.png', name: 'Sedan', id: '6753b87e553d654548c3c71a' },
    { icon: '/icons/suv1.png', name: 'SUV', id: '6753bbc2553d654548c3c720' },
    { icon: '/icons/hatchback-car.png', name: 'Hatchback', id: '6753bb7b553d654548c3c71d' },
    { icon: '/icons/bus.png', name: 'Bus', id: '6753bd17553d654548c3c723' },
    { icon: '/icons/bike.png', name: 'Bike/Scooty', id: '6753be4e553d654548c3c72f' },
];

const fuelType = [
    { name: 'DISEL', id: 1 },
    { name: 'PETROL', id: 2 },
    { name: 'CNG', id: 3 },
    { name: 'ELECTRIC', id: 4 },
    { name: 'PETROL-CNG', id: 5 },
    { name: 'OTHER', id: 6 }
];

const amenitiesArray = [
    { name: 'Water', id: 1 },
    { name: 'Mobile Charging', id: 2 },
    { name: 'Newspaper', id: 3 },
    { name: 'Sanitizer', id: 4 },
    { name: 'Music', id: 5 },
];

const checkSlug = (slug, categories) => {

    console.log('slug', slug, 'cate:', categories);
    

    let slugSatus = false;
    let slugId = '';
    categories?.forEach((category) => {
        const cateSlug = category.name.toLowerCase().replace(/\s+/g, '-');
        if (cateSlug === slug) {
            slugSatus = true;
            slugId = category._id
        }
    })

    return { slugSatus, slugId }
}




export { disabledDate, carTypes, checkSlug, fuelType, amenitiesArray, lookingForOptions, timeAgo, generateRandomText, openInNewTab, avgRatingCalculate, reviewOfSummary, useCountryGet, customStyle }