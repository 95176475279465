import React, { useCallback, useState } from 'react';
import '../../CSS/style.css';
import { tstSuccess } from '../../Componets/HotToast';
import { enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import SignInModal from '../Auth/SignInModal';

const key = process.env.REACT_APP_KEY;


const ContactForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [err, setErr] = useState({})


    const val = () => {
        const err = {};

        if (!value.trim()) {
            err.name = 'Enter valid name!'
        }
        if (!/^[^\s@]+@gmail\.com$/.test(email)) {
            err.email = 'Invalid email format!'
        }

        if (subject.trim().length < 5) {
            err.subject = 'Please enter valid subject!'
        }

        if (description.trim().length < 10) {
            err.msg = 'Please enter minimum 10 characters!'
        }


        setErr(err)
        return Object.keys(err).length == 0;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const request = {
            fullName: value,
            email: email,
            subject: subject,
            message: description,
        }

        const enc1 = enc(request, key)

        if (val()) {
            CommonAPI(enc1, 'POST', 'getInTouch', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            setValue('')
            setEmail('')
            setSubject('')
            setDescription('')
            tstSuccess('Form submitted successfully')
        } else {
            if ((res.message == 'Invalid token' || res.message == 'No token provided')) {
                setIsModalOpen(true)
            }

        }
    })


    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                    <div className="contact-wrap bg-white w-100 p-md-5 p-4">
                                        <h3 className="mb-4 Rubik-Regular text-secondary">Get in touch</h3>
                                        <div id="form-message-warning" className="mb-4"></div>
                                        <div id="form-message-success" className="mb-4">
                                            Your message was sent, thank you!
                                        </div>
                                        <form onSubmit={handleSubmit} id="contactForm" name="contactForm" className="contactForm">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="label m-0" htmlFor="name">Full Name</label>
                                                        <input type="text" value={value} onChange={(e) => setValue(e.target.value)} className="form-control mt-0" name="name" id="name" placeholder="Name" />
                                                        {err.name && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.name}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="label mb-0" for="email">Email Address</label>
                                                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name="email" id="email" placeholder="Email" />
                                                        {err.email && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.email}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="label mb-0" for="subject">Subject</label>
                                                        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" name="subject" id="subject" placeholder="Subject" />
                                                        {err.subject && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.subject}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="label mb-0" for="#">Message</label>
                                                        <textarea name="message" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" id="message" cols="30" rows="4" placeholder="Message"></textarea>
                                                        {err.msg && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.msg}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="submit" value="Send Message" className="btn bg-gradient-thm text-white" />
                                                        <div className="submitting"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                                    <div className="info-wrap bg-gradient-thm w-100 p-md-5 p-4">
                                        <h3 className='Rubik-SemiBold text-white'>Let's get in touch</h3>
                                        <p className="mb-4 Rubik-Regular text-white">We're open for any suggestion or just to have a chat</p>

                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon me-2 d-flex align-items-center justify-content-center">
                                                <span className="fa-brands fa-instagram fs_14"></span>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span className='Rubik-Medium'>Instagram:</span> <a target='_blank' href="https://www.instagram.com/travellinglark">@travellinglark</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon me-2 d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane fs_14"></span>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span className='Rubik-Medium'>Email:</span> <a target='_blank' rel="noopener noreferrer" href="mailto:support@travellinglark.com">support@travellinglark.com</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon me-2 d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe fs_14"></span>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span className='Rubik-Medium'>Website</span> <a target='_blank' rel="noopener noreferrer" href="#">travellinglark.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

        </section>
    );
};

export default ContactForm;