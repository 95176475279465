import React from 'react'

function RefundCancel() {
    return (
        <div className='mt-7 pt-4 container'>
            {/* Main Heading */}
            <p className='Source_Bold fs-1 mt-2 m-0' style={{ color: '#0d0a3a' }}>
                Cancellation & Refund Policy
            </p>
            <p className='Source_Regular fs_13 text-secondary'>Last updated: July 12th, 2024</p>

            <p className='Source_Bold text_gray'>
                For Short Duration/Weekend Trips (Trips less than 3 Days)
            </p>
            <p className='Source_Regular fs_15 text-secondary'>
                The refund of the amount would be processed as per deduction guidelines given below:
                <br /><br />
                Cancellations up to 60 days prior to departure date - 0% deduction<br />
                Between 60 days to 45 days prior to departure - 50% deduction<br />
                Between 45 days to 30 days prior to departure - 70% deduction<br />
                Between 30 days to 15 days prior to departure - 80% deduction<br />
                Less than 15 days of departure - no cash refund<br />
                Other cookies may be stored to your computers hard drive by external vendors when this website uses referral programs, sponsored links or adverts. Such cookies are used for conversion and referral tracking and typically expire after 30 days, though some may take longer. No personal information is stored, saved or collected.
            </p>

            {/* Heading */}
            <p className='Source_Bold text_gray'>
                For Long Duration & International Trips (Trips more than 3 Days)
            </p>
            <p className='Source_Regular fs_15 text-secondary'>
                The refund of the amount would be processed as per deduction guidelines outlined below:
                <br /><br />
                Cancellations up to 90 days prior to departure date - 0% deduction<br />
                Between 90 days to 45 days prior to departure - 60% deduction<br />
                Between 45 days to 15 days prior to departure - 80% deduction<br />
                Less than 15 days of departure - no cash refund<br />
            </p>

            {/* Heading */}
            <p className='Source_Bold text_gray'>
                Rules Applicable for Change/Cancellation of Booking by the Participant
            </p>


            {/* Heading */}
            <p className='Source_Bold text_gray'>

            </p>
            <p className='Source_Regular fs_14 text-secondary'>

            </p>

            {/* Heading */}
            <p className='Source_Bold text_gray'>

            </p>
            <p className='Source_Regular fs_14 text-secondary'>

            </p>

            {/* Heading */}
            <p className='Source_Bold text_gray'>

            </p>
            <p className='Source_Regular fs_14 text-secondary'>

            </p>
        </div>
    )
}

export default RefundCancel