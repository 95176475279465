import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('packages');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <ul className="nav nav-tabs-booking">
                <li className="nav-item-booking rd_50">
                    <Link
                        className={`nav-link-booking text-decoration-none d-flex align-items-center  Source_Regular px-2-5 mr_10 p-1 rd_50 text-secondary ${activeTab === 'packages' ? 'active' : ''}`}
                        onClick={() => handleTabClick('packages')}
                    >
                        <span className="material-symbols-sharp text-decoration fs_13 mr_5 pt-0" style={{ marginTop: -2 }}>package_2</span>
                        Packages
                    </Link>
                </li>
                <li className="nav-item-booking rd_50">
                    <Link
                        className={`nav-link-booking text-decoration-none d-flex align-items-center  Source_Regular px-2-5 mr_10 p-1 rd_50 text-secondary ${activeTab === 'hotel' ? 'active' : ''}`}
                        onClick={() => handleTabClick('hotel')}
                    >
                        <span className="material-symbols-sharp text-decoration fs_13 mr_5 pt-0" style={{ marginTop: -2 }}>apartment</span>
                        Hotels
                    </Link>
                </li>
                <li className="nav-item-booking rd_50">
                    <Link
                        className={`nav-link-booking text-decoration-none d-flex align-items-center  Source_Regular px-2-5 mr_10 p-1 rd_50 text-secondary ${activeTab === 'vehicle' ? 'active' : ''}`}
                        onClick={() => handleTabClick('vehicle')}
                    >
                        <span className="fa fa-car text-decoration fs_12 mr_5 pt-0" style={{ marginTop: -2 }}></span>
                        Vehicle
                    </Link>
                </li>
            </ul>

            <div className="tab-content bg-warning1 mt-4">
                {activeTab == 'packages' ? (
                    <div className={`tab-pane bg-warning1 d-flex align-items-center flex-column ${activeTab === 'packages' ? 'active' : ''}`}>
                        <img src='/searching_nodata.avif' alt='' className='img-fluid h_300px' />
                        <p className='Source_Bold text_gray ms-5 mr_10'>Packages</p>
                    </div>
                ) : activeTab == 'hotel' ?(
                    <div className={`tab-pane bg-warning1 d-flex align-items-center flex-column ${activeTab === 'hotel' ? 'active' : ''}`}>
                        <img src='/searching_nodata.avif' className='img-fluid h_300px' />
                        <p className='Source_Bold text_gray ms-5 mr_10'>Hotel</p>
                    </div>
                ):(
                <div className={`tab-pane bg-warning1 d-flex align-items-center flex-column ${activeTab === 'vehicle' ? 'active' : ''}`}>
                    <img src='/searching_nodata.avif' className='img-fluid h_300px' />
                    <p className='Source_Bold text_gray ms-5 mr_10'>Vehicle</p>
                </div>
            )}
            </div>
        </div>
    );
};

export default Tabs;
