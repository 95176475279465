import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Switch from '@mui/material/Switch';

function Settings() {
  const [password, setPassword] = useState('hacked');
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);


  const [form, setForm] = useState({
    emailFormat: 'HTML',
    digest: 'Default',
    emailLatest: 'Daily',
    local: '2',
    digestMsg: 'all',

  })

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked((prevState) => !prevState);
  };


  useEffect(() => {
    if (Notification.permission === "granted") {
      setChecked(true)
    }

    if (checked) {
      if (Notification.permission === "granted") {
        new Notification('Hello, world!');

      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
          }
        });
      }
    }

  }, [checked]);


  // const alloNoto = () => {
  //   if (Notification.permission === "granted") {

  //   } else {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //       }
  //     });
  //   }
  // };

  return (
    // <div className='bg_lightblue pb-4 p-0 ' style={{ marginTop: '2.5rem' }}>
    <Container className='bg-primary1' style={{ marginTop: '1rem' }}>
      <Row>
        <Col lg={9}>
          <div className='rd_6 p-3 bg-white shadow-n1 mt-4'>
            {/* <h3 className='fw-bolder fs_16 m-0 flex-grow-1'>Email & Notification</h3> */}

            {/* <form>
                <div className='d-flex mt-3'>
                  <div className="form-group mr_10 flex-grow-1">
                    <label className='Source_SemiBold fs_14 text-muted mb-1'>Email</label>
                    <input type="email" className="form-control fs_13 h_35px" required placeholder="support@travellinglark.com" value={email} onChange={(event) => setEmail(event.target.value)} />
                  </div>

                  <div className="form-group mr_10 flex-grow-1">
                    <label className='Source_SemiBold fs_14 text-muted mb-1'>Password</label>
                    <div className='d-flex align-items-center bg-warning1 position-relative'>
                      <input type={showPassword ? 'text' : 'password'} className="form-control fs_13 h_35px" placeholder="Enter your password" value={password} onChange={(event) => setPassword(event.target.value)} />
                      <i className={`fas fa-${showPassword ? 'eye' : 'eye-slash'} pointer position-absolute rt-0 mr_10`} onClick={() => setShowPassword(!showPassword)}></i>
                    </div>
                  </div>
                </div>
              </form> */}

            {/* Email Format */}
            {/* <div className='divider mt-5'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1'>Email Format</h3>

              <div className="form-group mr_10 flex-grow-1">
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Email Format</label>
                <select name="country" className="form-control w-50 Source_Regular fs_14 text_gray pointer" value={form.emailFormat} onChange={handleChange}>
                  <option value="PlainText">PlainText</option>
                  <option value="HTML">HTML</option>
                </select>
              </div> */}

            {/* Digest Email */}
            {/* <div className='divider mt-5'></div> */}
            <h3 className='fw-bolder fs_16 flex-grow-1'>Enable Notification</h3>
            <div className="form-group mr_10 flex-grow-1 mt-4">
              <div className='d-flex align-items-center '>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1 ms-2'>To receive realtime notification please enable</label>
              </div>

              {/* <label className='Source_SemiBold fs_14 text-muted mb-1 mt-3'>Email frequency for project related activity</label>
                <select name="country" className="form-control w-50 Source_Regular fs_14 text_gray pointer" value={form.emailFormat} onChange={handleChange}>
                  <option value="PlainText">PlainText</option>
                  <option value="HTML">HTML</option>
                </select> */}

              {/* <p className='Source_Regular text-muted fs_14 mt-4'>To receive realtime notification please enable.</p> */}
            </div>

            {/* Email for */}
            {/* <div className='divider mt-5'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1'>Emails for latest projects posted</h3>

              <div className="form-group mr_10 flex-grow-1 mt-4">
                <div className='d-flex align-items-start'>
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <label className='Source_SemiBold fs_14 text-muted mb-1 ms-2'>When a project gets posted that matches my selected skills</label>
                </div>

                <label className='Source_SemiBold fs_14 text-muted mb-1 mt-3'>Email frequency</label>
                <select name="country" className="form-control w-50 Source_Regular fs_14 text_gray pointer" value={form.emailFormat} onChange={handleChange}>
                  <option value="PlainText">PlainText</option>
                  <option value="HTML">HTML</option>
                </select>

              </div> */}

            {/* local jobs */}
            {/* <div className='divider mt-5'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Emails for latest local jobs</h3>
              <div className='d-flex align-items-start mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1 ms-2'>When an employer is interested in hiring me</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted'>When a local job in my area gets posted</label>
              </div> */}


            {/* digest messages */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Digest emails for messages</h3>
              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you receive a private message from a contact</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you receive a message about a project or contest</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you receive a message from Freelancer.com staff</label>
              </div> */}

            {/* digest groups */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Digest emails for groups</h3>
              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When a new post is posted in a group you are a member of</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When new comment is posted on a post you are following</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you are mentioned in a new comment</label>
              </div> */}

            {/* push notification */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Push notifications for workspaces</h3>
              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When a new post is posted in a workspace you are a member of</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When a new comment is posted on a post you are following</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you are mentioned in a new comment</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When someone likes your post or comment</label>
              </div> */}


            {/* push notification tasklists */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Push notifications for tasklists</h3>
              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Tasklist push notifications</label>
              </div> */}


            {/* Individual Emails */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Individual Emails</h3>
              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>News and announcements from Freelancer.com</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>You are awarded a project</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>A freelancer requests you to release a milestone payment</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>We notify you of the top bidder for your project</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>We notify you of the latest activity regarding Freemarket</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>A freelancer requests you as a contact</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you are invited to a group</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>When you are invited to a tasklist</label>
              </div> */}

            {/* Other Emails */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>Other Emails</h3>
              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Marketing emails</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Freelancer.com deals</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Monthly newsletter</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Weekly Community Digest</label>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>Referral Program Notifications</label>
              </div> */}

            {/* SMS Notifications */}
            {/* <div className='divider mt-4'></div>
              <h3 className='fw-bolder fs_16 mt-4-5 flex-grow-1 mb-4'>SMS Notifications</h3>
              <div className='d-flex align-items-center mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>SMS notifications for project updates</label>
              </div>

              <div className='d-flex mb-2'>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className='Source_SemiBold fs_14 text-muted mb-1'>SMS notifications for marketing and promotions</label>
              </div> */}

          </div>
        </Col>
      </Row>
    </Container>
    // </div>
  )
}

export default Settings