import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Collapse, Card, Button, ListGroup, Form, InputGroup } from 'react-bootstrap';
import { MemContext } from '../../ContextRedux/Context';
import io from 'socket.io-client';
import { dateFormat } from '../../Componets/CommonFs';
import { CommonAPI } from '../../Componets/CommonAPI';
import SignInModal from '../Auth/SignInModal';
import { useDispatch, useSelector } from 'react-redux';
import { svMsgId, svReceiverDetails } from '../../ContextRedux/Action';
import { Spin } from 'antd';
import { CircularProgress } from '@mui/material';
import { MsgNoti } from '../../Componets/Modal';
import { useNotification } from '../../ContextRedux/NotificationContext';
import LogOut from '../../Componets/LogOut';
import { dec, enc } from '../../Componets/SecRes';
import { useChatPopup } from '../../ContextRedux/ChatPopupContext';


const socket_url = process.env.REACT_APP_SOCKET_URL;
const socket_local_url = process.env.REACT_APP_SOCKET_LOCAL;
const local_url = process.env.REACT_APP_LOCAL_URL;
const base_url = process.env.REACT_APP_BASE_URL;
const key = process.env.REACT_APP_KEY;
const notificationsShown = new Set();


function MessagingPopup({ openPopup, closePopup, type }) {

    const { userInfo, unId } = useContext(MemContext)
    const dispatch = useDispatch();

    const userId = userInfo !== null && userInfo?._id

    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [animationOut, setAnimationOut] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page1, setPage1] = useState(1);
    const [isLoading1, setIsLoading1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [againRender, setAgainRender] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [reload, setReload] = useState(1);
    const [messagesData, setMessagesData] = useState([]);
    const [chats, setChats] = useState([]);
    const [receiver, setReceiver] = useState({});
    const chatContainer = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hideLoadButton, setHideLoadButton] = useState(false);
    const receiver_details = useSelector(reducer => reducer.data?.receiver_details)
    const user_info = useSelector(reducer => reducer.data?.info)
    const activeCheck = useSelector(reducer => reducer.data?.msgId)
    const socketRef = useRef(null);
    const socketNotiRef = useRef(null);
    const { showNotification } = useNotification();
    const { logout } = useContext(MemContext)
    const { isChatOpen, toggleChatPopup } = useChatPopup();


    // new msg
    useEffect(() => {
        if (user_info.tkn) {
            socketRef.current = io(socket_url, {
                query: {
                    token: user_info.tkn,
                },
            });

            const joinO = { user_id: user_info?.user?._id }
            const join = enc(joinO, key)
            socketRef.current.emit('join', join, (join)=>{
            })

            const handleReceivedMessages = (data) => {
                setAgainRender(false)
                const dec1 = dec(data, key);

                getList()
                const BusName = dec1.user?.business_name || ''
                const fullName = dec1.user?.first_name + dec1.user?.last_name || ''

                const nameIs = BusName ? BusName : fullName

                const seenMe = { chat_id: receiver_details?.chat_id, user_id: user_info?.user?._id }
                const seeMe = enc(seenMe, key)

                if (activeCheck == '' || !activeChat) {
                } else {
                    socketRef.current.emit('seeMessage', seeMe)
                }
                setMessagesData((prevMessages) => [...prevMessages, dec1.messageData]);


                showNotification({ name: nameIs, msg: dec1.messageData?.message, id: dec1?.messageData?._id, image:dec1.user?.image })
            };

            const newChat = (data) => {
                setAgainRender(false)
                getList()
            };

            socketRef.current.on('received_messages', handleReceivedMessages);
            socketRef.current.on('new_chat', newChat);

            return () => {
                socketRef.current.off('received_messages', handleReceivedMessages);
                socketRef.current.off('new_chat', newChat);
            };
        }

    }, [isChatOpen]);


    useEffect(() => {
        if (user_info.tkn) {
            socketNotiRef.current = io(socket_url, {
                query: {
                    token: user_info.tkn
                }
            });

            const joinO = { user_id: user_info?.user?._id }
            const join = enc(joinO, key)

            socketNotiRef.current.emit('join',join, (join)=>{
            })

            const newProposal = (res) => {
                const dec1 = dec(res, key);
                const proposal = dec1?.proposal;

                showNotification({ name: proposal?.vendorId?.business_name, msg: 'New Proposal Received!', image:proposal?.vendorId?.image })
            };

            const newCallback = (res) => {
                const dec1 = dec(res, key);
                showNotification({ name: dec1?.sender_id?.name, msg: `New Callback Request Received for ${dec1?.type}!`, image:dec1?.sender_id?.image })
            }

            socketNotiRef.current.on('proposal_received', newProposal);
            socketNotiRef.current.on('callback_received', newCallback);

            return () => {
                socketNotiRef.current.off('callback_received', newCallback);
                socketNotiRef.current.off('proposal_received', newProposal);
            };
        }

    }, []);


    useEffect(() => {
        if (user_info.tkn) {
            if (againRender) return
            getList()
        }
    }, [user_info.tkn]);

    useEffect(() => {
        if (user_info.user?._id) {
            getReceiverDetails()
        }

    }, [receiver_details]);

    useEffect(() => {
        if (user_info.user?._id && activeChat) {
            handleGetMessages(receiver_details?.chat_id, page1)
        }
    }, [reload]);

    const getReceiverDetails = async () => {
        setReceiver(receiver_details)
        if (!activeChat) {
            setActiveChat(receiver_details?.chat_id)
            dispatch(svMsgId(receiver_details?.chat_id))
        }
    }

    const getList = async () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `chat_list/${page}/${user_info?.user?._id}`, responseBack1)
        setIsLoading(false)
    }


    const responseBack1 = useCallback(res => {
        if (res.status === "success") {
            const dec1 = dec(res?.data, key);
            setChats(dec1)
            setAgainRender(true)
        } else {
            if (res.message === 'Invalid token') {
                setIsModalOpen(true)
                LogOut(logout, dispatch)
            }
        }
        setIsLoading(false)
    })


    const handleChatClick = async (chat_id, sender_id, receiver_Id, name) => {
        const joinO = { user_id: user_info?.user?._id }
        const getReceiverId = sender_id == user_info.user?._id ? receiver_Id : sender_id;
        dispatch(svReceiverDetails({ receiver_id: getReceiverId, name: name, chat_id: chat_id }))
        // const join = enc(joinO, key)
        // socketRef.current.emit('join', join)

        setActiveChat(chat_id);
        dispatch(svMsgId(chat_id))

        const rn = Math.floor(Math.random() * 989889999 + 1)


        setReload(rn)
        // handleGetMessages(chat_id, page1)
    };

    useEffect(() => {
        if (isChatOpen && activeChat) {
            handleGetMessages(activeChat, page1)
        }
    }, [isChatOpen])


    const handleGetMessages = async (chat_id, page1) => {
        const id = chat_id == undefined || chat_id == '' ? receiver?.chat_id : chat_id

        if (isLoading) return;
        setIsLoading1(true)
        CommonAPI({}, 'GET', `get_messages/${page1}/${id}`, responseBack)
    }

    const handleScroll = () => {
        if (chatContainer.current.scrollTop === 0 && !isLoading) {
            handleGetMessages('', page1);
        }
    };

    useEffect(() => {
        const container = chatContainer.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isLoading1]);

    const responseBack = useCallback(res => {
        if (res.status === 'success') {
            setError('')

            const dec1 = dec(res.data, key);

            if (dec1.length == 0) {
                setHideLoadButton(true)
            }

            const seenMe = { chat_id: receiver_details?.chat_id, user_id: user_info?.user?._id }
            const seeMe = enc(seenMe, key)



            if (dec1[0]?.seenStatus == 0 && dec1[0]?.sender_id !== user_info.user?._id) {
                socketRef.current.emit('seeMessage', seeMe)
            }
            // if (condition) {

            // }
            if (!dec1 || dec1.length === 0 && page > 1) {
                setError("You've reached the end of your messages!")
                setIsLoading1(false)
                return;
            }

            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
                return
            }

            const currentContainer = chatContainer.current;
            const currentScrollHeight = currentContainer ? currentContainer.scrollHeight : 0;

            // Filter out duplicate messages before prepending
            setMessagesData(prevMessages => {
                const existingIds = new Set(prevMessages.map(msg => msg._id));
                const newMessages = dec1.filter(msg => !existingIds.has(msg._id));
                return [...newMessages, ...prevMessages]; // Prepend unique messages
            });

            // After messages are updated, adjust the scroll position
            setTimeout(() => {
                if (currentContainer) {
                    const newScrollHeight = currentContainer.scrollHeight;
                    currentContainer.scrollTop = newScrollHeight - currentScrollHeight;
                }
            }, 0);

            setPage1(prevPage => prevPage + 1);
        } else {

            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }

        }
        setIsLoading1(false)
    })

    const handleSendMessage = async () => {

        if (message?.trim() && receiver_details?.chat_id) {

            const data = { sender_id: user_info.user?._id, receiver_id: receiver_details?.receiver_id, chat_id: receiver_details?.chat_id, message }
            const encryptedData = enc(data, key)

            const joinO = { user_id: user_info?.user?._id }
            const join = enc(joinO, key)

            socketRef.current.emit('join', join)

            socketRef.current.emit('send_message', encryptedData, (res) => {
                const currentTime = new Date().toISOString()
                const createMsg = {
                    createdAt: currentTime,
                    sender_id: user_info.user?._id,
                    receiver_id: receiver?.receiver_id,
                    chat_id: receiver_details?.chat_id,
                    message: message,
                    seenStatus: 0
                }
                setMessagesData(prev => [...prev, createMsg])
            });
            setIsLoading1(false)

            setMessage('');
        }
    };


    useEffect(() => {
        if (chatContainer.current && page1 === 2) {
            chatContainer.current.scrollTop = chatContainer.current.scrollHeight
        }
    }, [messagesData])



    const sort = messagesData && messagesData?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))


    const handlekey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'X') {
                toggleChatPopup();
            }

            if (event.ctrlKey && event.shiftKey && event.key === 'O') {
                toggleChatPopup()
            }

            if (event.key === 'Escape') toggleChatPopup();
        };

        const handleKeyUp = (event) => {
            if (event.key === 'a') window.aPressed = false;
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [])

    const handleClose = (e) => {
        e.preventDefault();
        setActiveChat(null); getList(); setPage1(1); setMessagesData([])
        dispatch(svMsgId(''))
        dispatch(svReceiverDetails({ receiver_id: '', name: '', chat_id: '' }))
    }


    const openToggle = () => {
        if (!user_info?.tkn) {
            setIsModalOpen(true)
            return
        }
        toggleChatPopup();
    }


    return (
        <div className={`messaging-popup z-2 bg-transparent d-flex justify-content-end flex-column`}>

            <div className='bg-warning1 d-flex align-items-end justify-content-end m-0 p-0'>
                {isChatOpen ? (
                    <Button
                        aria-controls="chat-list"
                        aria-expanded={isChatOpen}
                        className={`d-flex justify-content-between h_40px bg-gradient-thm m-0 border-0 w-100 align-items-center rounded-0 rd_tl_tr_6 ${!activeChat ? '' : 'ps-0'}`}
                    >
                        {!activeChat ? (
                            <>
                                <span className='Rubik-Medium' onClick={toggleChatPopup}>Chats</span>
                                <span onClick={toggleChatPopup}>
                                    <i className="bi bi-x fs_18"></i>
                                </span>
                            </>
                        ) : (
                            <div className='d_flex_align_justify_center h-100'>
                                <i className="fa-solid fa-chevron-left px-2 d-align-justify-center h-100 text-white fs_13 pointer" onClick={handleClose}></i>
                                <span className='Rubik-Medium ms-1 fs_14 mini-line-1 text-capitalize'>{receiver.name}</span>
                            </div>
                        )}
                    </Button>
                ) : (
                    <Button
                        onClick={openToggle}
                        aria-controls="chat-list"
                        aria-expanded={isChatOpen}
                        className="d-flex bg-gradient-thm rd_30 hw_50px p-0 mb-4 justify-content-center border-0 align-items-center "
                    >
                        <span>
                            <i className="bi bi-chat-left-text"></i>
                        </span>
                    </Button>
                )}
            </div>

            <Collapse in={isChatOpen}>
                <div id="chat-list" className='w_300px'>
                    {!activeChat ? (
                        <div className="chat-list bg-white border_gray rounded-0 rd_bl_br_6">
                            {chats && chats.length > 0 ? (
                                <ListGroup variant="flush">
                                    {chats && chats?.map((chat, index) => {
                                        const date = dateFormat(chat?.last_message_time, 'dmy');
                                        const current = new Date()
                                        const currentDate = dateFormat(current, 'dmy')

                                        const BusName = chat?.business_name || ''
                                        const fullName = chat?.first_name + ' ' + chat?.last_name || ''

                                        const nameIs = BusName ? BusName : fullName


                                        const colors = ['bg-secondary', 'bg-info', 'bg-danger', 'bg_violet', 'bg-dark', 'bg-gradient-thm', 'bg_thm', 'bg-primary', 'bg-gradient-thm'];
                                        const random = Math.floor(Math.random() * colors.length);

                                        return (
                                            <ListGroup.Item
                                                key={chat._id}
                                                action
                                                onClick={() => handleChatClick(chat?._id, chat?.sender_id, chat?.receiver_id, nameIs)}
                                                className="d-flex align-items-center"
                                            >
                                                {chat.avatar ? (
                                                    <img
                                                        src={chat.avatar}
                                                        alt={chat.name}
                                                        className="rounded-circle"
                                                        style={{ width: '40px', height: '40px' }}
                                                    />
                                                ) : (
                                                    <div
                                                        className={`rounded-circle d-flex justify-content-center align-items-center ${colors[random]}`}
                                                        style={{ width: '40px', height: '40px' }}
                                                    >
                                                        <span className="text-white text-capitalize">{nameIs?.[0]}</span>
                                                    </div>
                                                )}
                                                <div className="ms-3 d-flex flex-grow-1">
                                                    <div className='flex-grow-1'>
                                                        <div className='Rubik-Medium m-0 fs_15 text-capitalize mini-line-1 flex-grow-1'>{nameIs || ''}</div>
                                                        <div className='Rubik-Regular fs_13 text-secondary mini-line-1'>{chat?.message}</div>
                                                    </div>
                                                    <div className='d-flex align-items-center flex-column'>
                                                        <p className='Rubik-Regular m-0 fs_12 text-capitalize'>{date === currentDate ? 'Today' : date?.replace('/20', '/')}</p>
                                                        {chat.count !== 0 && <div className='px-2 bg-success rd_30 text-white p-0 fs_11 d_flex_align_justify_center'>{chat.count}</div>}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            ) : (
                                <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                                    <img src='/images/not_found.avif' alt='no chats found' className='img-fluid h_120px w_120px' />
                                    <div className='Inter-Medium fs_16 text-muted'>No Chats Available</div>
                                    {/* <div className='Inter-Medium fs_13 text_gray2'>{userInfo !== null && 'Please SignIn for Chat'}</div> */}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="messages-list bg-white p-0 rd_bl_br_6 border_gray ">
                            {messagesData && messagesData.length > 0 ? (
                                <div className="chats-body p-2 bg-white" key={'chatContainer'} ref={chatContainer} >
                                    <ListGroup variant="flush">
                                        {error.length > 10 && !isLoading1 && (
                                            <div className='h_22px d-flex align-items-center justify-content-center rd_6 mb-4 m-1'>
                                                <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary'>{isLoading1 ? 'Please Wait...' : error}</p>
                                            </div>
                                        )}
                                        {isLoading1 && (
                                            <div className='bg-white rd_50 shadow hw_25px d-flex align-items-center justify-content-center position-absolute' style={{ left: '50%' }}>
                                                <CircularProgress className='w_60 h_60' />
                                            </div>
                                        )}
                                        {sort && sort?.map((msg, index) => {
                                            const date = dateFormat(msg?.createdAt, 'dmy')
                                            const current = new Date()
                                            const currentDate = dateFormat(current, 'dmy')

                                            const isDifferentDate = date !== (sort[index - 1] ? dateFormat(sort[index - 1]?.createdAt, 'dmy') : '');
                                            const time = dateFormat(msg?.createdAt, 'hhmm')

                                            return (
                                                <div key={msg?._id} className={`messages p-1`}>
                                                    {isDifferentDate && (
                                                        <div className='h_22px d-flex align-items-center justify-content-center rd_6 mb-2 m-1'>
                                                            <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary'>{date === currentDate ? 'Today' : date}</p>
                                                        </div>
                                                    )}
                                                    <div key={msg?._id} className={`messages p-1 ${msg?.sender_id == userId ? 'me' : 'other'}`}>

                                                        <div key={index} className={`message p-1-5 px-2-5 rd_6 ${msg?.sender_id == userId ? 'me bg-gradient-thm' : 'other'}`}>
                                                            <p className='Rubik-Regular fs_12 m-0 p-0 bg1-warning'>{msg?.message}</p>

                                                            <div className="Rubik-Light fs_10 text-end m-0 p-0 bg1-success position-relative d-flex align-items-center" style={{ left: 5 }}>{time}
                                                                {msg?.sender_id == userId && <span className="material-symbols-outlined text-white fs_11 ms-1">{msg.seenStatus == 0 ? 'check' : 'done_all'}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ListGroup>
                                </div>
                            ) : (
                                isLoading1 ? (
                                    <div className='bg-white shadow w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
                                        <CircularProgress className='hw_25px' />
                                        <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary mt-15px'>Please Wait...</p>
                                    </div>
                                ) : (
                                    <div className='h-100 w-100 bg-white d-flex justify-content-center align-items-center flex-column'>
                                        <img src='/images/not_found.avif' alt='no messages found' className='img-fluid h_120px w_120px' />
                                        <div className='Inter-Regular fs-14px text-center text_gray'>Send your first message to start chatting</div>
                                    </div>
                                )
                            )}
                            <div className="footer">
                                <InputGroup className='d-flex align-items-center'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Type a message..."
                                        value={message}
                                        className="rd_4 p-2 h_35px"
                                        onKeyDown={handlekey}
                                        required
                                        autoFocus
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    <i className="fa-solid fa-paper-plane text_thm pointer ms-2 ps-1" onClick={() => handleSendMessage()}></i>
                                </InputGroup>
                            </div>
                        </div>
                    )}
                </div>
            </Collapse>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    );
}

export default MessagingPopup;

