import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { tstSuccess } from '../../Componets/HotToast';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { MemContext } from '../../ContextRedux/Context';
import { dateFormat } from '../../Componets/CommonFs';
import { Spin } from 'antd';
import SignInModal from '../Auth/SignInModal';
import { useSelector } from 'react-redux';

const key = process.env.REACT_APP_KEY;


function STicket(type) {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location?.state
    const { userInfo } = useContext(MemContext);
    const user_details = useSelector(reducer => reducer?.data?.info)

    const [active, setActive] = useState(state?.switch || 'my');
    const [relatedIssue, setRelatesIsssue] = useState('none');
    const [name, setName] = useState(user_details?.user?.name || '');
    const [email, setEmail] = useState(user_details?.user?.email || '');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState({})
    const [list, setList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        if (!user_details?.tkn) return
        getList()
    }, []);


    const getList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `ticketList`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key)
            if (dec1 == undefined || dec1 == null) {
                setList(dec1)
            } else {
                setList(prevData => {
                    const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                    return [...prevData, ...newData];
                });
            }

        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })

    const val = () => {
        const err = {};

        if (!name?.trim()) {
            err.name = 'Enter Your name'
        }

        if (!/^[^\s@]+@gmail\.com$/.test(email)) {
            err.mail = 'Please enter valid email'
        }

        if (relatedIssue == 'none') {
            err.issues = 'Please select valid option!'
        }

        if (!subject.trim()) {
            err.sub = 'Please enter subject!'
        }

        if (message.trim().length <= 10) {
            err.msg = 'Please enter minimum 10 characters!'
        }

        setErr(err)
        return Object.keys(err).length == 0;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: userInfo?.first_name + ' ' + userInfo?.last_name,
            email: userInfo?.email,
            service: relatedIssue,
            subject: subject,
            message: message,
        }

        const enc1 = enc(data, key)

        if (!user_details?.tkn) {
            setIsModalOpen(true)
            return
        }

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'updateTicket', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            setRelatesIsssue('none')
            setSubject('')
            setMessage('')
            getList();
            setList([]);
            setActive('my');
            tstSuccess('Support ticket created successfully')
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })

    const handleRowClick = (id) => {
        navigate(`/user/ticket-details`, { state: { id: id } });
    };

    const ty = type.type == 'TL'


    return (
        <div className={`bg-light pt-3 p-0 pb-5 ${ty ? 'mt-0' : 'mt-12'}`}>
            <Container className='bg-primary1'>
                {!ty && (
                    <div className=''>
                        <p className='Source_SemiBold fs-5 mt-2 m-0' style={{ color: '#0d0a3a' }}>{active == 'my' ? 'My Support' : 'Open'} Ticket</p>
                    </div>
                )}
                <Row className='bg-primary1 mt-3'>
                    {!ty && (
                        <Col lg={3} className='bg-primary1'>
                            <div className={`rd_6 shadow-slowclean bg-white p-3 mt-2`}>
                                <label className={`Source_Regular pointer mb-2 fs_14 d-flex align-items-center ${active == 'my' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => { getList(); setList([]); setActive('my'); }}>
                                    <i className="bi bi-ticket mr_10 pt-4px"></i>
                                    My Tickets
                                </label>
                                <label className={`Source_Regular pointer pt-0-5 p-0 fs_14 d-flex align-items-center ${active == 'open' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('open')}>
                                    <i className="material-symbols-outlined fs_16 mr_10">open_in_new</i>
                                    Open Ticket
                                </label>
                            </div>
                        </Col>
                    )}

                    <Col className='bg-warning1'>
                        <Row>
                            {active == 'open' ? (
                                <Col lg={12} className='bg-warning1'>
                                    <p className='Source_Regular fs_18 m-0 text_gray mt-4'>Ticket Information</p>
                                    <div className={`rd_4 shadow-n1 bg-white p-3 mt-3`}>
                                        <form>
                                            <div className='d-flex'>
                                                <div className="form-group mb-3 mr_10 flex-grow-1">
                                                    <label className='Source_Medium fs_14 text-muted mb-1'>Name</label>
                                                    <input type="text" name="budget" placeholder='Full name' value={name} onChange={(e) => setName(e.target.value)} className="form-control Source_Regular fs_14 custom_field" />
                                                    {err.name && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.name}</p>}
                                                </div>
                                                <div className="form-group mb-3 mr_10 flex-grow-1">
                                                    <label className='Source_Medium fs_14 text-muted mb-1'>Email</label>
                                                    <input type="text" name="budget" placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} className="form-control Source_Regular fs_14 custom_field" />
                                                    {err.mail && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.mail}</p>}
                                                </div>
                                            </div>

                                            <div className='d-flex'>
                                                {/* <div className="form-group mb-3 mr_10 flex-grow-1">
                                                    <label className='Source_Medium fs_14 text-muted mb-1'>Department</label>
                                                    <select name="country" className="form-control Source_Regular fs_14 text_gray pointer custom_field">
                                                        <option value="tech">Tech Support</option>
                                                        <option value="call">Call</option>
                                                    </select>
                                                </div> */}
                                                <div className="form-group mb-3 mr_10 flex-grow-1">
                                                    <label className='Source_Medium fs_14 text-muted mb-1'>Related Services</label>
                                                    <select name="country"
                                                        value={relatedIssue}
                                                        onChange={(event) => setRelatesIsssue(event.target.value)}
                                                        className="form-control Source_Regular fs_14 text_gray pointer custom_field">
                                                        <option value="none">None</option>
                                                        <option value="technical issue">Technical Issue</option>
                                                        <option value="query">Query</option>
                                                        <option value="package">Package</option>
                                                        <option value="vehicle">Vehicle</option>
                                                        <option value="hotel">Hotel</option>
                                                        <option value="guider">Guider</option>
                                                        <option value="tour">Tour</option>
                                                    </select>
                                                    {err.issues && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.issues}</p>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <p className='Source_Regular fs_18 mt-4-5 m-0 text_gray'>Message</p>
                                    <div className={`rd_4 shadow-n1 bg-white p-3 mt-3`}>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group mb-3 mr_10 flex-grow-1">
                                                <label className='Source_Medium fs_14 text-muted mb-1'>Subject</label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    placeholder=''
                                                    value={subject}
                                                    onChange={(event) => { setSubject(event.target.value) }}
                                                    className="form-control Source_Regular fs_14 custom_field" />
                                                {err.sub && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.sub}</p>}
                                            </div>
                                            <div className="form-group mb-3 mr_10 flex-grow-1">
                                                <label className='Source_Medium fs_14 text-muted mb-1'>Message</label>
                                                <textarea
                                                    type="text"
                                                    name="message"
                                                    rows={4}
                                                    placeholder='message'
                                                    value={message}
                                                    onChange={(event) => { setMessage(event.target.value) }}
                                                    className="form-control Source_Regular fs_14 custom_field h-auto" />
                                                {err.msg && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.msg}</p>}
                                            </div>
                                            <div className='d-flex mt-4'>
                                                <button type="reset" className="btn border_thmdark text_thmdark px-4 text-center rd_4 Source_Regular mr_10">Cancel</button>
                                                <button type="submit" className="btn bg-gradient-thm text-white px-4 text-center rd_4 Source_Regular" disabled={isLoading}>Submit</button>
                                            </div>
                                        </form>
                                    </div>

                                </Col>
                            ) : (
                                <Col lg={12} className='bg-warning1'>
                                    <div className={`rd_4 shadow-slowclean bg-white p-3 pb-1 mt-2 overflow-hidden scrollable overflow-x-auto white-space`}>
                                        <table className='table table-responsive table-hover'>
                                            <thead>
                                                <tr>
                                                    <th className='Source_Regular text-secondary fs_14' scope='col'>Id</th>
                                                    <th className='Source_Regular text-secondary fs_14' scope='col'>Related Issue</th>
                                                    <th className='Source_Regular text-secondary fs_14' scope='col'>Subject</th>
                                                    <th className='Source_Regular text-secondary fs_14' scope='col'>Status</th>
                                                    <th className='Source_Regular text-secondary fs_14' scope='col'>Last Updated</th>
                                                </tr>
                                            </thead>
                                            <tbody className=''>
                                                {list && list.map(ticket => {
                                                    const status = ticket.status == 0;
                                                    const clr = status ? 'success' : 'primary'
                                                    const date = dateFormat(ticket.updatedAt)

                                                    return (
                                                        <tr key={ticket._id} className='pointer' onClick={() => handleRowClick(ticket._id)}>
                                                            <td style={{}} className='align-middle Source_Medium fs_14 text_gray text-capitalize'>#{ticket.id}</td>
                                                            <td style={{}} className='align-middle Source_Medium fs_14 text_gray text-capitalize'>{ticket.service}</td>
                                                            <td style={{}} className='align-middle Source_Regular text-secondary'>
                                                                <p className='m-0 fs_12'>{ticket.subject}</p>
                                                            </td>
                                                            <td className='Source_Regular text-secondary align-middle'>
                                                                <div className='d-flex align-items-center'>
                                                                    <i className={`material-symbols-sharp fs_15 me-2 text-${clr}`}>block</i>
                                                                    <span className={`text-${clr}`}>{status ? 'Closed' : 'Active'}</span>
                                                                </div>
                                                            </td>
                                                            <td className='align-middle Source_Regular fs_15'>{date}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        {list && list?.length <= 0 && (
                                            <div className='h-100 w-100 mb-2 bg-white d-flex justify-content-center align-items-center flex-column'>
                                                <img src='/images/not_found.avif' alt='No tickets' className='img-fluid h_100px w_95px' />
                                                <div className='Inter-Medium fs_15 text-secondary'>For viewing tickets please login</div>
                                            </div>)}

                                        {isLoading && (
                                            <div className='w-100 d-flex align-items-center justify-content-center mt-4 mb-1'>
                                                <Spin className='me-3 thm-spin' size='small'/> <p className='m-0 Source_Medium text_gray'>Loading...</p>
                                            </div>
                                        )}

                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    )
}

export default STicket