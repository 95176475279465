import React from 'react';
import { Link } from 'react-router-dom';

const BottomNavigation = () => {
    return (
        <div className="bottom-nav">
            <Link to="/" className="bottom-nav-link">
                <span className="icon">
                    <i className="bi bi-house"></i>
                </span>
                <span className="label">Home</span>
            </Link>
            <Link to="/search" className="bottom-nav-link">
                <span className="icon">
                    <i className="bi bi-list-check"></i>
                </span>
                <span className="label">Travel Leads</span>
            </Link>
            <Link to="/profile" className="bottom-nav-link">
                <span className="icon">
                    <i className="bi bi-postcard"></i>
                </span>
                <span className="label">Post Now</span>
            </Link>
            <Link to="/profile" className="bottom-nav-link">
                <span className="icon">
                    <i className="bi bi-menu-button-wide"></i>
                </span>
                <span className="label">Menu</span>
            </Link>
        </div>
    );
};

export default BottomNavigation;
