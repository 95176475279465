import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import { dec, enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateFormat, timeAgo } from '../../Componets/CommonFs';
import { MemContext } from '../../ContextRedux/Context';
import { tstSuccess } from '../../Componets/HotToast';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import SignInModal from '../Auth/SignInModal';

const key = process.env.REACT_APP_KEY;

function TicketDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location?.state
    const { userInfo } = useContext(MemContext);
    const chatContainer = useRef(null)
    const userDetails = useSelector(reducer => reducer.data?.info)

    const [reopen, setReopen] = useState(false);
    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [message, setMessage] = useState('');
    const [err, setErr] = useState('')
    const [success, setSuccess] = useState('')
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);


    useEffect(() => {
        getList()
    }, []);

    const getList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `ticketReplyList?id=${state.id}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key)
            setList(dec1)
            setList1(dec1.ticketReply)
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading1(false)
        setIsLoading(false)
    })


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: userInfo.first_name + ' ' + userInfo.last_name,
            email: userInfo.email,
            message: message,
            ticketId: state.id,
            role: 'user'
        }

        const enc1 = enc(data, key)

        if (message.trim().length <= 10) {
            setErr('Please enter minimum 10 characters!')
        } else {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'ticketReply', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const currentTime = new Date().toISOString()

            const createMsg = {
                createdAt: currentTime,
                message: message,
                type: 'user'
            }

            setList1(prev => [...prev, createMsg])

            setErr('')
            setMessage('')
            // if (!reopen) {
            //     setReopen(false)
            // }

        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })

    
    
    const ticket = list && list?.ticket
    const date = dateFormat(ticket?.createdAt);
    const lastUpdated = timeAgo(ticket?.updatedAt);
    const status = ticket?.status == 0

    const handlekey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e)
        }
    }


    return (
        <div className='bg-light mt-12 pt-3 p-0 pb-5'>
            <Container>
                <div className=''>
                    <p className='Source_Regular fs-5 mt-2 m-0'>Ticket ID #{ticket?.ticketId} - {ticket?.subject}</p>
                </div>
                <Row className='bg-primary1 mt-4'>
                    <Col lg={3} className='bg-primary1'>
                        <p className='Source_Medium fs_16 mt-2 m-0'>Ticket Information</p>
                        <div className={`rd_6 shadow-n1 bg-white p-3 mt-2`}>
                            <p className='Source_Regular fs_14 text-secondary m-0'>Requestor</p>
                            <p className='Rubik-Regular fs_14 text_gray m-0'>{ticket?.user?.name}</p>

                            <div className='divider mt-2 mb-2'></div>

                            <p className='Source_Regular fs_14 text-secondary m-0'>Related Issue</p>
                            <p className='Rubik-Regular fs_14 text_gray m-0 text-capitalize'>{ticket?.service}</p>

                            <div className='divider mt-2 mb-2'></div>

                            <p className='Source_Regular fs_14 text-secondary m-0'>Submitted</p>
                            <p className='Rubik-Regular fs_14 text_gray m-0'>{date}</p>

                            <div className='divider mt-2 mb-2'></div>

                            <p className='Source_Regular fs_14 text-secondary m-0'>Last Updated</p>
                            <p className='Rubik-Regular fs_14 text_gray m-0'>{lastUpdated}</p>

                            <div className='divider mt-2 mb-2'></div>

                            <p className='Source_Regular fs_14 text-secondary m-0'>Status</p>
                            {/* badgemy */}
                            <p className={`Source_Medium fs_12 m-0 d-inline px-2 p-1 rd_4 ${status ? 'bg-success text-white' : 'bg-primary text-white'}`}>{status ? 'CLOSED' : 'ACTIVE'}</p>
                        </div>
                        <p className='Source_Medium fs_16 mt-4 m-0'>Support</p>

                        <div className={`rd_6 shadow-n1 bg-white p-3 mt-2`}>
                            <label className={`Source_Regular pointer mb-2 fs_14 d-flex align-items-center`} onClick={() => navigate('/user/tickets', { state: { switch: 'my' } })}>
                                <i className="bi bi-ticket mr_10"></i>
                                My Support Tickets
                            </label>
                            <label className={`Source_Regular pointer p-0 fs_14 d-flex align-items-center`} onClick={() => navigate('/user/tickets', { state: { switch: 'open' } })}>
                                <i className="material-symbols-outlined fs_18 mr_10">open_in_new</i>
                                Open Ticket
                            </label>
                        </div>
                    </Col>
                    <Col lg={9}>
                        {(status || reopen) && (
                            <div className='border_thm d_flex_align_justify_center justify-between bg_thmlight text_thmdark fs_14 Source_Regular mt-4 rd_4 p-3 mb-3'>
                                This ticket is closed. You may reply to this ticket to reopen it.

                                <div className='bg-white rd_6 Inter-Medium p-2 pointer px-2-5 fs_13' onClick={()=> setReopen(true)}>
                                    Reopen Ticket
                                </div>
                            </div>
                        )}
                        <>
                            <div className="d-flex h_40px bg-gradient-thm m-0 border-0 w-100 align-items-center rounded-0 rd_tl_tr_6">
                                <img src='/icons/op3.svg' alt='Customer support Image' className='img-fluid hw_30px bg-white rd_50 p-2 ms-2 me-1' />
                                <span className='Rubik-Medium ms-1 fs_14 text-capitalize text-white'>Customer Support</span>
                            </div>
                            <div className="messages-list bg-white p-0 rd_bl_br_6 border_gray ">
                                {list1 && list1.length > 0 ? (
                                    <div className="chats-body p-2 rd_bl_br_6" ref={chatContainer}>
                                        <ListGroup variant="flush">
                                            {error.length > 10 && !isLoading && (
                                                <div className='h_22px d-flex align-items-center justify-content-center rd_6 mb-4 m-1'>
                                                    <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary'>{isLoading ? 'Please Wait...' : error}</p>
                                                </div>
                                            )}
                                            {isLoading && (
                                                <div className='bg-white rd_50 shadow hw_25px d-flex align-items-center justify-content-center position-absolute' style={{ left: '50%' }}>
                                                    <CircularProgress className='w_60 h_60' />
                                                </div>
                                            )}
                                            {list1 && list1?.map((msg, index) => {
                                                const date = dateFormat(msg?.createdAt, 'dmy')
                                                const current = new Date()
                                                const currentDate = dateFormat(current, 'dmy');

                                                const createdAt = date !== (list1[index - 1] ? dateFormat(list1[index - 1]?.createdAt, 'dmy') : '');
                                                const typeUser = msg?.type == 'user'

                                                return (
                                                    <div key={msg?._id} className={`bg-warning1 d-flex align-items-start flex-column p-1`}>
                                                        {createdAt && (
                                                            <div className='h_22px w-100 d-flex align-items-center justify-content-center rd_6 mb-2 m-1'>
                                                                <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary'>{date === currentDate ? 'Today' : date}</p>
                                                            </div>
                                                        )}
                                                        <div key={msg?._id} className={`d-flex align-items-center justify-content-center`}>
                                                            <img src={`${typeUser ? '/icons/man.png' : '/icons/admin.png'}`} alt='Image' className='img-fluid hw_30px bg-white rd_50 p-1-5' />

                                                            <div key={index} className={`p-1-5 px-2-5 ms-2 rd_6 bg-light text-gray ${typeUser ? 'bg-gray text_gray' : 'bg-light'}`}>
                                                                <p className='Rubik-Regular fs_12 m-0 p-0 bg1-warning'>{msg?.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                ) : (
                                    <div className='h-100 w-100 bg-white d-flex justify-content-center align-items-center flex-column'>
                                        <img src='/images/not_found.avif' alt='not found' className='img-fluid h_120px w_120px' />
                                        <div className='Rubik-SemiBold text_gray'>No messages available</div>
                                    </div>
                                )}
                                {(!status || reopen) && (
                                    <div className="mt-3">
                                        <div className='d-flex align-items-center fs_13 h_38px rd_6 bg-white border_gray Rubik-Regular text_dark_medium'>
                                            <input
                                                type="text"
                                                placeholder="Type a message..."
                                                value={message}
                                                className="form-control border-0 rd_6"
                                                onKeyDown={handlekey}
                                                onChange={(e) => { setMessage(e.target.value); setSuccess(''); }}
                                            />
                                            <div className='d-flex align-items-center me-2 rd_50 justify-content-center fs_13 text-white pointer hw_25px p-1 bg-gradient-thm'>
                                                <i className="fa-solid fa-paper-plane " onClick={handleSubmit}></i>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {err && <p className='fs_13 mb-2 mt-1 pt-0 mt-0 text-danger'>{err}</p>}

                        </>
                    </Col>
                </Row>
            </Container>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

        </div>
    )
}

export default TicketDetails