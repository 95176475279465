import React, { useCallback, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser';

import { Col, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import { CountriesDrop } from '../../Componets/Countries';
import { FilterHotels, FilterPackages, FilterVehicle } from '../Hotels/FilterSection';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { dateFormat } from '../../Componets/CommonFs';
import SignInModal from '../Auth/SignInModal';
import { useLocalStorage } from 'react-fu-hooks';
import { Autocomplete, GoogleMap, LoadScript } from '@react-google-maps/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import Counter from '../Hotels/Counter';
import { Tooltip } from '@mui/material';
import { FormVehicleModal, ParagraphModal } from '../../Componets/Modal';
const { RangePicker } = DatePicker;

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;
const g_map_key = process.env.REACT_APP_G_MAP_KEY;
const libraries = ['places'];



function VehicleList() {
    const location = useLocation();
    const state = location.state;

    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')
    const [vehicleId, setVehicleId] = useLocalStorage('vehicleId', '');
    const [clearStorage, setClearStorage] = useLocalStorage('vclearStorage', false);
    const [vehicleAdd, setVehicleAdd] = useLocalStorage('vehicleAdd', '');
    const [vehicleCoor, setVehicleCoor] = useLocalStorage('vehicleCoor', '');


    const [dates, setDates] = useState([])
    const [dateValue, setDateValue] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [page, setPage] = useState(1)
    const [reload, setReload] = useState(1)
    const [countryId, setCountryId] = useState('')
    const [notMore, setNotMore] = useState('')
    const [fuelType, setFuelType] = useState([])
    const [vehicleType, setVehicleType] = useState([])
    const [selectedAmenities, setSelectedAmenities] = useState([])
    const [search, setSearch] = useState(hotelLocation || '');
    const [showError, setShowError] = useState(false);

    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValues1, setSelectedValues1] = useState([]);
    const [roomDetails, setRoomDetails] = useState({
        room: 1,
        adults: 1,
        children: 0
    });
    const [error, setError] = useState({ to: false, from: false })

    const [clearCoor, setClearCoor] = useState(0);
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState({ open: false, terms: '' })
    const autocompleteRef1 = useRef(null);
    const inputRef1 = useRef(null);

    const autocompleteRef2 = useRef(null);
    const inputRef2 = useRef(null);

    const [from, setFrom] = useState('')
    const [to, setTo] = useState(vehicleAdd?.to || '')
    const [fromLatLng, setFromLatLng] = useState([]);
    const [toLatLng, setToLatLng] = useState([]);

    useEffect(() => {
        if (dates.length === 0) {
            const currentDate = new Date();
            const nextDate = new Date()
            nextDate.setDate(currentDate.getDate() + 1)
            const makeArr = [currentDate, nextDate]
            const parsedDate1 = dayjs(currentDate || null)
            const parsedDate2 = dayjs(nextDate || null)
            setDateValue([parsedDate1, parsedDate2])

            const formatted = makeArr.map(item => dateFormat(item, 'dmy').replace(/\//g, '-'))
            setDates(formatted)
        }
    }, [dates?.length === 0]);

    const handleDateChange = (values) => {
        if (values) {
            const formattedDates = values.map(item => dateFormat(item, 'dmy').replace(/\//g, '-'))
            setDates(formattedDates)
        } else {
            setDates([]);
        }
    };


    const bothLatLngsExist = (fromLatLng.length > 0 || vehicleCoor?.from?.length > 0) && (toLatLng.length > 0 || vehicleCoor?.to?.length > 0);



    useEffect(() => {
        vehicleList()
    }, [page, search, vehicleType, selectedAmenities, fuelType, reload]);




    const vehicleList = () => {
        const vehicle = vehicleType.length > 0 ? JSON.stringify(vehicleType)
            : (vehicleId?.length > 0 ? JSON.stringify(vehicleId) : [])

        const fromCoor = fromLatLng.length > 0 ? JSON.stringify(fromLatLng)
            : (vehicleCoor?.from?.length > 0 ? JSON.stringify(vehicleCoor?.from) : [])

        const toCoor = toLatLng.length > 0 ? JSON.stringify(toLatLng)
            : (vehicleCoor?.to?.length > 0 ? JSON.stringify(vehicleCoor?.to) : [])

        if (vehicleAdd.from !== '' && vehicleAdd.to !== '') {
            inputRef1.current.value = vehicleAdd?.from || ''
            inputRef2.current.value = vehicleAdd?.to || ''
        }


        // setVehicleCoor({ from: [], to: [] })
        setIsLoading(true)
        CommonAPI({}, 'GET', `vehicleList?from=${fromCoor}&to=${toCoor}&vehicleType=${vehicle}&fueltype=${fuelType.length > 0 ? JSON.stringify(fuelType) : []}&amenity=${selectedAmenities.length > 0 ? JSON.stringify(selectedAmenities) : []}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt?.data, key)

            setLoadMore(true);


            if (dec1 == undefined || dec1 == null) {
                setList(dec1)
            } else {
                setList(prevData => {
                    const newData = dec1?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                    return [...prevData, ...newData];
                });
            }

            if (dec1.length < 12) {
                setLoadMore(false);
            }

        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
        setIsLoading1(false)
    })


    const handleOk = (ty) => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setOpen1(false);
            setOpen2(false);
            setConfirmLoading(false);
        }, 1000);
    };

    const disbaleDate = (current) => {
        return current && current < moment().startOf('day');
    }

    const handleCheckboxChange = (value, type) => {
        setList([])
        setPage(1)
        if (type == 'vehicle') {
            if (value == 'no') {
                setVehicleId('')
                setVehicleType([])
                return
            }
            setVehicleType(prevValue => {
                const newValues = value.filter(id => !prevValue.includes(id));
                const newValues1 = prevValue.filter(id => !value.includes(id));

                return [...newValues1, ...newValues];
            })
        }

        if (type == 'fuel') {
            setFuelType(prevValue => {
                if (prevValue.includes(value)) {
                    return prevValue.filter(name => name !== value)
                } else {
                    return [...prevValue, value]
                }
            })
        }

        if (type == 'amenities') {
            setSelectedAmenities(prevValue => {
                if (prevValue.includes(value)) {
                    return prevValue.filter(name => name !== value)
                } else {
                    return [...prevValue, value]
                }
            })
        }
    };


    const handleShowMore = () => {
        setIsLoading1(true)
        setPage(prevPage => prevPage + 1)
    };

    const showErrorVal = (!fromLatLng || fromLatLng.length === 0) && (!toLatLng || toLatLng.length === 0)


    const vehicle = vehicleType.length > 0 ? vehicleType
        : (vehicleId?.length > 0 ? vehicleId : [])

    const fromPlaceSelect = () => {
        const place = autocompleteRef1.current.getPlace();

        if (place?.geometry) {
            const add = inputRef1.current.value;

            setFrom(add);

            setFromLatLng([
                place.geometry.location.lng(),
                place.geometry.location.lat(),
            ]);
        }
    };

    const toPlaceSelect = () => {
        const place = autocompleteRef2.current.getPlace();

        if (place?.geometry) {

            const add = inputRef2.current.value;

            setTo(add);
            setToLatLng([
                place.geometry.location.lng(),
                place.geometry.location.lat(),
            ]);
        }
    };

    const handleSearchList = () => {
        if (vehicleCoor.from.length > 0 && vehicleCoor.to.length > 0) {
            setList([])
            vehicleList()
            setShowError('')
            return
        }
        if (showErrorVal) {
            setShowError(true);
        } else {
            setShowError('')
            setList([])
            vehicleList()
        }
    };

    const handleChange = (e, type) => {
        if (type == 'from') {
            inputRef1.current = e.target.value
        } else {
            inputRef2.current = e.target.value
        }
        if (e.target.value == '') {
            if (type == 'from') {
                setVehicleAdd({ from: '', to: vehicleAdd.to })
                setVehicleCoor({ from: [], to: vehicleCoor.to })
            }

            if (type == 'to') {
                setVehicleAdd({ from: vehicleAdd.from, to: '' })
                setVehicleCoor({ from: vehicleCoor.from, to: [] })
            }
            const status = inputRef1.current == '' && inputRef2.current == ''

            const rn = Math.floor(Math.random() * 989889999 + 1)


            if (status) {
                setReload(rn)
                setVehicleAdd({ from: '', to: '' })
                setVehicleCoor({ from: [], to: [] })
                setList([])
                setFromLatLng([])
                setToLatLng([])
            }
        }
    };


    return (
        <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5 bg-gray'>
            <div className="w_92 mt-4 mt-0px--sm mb-5 d-flex align-items-center flex-column">

                <Row className='mt-4 w-100 mt-0px--sm'>
                    <Col xs={0} sm={0} md={5} lg={4} xl={3} className='d-none-on-lg h-auto'>
                        <FilterVehicle vehicle={vehicle} fuel={fuelType} amenities={selectedAmenities} onChange={handleCheckboxChange} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9} className='bg-primary1 pt-0 px-0 d-flex justify-content-start align-items-center flex-column'>
                        <div className='bg-white d-flex align-items-center flex-column flex-sm-row rd_6 p-3 w_97s mx-0-7 mb-4'>
                            <div className={`p-0 me-sm-2 flex-grow-1 w-md-100 mb-xs1-20 d-hide-on-lg ${showError ? 'mb-3' : ''}`}>
                                <Autocomplete onLoad={(ref) => (autocompleteRef1.current = ref)} onPlaceChanged={fromPlaceSelect}>
                                    <input
                                        ref={inputRef1}
                                        type='text'
                                        onChange={(e) => handleChange(e, 'from')}
                                        placeholder='Search Pickup location'
                                        style={{ width: '100%', padding: '10px' }}
                                        className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                    />
                                </Autocomplete>

                            </div>
                            <div className='bg-primary11 p-0 me-sm-2 flex-grow-1 w-md-100 mb-xs1-20 d-hide-on-lg'>
                                <Autocomplete onLoad={(ref) => (autocompleteRef2.current = ref)} onPlaceChanged={toPlaceSelect}>
                                    <input
                                        ref={inputRef2}
                                        type='text'
                                        onChange={(e) => handleChange(e, 'to')}
                                        placeholder='Search Drop location'
                                        style={{ width: '100%', padding: '10px' }}
                                        className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                    />
                                </Autocomplete>

                                {showError && (
                                    <p className='fs-12px pt-0 m-0 text-danger'>Enter both location!</p>
                                )}
                            </div>
                            <div className={`d-hide-on-lg `}>
                                <div className={`${(vehicleCoor?.from?.length == 0 || vehicleCoor?.to?.length == 0) ? 'd-block' : 'd-none'}`}>
                                    <div className={`Source_Medium w-100p--xs-g p-1 px-2 bg-gradient-thm flex-grow-1 text-white d-flex align-items-center justify-content-center rd_10 m-0 pointer custom_field ${showError ? 'mb-20px' : ''}`} onClick={() => handleSearchList()}>
                                        Search
                                    </div>
                                </div>
                            </div>
                            <div className='bg-warning1 d-flex w-md-100'>

                                <div className='d-show-on-mdl'>
                                    <div className='d-flex'>
                                        <p className='Source_Medium text-white m-0 mr-10px px-3 rd_6 fs-13px h-35px bg-gradient-thm d-flex align-items-center justify-content-center pointer' onClick={() => setOpen2(true)}><i className='bi bi-search fs_13 mr_5'></i> SEARCH</p>
                                        <p className='Source_Medium text-white m-0 px-3 rd_6 fs-13px h-35px bg-gradient-thm d-flex align-items-center justify-content-center pointer' onClick={() => setOpen1(true)}>FILTER <i className='bi bi-filter ml-10px'></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className='d-flex justify-content-center mb-4'>
                                <Spin className='thm-spin' />
                            </div>
                        )}

                        <Row className='d-flex justify-sm-center w-100'>
                            {/* Listing */}
                            {list && list.map((item, i) => {

                                return (
                                    <Col xs={12} sm={12} lg={6} xl={4} xxl={4} key={i} className="xmd-6 m-xxs-auto mb-4 w-xxs-99 px-2 px-xs-0">
                                        <div className='mb-xs-10 shadow-n1 bg-white rd_6 p-0 overflow-hidden position-relative'>
                                            <Link to={`/vehicle/details/${item.id}`} className='text-decoration-none hover-me'>

                                                <div className='bg-white p-2'>
                                                    <img src={`${img_base_url + item.image}`} alt={item.name} className='img-fluid h_120px w-100 position-relative bg-white overflow-hidden' />
                                                </div>
                                                <h3 className='Rubik-Medium text_gray fs_15 fs-xs-14 spacing ps-2 pt-2'>{item.name}</h3>

                                                <div className="p-2 px-2-5 overflow-hidden position-relative">
                                                    <div className='d-flex align-items-center Source_Medium'>
                                                        <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Seat Included</p>
                                                        <p className='fs_14 bg-warning1 m-0 text_gray2'>{item.sheets}</p>
                                                    </div>
                                                    <div className='d-flex align-items-center Source_Medium'>
                                                        <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Luggage Bag</p>
                                                        <p className='fs_14 bg-warning1 m-0 text_gray2'>{item.luggage || 0}</p>
                                                    </div>
                                                    <div className='d-flex align-items-center Source_Medium'>
                                                        <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Waiting Charges</p>
                                                        <p className='fs_14 bg-warning1 m-0 text_gray2'>₹{item.waitingCharges}</p>
                                                    </div>
                                                    <div className='d-flex align-items-center Source_Medium'>
                                                        <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Per KM</p>
                                                        <p className='fs_14 bg-warning1 m-0 text_gray2'> ₹{item.chargesKm}</p>
                                                    </div>

                                                    {/* <label className='Source_Regular text-info fs_12 mb-1 pointer' onClick={() => { setOpen4({ open: true, terms: terms }) }}>Other Terms & Charges</label> */}

                                                    <div className='pointer p-1 mt-2 rd_50 h-xxs-35px border_thm text_thm Source_Medium d-flex align-items-center justify-content-center' onClick={() => setOpen3(true)}>
                                                        Request Callback
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                )
                            })}

                            {loadMore && !isLoading && list && list?.length > 0 && (
                                <div className='d_flex_align_justify_center  mt-4'>
                                    <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                                </div>
                            )}

                            {!isLoading && list && list?.length == 0 && (
                                <div className='d_flex_align_justify_center flex-column mt-5'>
                                    <img src='/images/not_data.webp' alt='not found' className='img-fluid h_130px w_200px mt-5' />
                                    <div className='Rubik-Regular text_gray2 mt-2'>No vehicles found</div>
                                </div>
                            )}
                        </Row>
                    </Col>
                </Row>

            </div>

            <Modal
                title="Search"
                className='Source_Medium text_gray w_60 w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '15px', height: 'auto'
                    },
                    header: {
                        padding: '10px 10px 0 10px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open2}
                onOk={handleOk}
                footer={null}
                onCancel={() => setOpen2(false)}
            >
                <div className='bg-warnin1g  align-items-end mr_10 mt-3'>
                    <Row className='d-flex justify-content-between'>

                        <Col className='mb-2 bg1-warning'>
                            <div className='bg-primary11 p-0 me-sm-2 mb-10px flex-grow-1 mb-xs1-20'>
                                <Autocomplete onLoad={(ref) => (autocompleteRef1.current = ref)} onPlaceChanged={fromPlaceSelect}>
                                    <input
                                        ref={inputRef1}
                                        type='text'
                                        onChange={(e) => handleChange(e, 'from')}
                                        placeholder='Search Pickup location'
                                        style={{ width: '100%', padding: '10px' }}
                                        className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                    />
                                </Autocomplete>

                            </div>
                            <div className='bg-primary11 p-0 me-sm-2 flex-grow-1 mb-xs1-20'>
                                <Autocomplete onLoad={(ref) => (autocompleteRef2.current = ref)} onPlaceChanged={toPlaceSelect}>
                                    <input
                                        ref={inputRef2}
                                        type='text'
                                        onChange={(e) => handleChange(e, 'to')}
                                        placeholder='Search Drop location'
                                        style={{ width: '100%', padding: '10px' }}
                                        className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                    />
                                </Autocomplete>

                                {showError && (
                                    <p className='fs-12px pt-0 m-0 text-danger'>Enter both location!</p>
                                )}
                            </div>
                        </Col>
                        <Col lg={1} md={12} xs={12} className='d-flex mt-2 bg1-warning'>
                            <div className='Source_Medium p-1 px-2 bg-gradient-thm flex-grow-1 text-white d-flex align-items-center justify-content-center rd_10 m-0 pointer custom_field' onClick={() => { handleSearchList() }}>
                                Search
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal
                title="Room & Guest"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open}
                onOk={() => { vehicleList(); handleOk(); setList([]) }}
                confirmLoading={confirmLoading}
                onCancel={() => setOpen(false)}
            >
                <Counter details={roomDetails} setDetails={setRoomDetails} />
            </Modal>

            <Modal
                title="FILTER"
                className='Source_Medium text_gray w-xs-auto mx-xs-4 '
                styles={{
                    body: {
                        padding: '0 20px', overflowY: 'auto', maxHeight: '65vh'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open1}
                onOk={handleOk}
                onCancel={() => setOpen1(false)}
            >
                <div className="scrollable-h">
                    <FilterVehicle vehicle={vehicle} fuel={fuelType} amenities={selectedAmenities} onChange={handleCheckboxChange} />
                </div>
            </Modal>

            <ParagraphModal isVisible={open4} onClose={setOpen4} />

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

            {/* From */}
            <FormVehicleModal isVisible={open3} onClose={setOpen3} />
        </div>
    )
}

export default VehicleList