import React, { useCallback, useEffect, useState } from 'react'
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { StatesList } from '../../Componets/Countries';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { svTripCate } from '../../ContextRedux/Action';
import CarouselMe_D from '../../Componets/CarouselMe';
import Carousel from 'react-multi-carousel';
import { openInNewTab } from '../../Componets/CommonFs';
import { Row } from 'react-bootstrap';
import { Spin } from 'antd';
import { Loader } from '../../Componets/Loader';
import { Helmet } from 'react-helmet';
import { GridCategories, TagsSection } from '../../Componets/Modal';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const responsive_8 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


function PkgIndex() {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentMonth = new Date().getMonth();
    const ordersMonth = [
        ...months.slice(currentMonth),
        ...months.slice(0, currentMonth)
    ]

    const [selected, setSelected] = useState(ordersMonth[0]);
    const [state, setState] = useState({ cID: '', month: [ordersMonth[0]], location: '', categoryId: [] })
    const [cName, setCName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [monthClicked, setMothClicked] = useState(false);
    const [reloadAgain, setReloadAgain] = useState(0);
    const [showTags, setShowTags] = useState(30);
    const [showTags1, setShowTags1] = useState(30);
    const [showTags2, setShowTags2] = useState(30);
    const [list, setList] = useState([])
    const [pkgHomeList, setPkgHomeList] = useState([]);
    const [pkgList1, setPkgList1] = useState([]);
    const [pkgList2, setPkgList2] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [pkgWeekend, setPkgWeekend] = useState([]);
    const [pkgLuxury, setPkgLuxury] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const categories = useSelector(reducer => reducer.data.category);

    useEffect(() => {

        tripCateList();
        homePackageList();
    }, [reloadAgain]);

    const tripCateList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `tripCategoryList`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setList(dec1?.tripcategory)

            const categories = dec1?.tripcategory || [];
            dispatch(svTripCate(categories))
        } else {
        }
        setIsLoading(false)
    })


    const homePackageList = () => {
        setIsLoading1(true)
        setTagsList([])
        CommonAPI({}, 'GET', `homePackageList?country=${state.cID || ''}&location=${state.location || ''}&month=${state.month || ''}`, responseBack1)

        setIsLoading2(true)
        CommonAPI({}, 'GET', `topTagsList?type=country&typeId=${Number(state.digit) || ''}`, responseBack2)
    }

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)

            setPkgHomeList({ countries: dec1?.countries, locations: dec1?.locations })
            setPkgList1(dec1?.packages)
            setPkgList2(dec1?.monthlypackages)
            setPkgWeekend(dec1?.weekend)
            setPkgLuxury(dec1?.luxury)
        }

        setIsLoading1(false)
    })

    const responseBack2 = useCallback(res => {

        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setTagsList(dec1)
        }

        setIsLoading2(false)
    })

    const listHome = Object.keys(pkgHomeList).length > 0 && pkgHomeList

    const handleCallback = async (value, type) => {
        setMothClicked(false);

        if (state?.cID == value) {

            const update = { cID: value, month: [], location: '', categoryId: '', cName: cName }
            setState(update);

            // homePackageList(update)
            localStorage.setItem('pkgHome', JSON.stringify(update));
        } else {
            const rn = Math.floor(Math.random() * 99888899 + 1)

            setState(prevValues => {
                const newState = {
                    ...prevValues,
                    cName: cName,
                    [type]: type == 'month' ? [value] : value
                }
                localStorage.setItem('pkgHome', JSON.stringify(newState));
                if (type == 'digit') {

                    setReloadAgain(rn)
                }

                if (type == 'categoryId') {
                    goToPkgList()
                }
                return newState;
            })
        }
    }


    const goToPkgList = (type, category) => {
        const pkgHome = localStorage.getItem('pkgHome');
        const pkgHomeParams = JSON.parse(pkgHome)

        const month = type == 'month' ? pkgHomeParams?.month : []
        const newState = {
            ...pkgHomeParams,
            cName: cName,
            month
        }

        if (type == 'category') {
            localStorage.setItem('searchParams', JSON.stringify(newState));

            navigate(`/packages/${category}`)
            return
        }

        if (state?.cID) {
            localStorage.setItem('searchParams', JSON.stringify(newState));

            navigate('/packages/list')
        }
    }

    const isVisible = listHome?.countries?.length > 0


    return (
        <div className='d-flex flex-column align-items-center bg-white mt-12 pt-3 p-0 bg-light' style={{ backgroundColor: '#f9fafb' }}>

            {isLoading1 && !monthClicked && (
                <div className='d-flex justify-content-center mb-4'>
                    <Spin className='thm-spin' />
                </div>
            )}
            <div className='w_95 mt-4'>
                {/* Country & State list */}
                <CarouselMe_D countries={listHome?.countries || []} img_base_url={img_base_url} callback={handleCallback} digitId={handleCallback} c_Name={setCName} />


                <div className='bg-warning1 d-flex align-items-center mt-50px me-2 justify-content-between'>
                    <div className="Rubik-SemiBold fs_16 ms-2 min-line one">Popular Packages in {cName}</div>

                    <Helmet>
                        <meta charSet="UTF-8" />
                        <title>Travel Vacation Packages | Luxury Tour Packages India | Travellinglark</title>
                        <meta name='description' content='Travel Vacation Packages and Luxury Tour Packages India by Travellinglark Offering Custom-crafted Holiday Plans With Comfort, Style, and Unique Experiences.' />
                        <meta name="keywords" content="Travel Vacation Packages, Vacation Tour Packages India, Long Weekend Vacation Packages, Luxury Vacation Package Deals, Luxury Tour Packages India" />
                        <link rel="canonical" href="https://www.travellinglark.com/packages/" />
                    </Helmet>

                    {pkgList1 && pkgList1.length > 0 && (
                        <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('cId')}>
                            <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                            <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                        </div>
                    )}
                </div>

                {isLoading1 && !monthClicked ? (
                    <div className='w-100'>
                        <Loader type={'pkg'} />
                    </div>
                ) : (
                    pkgList1 && pkgList1.length > 0 ? (
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            responsive={responsive_8}
                            ssr={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                            // removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                            dotListClass="custom-dot-list-style"
                        >
                            {pkgList1 && pkgList1?.slice(0, 4).map((item, index) => {
                                const lowPrice = item.discountPrice
                                const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                const vendor = item?.vendorId
                                return (
                                    <div key={item._id} className='hover-highlight-pkg  pkg-btn-hover border_white br-2 mt-3 mb-4 rd-15px mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                        <Link state={{ type_id: item._id, type: 'Package', cId: state?.cID }} to={`/package/${item?.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none hover-me'>
                                            <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100 rd-tl-tr-15px' />

                                            <div className='p-2-5'>
                                                <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{vendor?.business_name}</span></p>

                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                                        <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                                        {lowPrice != null && lowPrice > 0 && (
                                                            <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                                        )}
                                                    </div>
                                                    <div className='book-now border_thmdark rounded-5 fs_12 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </Carousel>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' alt='no popular packages' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No popular packages available</div>
                        </div>
                    )
                )}


                {!isLoading1 && listHome?.locations?.length > 0 && <h2 className="mb-2 Rubik-SemiBold fs_16 mb-3 mt-4 ms-2">Popular Locations in {cName}</h2>}
                {(isLoading1 && !monthClicked) ? (
                    <div className='w-100'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-3 mt-4 ms-2">Popular Locations in {cName}</h2>
                        <Loader type={'category'} mt={'mt-3'} />
                    </div>
                ) : (
                    listHome?.locations?.length > 0 && (
                        <StatesList data={listHome?.locations || []} selected={state?.location || ''} callback={handleCallback} />
                    )
                )}

                {/* ===========================2nd section================================ */}



                {/* Trip categories */}
                <div className="container text-center bg-danger2 mt-4">
                    <h2 className="mb-2 Rubik-SemiBold fs_20 fs-xs-18 mb-4">Explore Packages by Theme</h2>
                </div>

                <GridCategories handleChange={handleCallback} tripCate={list || []}/>

                {/* Months */}
                {isVisible && (
                    <div className='bg_thmlightdark mt-5 mb-3 mx-0-7 pt-4 pb-4 rd_6 d-flex flex-column align-items-center'>
                        <div className='text-center d-flex mb-3 align-center justify-center w-100'>
                            <h2 className="Rubik-SemiBold fs_20 flex-column text-end flex-grow-1">Month wise Trips</h2>

                            <div className='bg-warning1 d-flex align-items-center me-2 flex-grow-1 pointer justify-end position-relative' style={{ top: -20, right: 8 }} onClick={() => goToPkgList('month')}>
                                <div className='Rubik-Regular text_thmdark fs_14' >View All</div>
                                <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                            </div>
                        </div>
                        <div className='d-flex align-items-center pb-3 scrollable-container scrollable'>
                            {ordersMonth.map((month, index) => (
                                <div
                                    key={index}
                                    onClick={() => { handleCallback(month, 'month'); setSelected(month); setMothClicked(true); }}
                                    className={`mt-2 pointer fs_13 p-1 rounded-5 mx-1 px-3 ${month == selected ? 'Source_Medium current-month text-white bg_thm' : 'Sansation_Regular text_gray2 border_white brc-secondary'}`}
                                >
                                    {month}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* <div className='w-100'>
                    <Loader type={'pkg'} mt={'mt-5'} />
                </div> */}

                {isLoading1 ? (
                    <Loader type={'pkg'} mt={'mt-4-5'} />
                ) : (
                    pkgList2 && pkgList2.length > 0 ? (
                        <>

                            {/* filter list */}
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                responsive={responsive_8}
                                ssr={true}
                                autoPlay
                                autoPlaySpeed={2500}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                containerClass="carousel-container"
                                itemClass="carousel-item1 "
                                customLeftArrow={
                                    <CustomLeftArrow
                                        direction="left"
                                    />
                                }
                                customRightArrow={
                                    <CustomLeftArrow
                                        direction="right"
                                    />
                                }
                                // removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                                dotListClass="custom-dot-list-style"
                            >
                                {pkgList2 && pkgList2?.slice(0, 4).map((item, index) => {
                                    const lowPrice = item.discountPrice
                                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                    const vendor = item?.vendorId
                                    return (
                                        <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd-15px mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                            <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                                                <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100 rd-tl-tr-15px' />

                                                <div className='p-2-5'>
                                                    <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                    <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId?.business_name}</span></p>

                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                                            {lowPrice != null && lowPrice > 0 && (
                                                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                                            )}
                                                        </div>
                                                        <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' alt='No month packages available' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No month packages available</div>
                        </div>
                    )
                )
                }


                <div className='bg-warning1 d-flex align-items-center mt-50px me-2 justify-content-center'>
                    <h1 className="Rubik-SemiBold fs-20px fs-18px--sm ml-60px text-center flex-grow-1">Weekend Trips</h1>
                    <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('category', 'weekend-trips')}>
                        <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                        <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                </div>
                <h2 className="Rubik-Regular fs-15px text_gray mb-10px text-center">Transform your weekends into memories- Pack your bags and explore!</h2>

                {isLoading1 ? (
                    <Loader type={'pkg'} mt={'mt-4-5'} />
                ) : (
                    pkgWeekend && pkgWeekend.length > 0 ? (
                        <>
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                responsive={responsive_8}
                                ssr={true}
                                autoPlay
                                infinite
                                autoPlaySpeed={2500}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                containerClass="carousel-container"
                                itemClass="carousel-item1 "
                                customLeftArrow={
                                    <CustomLeftArrow
                                        direction="left"
                                    />
                                }
                                customRightArrow={
                                    <CustomLeftArrow
                                        direction="right"
                                    />
                                }
                                // removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                                dotListClass="custom-dot-list-style"
                            >
                                {pkgWeekend && pkgWeekend?.slice(0, 4).map((item, index) => {
                                    const lowPrice = item.discountPrice
                                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                    const vendor = item?.vendorId
                                    return (
                                        <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd-15px mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                            <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                                                <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100 rd-tl-tr-15px' />

                                                <div className='p-2-5'>
                                                    <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                    <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId[0]?.business_name}</span></p>

                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency[0]?.code}{final}</strong>/Person</div>
                                                            {lowPrice != null && lowPrice > 0 && (
                                                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency[0]?.code}{item.price}</div>
                                                            )}
                                                        </div>
                                                        <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' alt='No month packages available' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No month packages available</div>
                        </div>
                    )
                )
                }


                <div className='bg-warning1 d-flex align-items-center mt-50px justify-content-center'>
                    <h2 className="Rubik-SemiBold fs-20px fs-18px--sm ml-60px text-center flex-grow-1">Luxury Trips</h2>
                    <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('category', 'luxury-trips')}>
                        <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                        <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                </div>
                <h2 className="Rubik-Regular fs-15px text_gray mb-15px text-center">Explore the world in style – Luxury trips designed to elevate your journey</h2>

                {isLoading1 ? (
                    <Loader type={'pkg'} mt={'mt-4-5'} />
                ) : (
                    pkgLuxury && pkgLuxury.length > 0 ? (
                        <>
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                responsive={responsive_8}
                                ssr={true}
                                autoPlay
                                infinite
                                autoPlaySpeed={2500}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                containerClass="carousel-container"
                                itemClass="carousel-item1 "
                                customLeftArrow={
                                    <CustomLeftArrow
                                        direction="left"
                                    />
                                }
                                customRightArrow={
                                    <CustomLeftArrow
                                        direction="right"
                                    />
                                }
                                // removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                                dotListClass="custom-dot-list-style"
                            >
                                {pkgLuxury && pkgLuxury?.slice(0, 4).map((item, index) => {
                                    const lowPrice = item.discountPrice
                                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice


                                    return (
                                        <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd-15px mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                            <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                                                <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100 rd-tl-tr-15px' />

                                                <div className='p-2-5'>
                                                    <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                    <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId[0]?.business_name}</span></p>

                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency[0]?.code}{final}</strong>/Person</div>
                                                            {lowPrice != null && lowPrice > 0 && (
                                                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency[0]?.code}{item.price}</div>
                                                            )}
                                                        </div>
                                                        <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' alt='No month packages available' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No month packages available</div>
                        </div>
                    )
                )
                }



                {/* ===========================Tags section=============================== */}
            </div> 

            <TagsSection tags={tagsList} cName={cName} categories={categories}/>

            <p className='fs-13px Inter-Regular mt-40px w-90p pb-20px'>
                Explore the best travel vacation packages, including vacation tour packages designed for every traveler. Plan your perfect getaway with long weekend vacation packages, ideal for quick and refreshing escapes. Discover luxury vacation package deals for those seeking an indulgent travel experience. From exotic destinations to premium accommodations, our luxury tour packages cater to your every need. Whether you're looking for family-friendly trips, romantic vacations, or adventure-filled journeys, find tailored options that match your preferences. Book your dream holiday with exclusive packages that combine comfort, style, and unforgettable experiences.
            </p>
        </div>
    )
}

export default PkgIndex