import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CommonAPI } from '../../../Componets/CommonAPI';
import { dateFormat } from '../../../Componets/CommonFs';
import { dec } from '../../../Componets/SecRes';
import { Spin } from 'antd';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function Blogs() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [loadMore, setLoadMore] = useState(true);


  useEffect(() => {
    getList(page);
  }, [page, search]);

  const getList = () => {
    setIsLoading(true)
    CommonAPI({}, 'GET', `blogList?keyword=${search}&page=${page}`, responseBack)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)


      setLoadMore(true);
      if (dec1 == undefined || dec1 == null) {
        setList(res?.data)
      } else {
        setList(prevData => {
          const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
          return [...prevData, ...newData];
        });
      }
      if (dec1.length < 10) {
        setLoadMore(false);
      }
    }
    setIsLoading1(false)
    setIsLoading(false)
  })



  return (
    <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center bg-gray'>

      <div className='w_92 bg-succes1s mb-5 d-flex align-items-center flex-column'>

        {/* Search */}
        <Row className='bg-white rd_6 p-3 gap-3 mt-4 mb-2 w-100'>
          <Col className='bg-primary11 p-0 flex-grow-1'>
            <InputGroup className='z-1'>
              <InputGroup.Text className='input-group-text pointer h_40px'>
                <i className='fa fa-search text_thm'></i>
              </InputGroup.Text>
              <FormControl
                className='search-input text_gray fs_13 rd_tr_br_6 px-2 h_40px'
                type='text'
                placeholder='Search'
                style={{ height: 35, color: 'red' }}
                value={search}
                onChange={(event) => { setList([]); setSearch(event.target.value); }}
              />
            </InputGroup>
          </Col>
        </Row>

        {isLoading && (
          <div className='d-flex justify-content-center mb-4'>
            <Spin className='thm-spin' />
          </div>
        )}

        <Row className='bg-primary1 mt-4 w-100'>
          {/* Listing */}
          {list && list.map((item) => {
            const word = item?.sortDescription.split(/\s+/);

            const dt = dateFormat(item?.createdAt, 'date')
            // const desc = HTMLRender()
            return (
              <Col xs={6} sm={6} md={4} lg={3} key={item.id} className=" w-100p--xs-g mb-20px bg-warning1">
                <div className='mb-xs-10 bg-white shadow-slowclean rd_6 p-0 overflow-hidden position-relative h-280px'>
                  <Link to={`/blog/${item.slug}/${item.id}`} className='text-decoration-none hover-me'>
                    <img src={`${img_base_url + item.image}`} alt={item.name} className='img-fluid h_150px w-100 position-relative overflow-hidden' />
                    <h3 className='Rubik-Medium text_gray fs-15px px-3 pt-3 pb-1 m-0 fs-xs-14 bg-primar1y text-capitalize mini-line-1'>{item.title}</h3>
                    <div className="px-3 pb-3 overflow-hidden position-relative pt-0 bg-warning1">
                      <p className='Source_Medium fs_12 text-primary m-0'>By {item.writer || 'Anonymous'} / {dt}</p>
                      <p className='Rubik-Regular text-secondary fs_13 m-0 mt-15px mini-line-1 two'>
                        {item.sortDescription}
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
            )
          })}

          {!isLoading && list && list?.length == 0 && (
            <div className='d_flex_align_justify_center flex-column mt-5'>
              <img src='/icons/not-found.png' alt='not found' className='img-fluid h_100px w_100px mt-5' />
              <h5 className='Source_SemiBold mt-4'>no blogs found</h5>
            </div>
          )}
        </Row>
      </div>
    </div>
  )
}

export default Blogs