import React, { useCallback, useContext, useEffect, useState } from 'react';
import '../CSS/Modal.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, DatePicker, Dropdown, Row, Col, Input, Modal, Radio, Space, Spin, Typography } from 'antd';
import { googleLogout } from '@react-oauth/google';
import { Spinner } from 'react-bootstrap';
import { tstError, tstSuccess } from './HotToast';
import { MemContext } from '../ContextRedux/Context';
import { useDispatch } from 'react-redux';
import { svInfo, svMsgId, svReceiverDetails, svTripCate } from '../ContextRedux/Action';
import { options } from './Images';
import { CommonAPI } from './CommonAPI';
import { enc } from './SecRes';
import SignInModal from '../Pages/Auth/SignInModal';
import { useNotification } from '../ContextRedux/NotificationContext';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;


const items1 = [
    {
        key: '1',
        label: 'Edit',
    },
    {
        key: '2',
        label: 'Delete',
    }
]


// Menu

const MenuModal = ({ isVisible, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logout, userInfo } = useContext(MemContext)
    const location = useLocation();

    const [selectedKey, setSelectedKey] = useState([]);


    useEffect(() => {
        const case2 = userInfo?.role !== 'vendor' ? '/my-travels' : '/my-proposals'

        switch (location.pathname) {
            case '/profile':
                setSelectedKey(['1']);
                break;
            case '/my-proposals':
                setSelectedKey(['2']);
                break;
            case '/my-travels':
                setSelectedKey(['3']);
                break;
            case '/':
                setSelectedKey([]);
                break;
            default:
                setSelectedKey([]);
                break;
        }
    }, [location.pathname]);


    const outBro = async () => {
        try {
            dispatch(svInfo({}))
            dispatch(svTripCate([]))
            dispatch(svMsgId(''))
            dispatch(svReceiverDetails({}))

            await logout()
            await googleLogout()

            window.location.href = '/';
        } catch (error) {
            tstError('Something went wrong')
        }
    }

    const handleMenuClick = (e) => {
        const key = e.key;
        switch (key) {
            case '1':
                navigate('/profile');
                break;
            case '2':
                navigate('/my-proposals');
                break;
            case '3':
                navigate('/my-travels');
                break;
            case '4':
                outBro()
                break;
            default:
                break;
        }
    };

    // const type = userInfo == null && userInfo?.role !== 'vendor'

    const itemsAll = [
        {
            key: '1',
            label: 'View Profile',
        },
        {
            key: '2',
            label: `My Proposals`,
        },
        {
            key: '3',
            label: `My Travels`,
        },
        {
            key: '4',
            label: 'Log Out',
        }
    ];


    const items = userInfo?.role === 'vendor'
        ? itemsAll
        : itemsAll.filter(item => item.label !== 'My Proposals');


    return (
        <Dropdown
            menu={{
                items,
                selectable: true,
                onSelect: handleMenuClick,
                style: {
                    fontSize: 14,
                    marginRight: -20
                },
                selectedKeys: selectedKey,
                className: ' flex-column d-flex align-items-center justify-content-around'
            }}
            open={isVisible}
            onOpenChange={onClose}
            placement="bottomLeft"
            arrow={{
                pointAtCenter: true,
            }}
        >
            <Typography.Link className='bg-primary1 ms-4'>
                <Space className='bg-white rd_50 border_gray pointer p-1'>
                    <img src='https://bootdey.com/img/Content/avatar/avatar7.png' alt='Profile Menu' className='rd_6 hw_22px m-1' />
                </Space>
            </Typography.Link>
        </Dropdown>
    )
}

const ParagraphModal = ({ isVisible, onClose }) => {
    const handleOk = (ty) => {
        setTimeout(() => {
            // onClose(false);
        }, 1000);
    };


    return (
        <Modal
            title="Other Charges and Taxes"
            className='Source_Medium text_gray w-xs-auto mx-xs-4'
            styles={{
                header: {
                    padding: '15px 0 10px 15px',
                },
                footer: {
                    padding: '10px'
                },
                body: {
                    padding: '10px 20px', height: 'auto'
                }
            }}
            open={isVisible.open}
            onOk={handleOk}
            onCancel={() => onClose(false)}
            footer={null}
        >
            <div className=''>
                <p className='Source_Regular text-secondary'>
                    {isVisible.terms}
                </p>
            </div>
        </Modal>
    )
}

const OpenImageModal = ({ type, isVisible, onClose, img }) => {
    const handleOk = (ty) => {
        setTimeout(() => {
            onClose(false);
        }, 1000);
    };
    const imgShow = img_base_url + img


    return (
        <Modal
            style={{ top: '3.5%' }}
            width={'90%'}
            height={'75vh'}
            styles={{
                body: {
                    height: '100%',
                    overflowY: 'hidden',
                },
            }}
            open={isVisible}
            onOk={handleOk}
            onCancel={() => onClose(false)}
            footer={null}
        >
            <div className="ant-modal-body d-align-justify-center vh-50">
                <img src={`${imgShow}`} alt='Image Modal' className='img-fluid h-auto rd_6 set-original-size' />
            </div>
        </Modal>
    )
}

const FormVehicleModal = ({ isVisible, onClose, img }) => {
    const [dates, setDates] = useState([])
    const [terms, setTerms] = useState(false)
    const [isLoading, setIsLoading] = useState(false);


    const handleOk = (ty) => {
        setTimeout(() => {
            setIsLoading(false)
            onClose(false);
        }, 1000);
    };

    const handleDateChange = (date) => {
        if (date) {
            setDates(date);
        } else {
            setDates(null);
        }
    };

    const handleMe = () => {
        try {
            tstSuccess('Vehicle is booked successully')

        } catch (error) {
        }
        setIsLoading(false)
    }

    return (
        <div className=''>
            <Modal
                title="Fill Contact Form"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    header: {
                        padding: '15px 0 10px 15px',
                    },
                    footer: {
                        padding: '10px'
                    },
                    body: {
                        padding: '10px 20px',
                    }
                }}
                open={isVisible}
                onOk={handleOk}
                onCancel={() => {
                    onClose(false);
                    setIsLoading(false);
                }}
                footer={null}
            >
                <p className='m-0 fw-bold text_20 fs_15'></p>

                <div className="form-group flex-grow-1 mt-1">
                    <label className='Source_Medium text_gray fs_12 mb-1'>Full Name</label>
                    <input type="text" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" required placeholder="full name" />
                </div>

                <div className="form-group flex-grow-1 mt-3">
                    <label className='Source_Medium text_gray fs_12 mb-1'>Email</label>
                    <input type="email" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" required placeholder="email" />
                </div>

                <div className="form-group flex-grow-1 mt-3 d-flex">
                    <div className='bg-warning1 flex-grow-1 mr_10'>
                        <label className='Source_Medium text_gray fs_12 mb-1'>Phone Number</label>
                        <input type="number" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" required placeholder="number" />
                    </div>
                    <div className='bg-warning1'>
                        {/* <div className="form-group flex-grow-1 mt-3"> */}
                        <label className='Source_Medium text_gray fs_12 mb-1 d-block pt-0-5'>Date of Travel</label>
                        <DatePicker
                            className='form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                            onChange={handleDateChange}
                            value={dates}
                        />
                    </div>
                    {/* </div> */}
                </div>

                <div className="form-group mb-3 mt-2">
                    <label className='Source_Medium text_gray fs_12 mb-1'>Additional message</label>
                    <textarea name="description" placeholder='meesage' rows={4} className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" required />
                </div>

                <Checkbox checked={terms}
                    onChange={() => setTerms(!terms)} className='fs_14 Source_Regular'>
                    <label className='Source_Regular text_gray fs_12 mb-1'>I agree with <span className='Source_Regular text-info pointer'>Terms of Use</span> & <span className='Source_Regular text-info pointer'>Cancellation Policy</span> of this service.</label>
                </Checkbox>

                <button className='w-100  btn bg_thm mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium p-1-5 px-2' disabled={isLoading && true} onClick={() => handleMe()}>
                    {isLoading ? <Spinner size='sm' /> : 'Submit'}
                </button>
            </Modal>
        </div>
    )
}

const ReportSection = ({ isVisible, onClose, id, type }) => {
    const [reason, setReason] = useState('')
    const [terms, setTerms] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleOk = (ty) => {
        setTimeout(() => {
            setIsLoading(false)
            onClose(false);
            setReason('')
        }, 1000);
    };


    const handleMe = (e) => {
        const selected = options.find(item => e.target.value == item.value)
        setReason(selected.text)
    }

    const handleReport = () => {
        setIsLoading(true)

        const type1 = type == 'q' ? 'query' : 'proposal'

        const sendTo = {
            typeId: id,
            type: type1,
            reason: reason
        }
        const enc1 = enc(sendTo, key)

        CommonAPI(enc1, 'POST', 'createReport', responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            tstSuccess('Report submitted successful')
            onClose();
            setReason('');
            setIsLoading(false)
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })


    const sl = type == 'p' ? options.slice(6, 13) : options.slice(0, 6)

    return (
        <>
            <Modal
                title="Help us understand what's happening"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0px 20px 10px 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0 10px 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={isVisible}
                onOk={handleOk}
                onCancel={() => {
                    onClose(false);
                    setIsLoading(false);
                    setReason('')
                }}

                footer={[
                    <Button key="cancel" onClick={() => onClose(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" disabled={!reason?.trim() || isLoading} onClick={handleReport}>
                        Submit
                    </Button>
                ]}
            >
                <p className='m-0 Source_SemiBold text_20 fs_15'>What's going on?</p>

                <Radio.Group onChange={handleMe} name='radiogroup' className='mt-3'>
                    <Space direction='vertical'>
                        {sl.map((item, i) => {
                            return (
                                <>
                                    <Radio key={item.value} value={item.value}>
                                        {item.text}
                                    </Radio>
                                    {13 == item.value ?
                                        <>
                                            <textarea name="proposal"
                                                placeholder='Enter reason'
                                                rows={4}
                                                className="form-control Source_Regular fs_14 text_gray outline-none border_gray"
                                                value={reason}
                                                onChange={(event) => setReason(event.target.value)} />
                                            {reason?.trim()?.length <= 20 && <p className='fs_13 mb-2 mt-0 pt-0 text-danger'>Please enter minimum 20 characters!</p>}
                                        </>
                                        : null}
                                    {6 == item.value ?
                                        <>
                                            <textarea name="proposal"
                                                placeholder='Enter reason'
                                                rows={4}
                                                className="form-control Source_Regular fs_14 text_gray outline-none border_gray"
                                                value={reason}
                                                onChange={(event) => setReason(event.target.value)} />
                                            {reason?.trim()?.length <= 20 && <p className='fs_13 mb-2 mt-0 pt-0 text-danger'>Please enter minimum 20 characters!</p>}
                                        </>
                                        : null}

                                </>
                            )
                        })}
                    </Space>
                </Radio.Group>

            </Modal>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </>
    )
}

const ActionModal = ({ isVisible, onClose, data, reload, type, callback }) => {
    const navigate = useNavigate();
    const [selectedKey, setSelectedKey] = useState([]);
    const [open3, setOpen3] = useState(false);


    const deleteBro = async () => {


        setSelectedKey([])
        const enc1 = enc({ id: data._id }, key)

        if (type == 'review') {
            CommonAPI(enc1, 'GET', `deleteReview?id=${data._id}`, responseBack2)
        } else {
            CommonAPI(enc1, 'POST', 'queryDelete', responseBack)
        }
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            reload()
            tstSuccess('Travel Post Deleted Successfully')
        } else {

        }
    })


    const responseBack2 = useCallback(res => {
        if (res.status === true) {
            reload(data._id, 'delte')
            callback(false)
            tstSuccess('Review Deleted Successfully')
        } else {
        }
    })

    const handleMenuClick = (e) => {
        // e.stopPropagation()
        const key = e.key;
        switch (key) {
            case '1':
                if (type == 'review') {
                    setOpen3(true)
                } else {
                    navigate('/post-travel', { state: data })
                }
                break;
            case '2':
                deleteBro();
                break;
            default:
                break;
        }
    };

    const handleClick = (event) => {
        event.stopPropagation();
        // tstSuccess('work')
    };

    return (
        <div onClick={handleClick}>
            <Dropdown
                menu={{
                    items: items1,
                    selectable: true,
                    onSelect: handleMenuClick,
                    style: {
                        right: -10,
                        fontSize: 14
                    },
                    selectedKeys: selectedKey,
                }}
                placement="bottomRight"
                // visible={isVisible}
                arrow={{
                    pointAtCenter: true,

                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space className='pointer bg-warning1'>
                        <i className="fa fa-ellipsis-v text_gray fs_14 ms-3"></i>
                    </Space>
                </a>
            </Dropdown>

            <FeedbackModal isVisible={open3} onClose={setOpen3} type={data?.type} edit={data || []} id={data && data?.detailsId} callback={callback} reload={reload} />
        </div>
    )
}

// error success
const ModalTwo = (params) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [modalType, setModalType] = useState('');

    // const openModal = (type) => {
    //     setIsModalOpen(true);
    //     setModalType(type);
    // };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    //     setModalType('');
    // };


    return (
        params.isModalOpen && (
            <div className={`modal_wrapper ${params.isModalOpen ? 'active' : ''}`}>
                <div className="shadow" onClick={params.closeModal}></div>

                <div className="modal">
                    {params.type === 'success' && (
                        <div className="modal_item s_modal active">
                            <div className="modal_body">
                                <div className="s_icon">
                                    <ion-icon name="checkmark"></ion-icon>
                                </div>
                                <div className="s_text">
                                    <h2>SUCCESS</h2>
                                    <p>We are delighted to inform you that we received your payment.</p>
                                </div>
                            </div>
                            <div className="s_button">
                                <button className="success_btn button_modal" onClick={params.closeModal}>Continue</button>
                            </div>
                        </div>
                    )}

                    {params.type === 'error' && (
                        <div className="modal_item e_modal active">

                            <div className="modal_body">
                                <div className="s_icon">
                                    <i className="fa fa-question" aria-hidden="true"></i>
                                </div>
                                <div className="s_text">
                                    <h2>Failed</h2>
                                    <p>Unfortunately, we have an issue with your payment. Try again later.</p>
                                </div>
                            </div>
                            <div className="s_button">
                                <button className="error_btn button_modal" onClick={params.closeModal}>Continue</button>
                            </div>
                        </div>
                    )}
                    <div className="close" onClick={params.closeModal}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        )
    );
};


const FeedbackModal = ({ isVisible, onClose, id, type, callback, reload, edit, vendorId }) => {
    const [selectedEmoji, setSelectedEmoji] = useState(edit?.rating || '');
    const [feedback, setFeedback] = useState(edit?.message || '');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [err, setErr] = useState({})

    useEffect(() => {
        if (edit !== undefined) {
            setSelectedEmoji(edit?.rating || '')
            setFeedback(edit?.message || '')
        }
    }, [edit, vendorId])


    const val = () => {
        const err = {};

        if (!selectedEmoji) {
            err.rat = 'Select rating!'
        }

        if (feedback.trim().length >= 20) {
            err.msg = 'Please enter minimum 20 characters!'
        }

        setErr(err)
        return Object.keys(err).length == 0;
    }

    const handleSubmit = async () => {
        const data = {
            message: feedback,
            type: type,
            typeId: id,
            rating: selectedEmoji,
            id: edit?.detailsId ? edit?._id : '',
            vendorId: edit == undefined ? vendorId : edit?.vendorId
        }

        const enc1 = enc(data, key)

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'createReview', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {

        if (res.status === true) {
            tstSuccess('Review Submitted successfully!')
            setFeedback('')
            setSelectedEmoji('')
            callback(true)


            if (edit !== undefined) {
                reload(res.data, 'update')
            } else {
                reload(res.data, 'add')
            }
            onClose(false)

        } else {

            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
            if (res.message == 'Already Exist!!') {
                setErr({ review: 'You are already submitted!!' })
            }
        }
        setIsLoading(false)
    })


    const emojis = ['/icons/emoji/sad.png', '/icons/emoji/confused.png', '/icons/emoji/meh-blank.png', '/icons/emoji/smiley.png', '/icons/emoji/star.png'];

    const handleOk = (ty) => {
        setTimeout(() => {
            setIsLoading(false)
            onClose(false);
        }, 1000);
    };

    return (
        <>
            <Modal
                title={<span style={{ color: '#666' }}>Customer Feedback</span>}
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                footer={[
                    <Button key="back" onClick={() => onClose(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" disabled={isLoading} onClick={handleSubmit}>
                        {isLoading ? <Spinner size='sm' /> : 'Submit'}
                    </Button>,
                ]}
                open={isVisible}
                onOk={handleOk}
                onCancel={() => {
                    onClose(false);
                    setIsLoading(false);
                }}
            >
                <div className='divider h_05px mt-1 mb-2'></div>

                <div className='Rubik-Medium text_gray fs_14'>We would love to hear how your experience was with hotel</div>
                <p className='Rubik-Regular text_gray fs_13 mb-2 mt-3 m-0'>How was your experience?</p>
                <Row justify="center" gutter={16}>
                    {emojis.map((emoji, index) => (
                        <Col key={index}>
                            <Button
                                onClick={() => setSelectedEmoji(index + 1)}
                                size="large"
                                shape="circle"
                                className='d-flex align-items-center justify-content-center'
                                style={{
                                    backgroundColor: selectedEmoji === index + 1 ? '#fff3e0' : '#fff',
                                    borderColor: selectedEmoji === index + 1 ? '#ffc571' : '#d9d9d9',
                                }}
                            >
                                <img src={emoji} className='hw_25px' alt='Feedback Emojis' />
                            </Button>
                        </Col>
                    ))}
                </Row>
                <p className='Rubik-Regular text_gray fs_13 mb-1 mt-4 m-0'>Enter your feedback?</p>
                <Input.TextArea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    maxLength={500}
                    rows={4}
                    className='form-control fs_14 h_38px rd_10 border_gray Rubik-Regular text_dark_medium outline-none no-outline'
                    placeholder="Your feedback here..."
                />
                <p className='Rubik-Regular text_gray2 fs_11 mt-1 m-0'>{500 - feedback.length} characters left</p>

                {err.rat && <p className='fs_13 mb-2 pt-0 mt-2 text-danger'>{err.rat}</p>}
                {!err.rat && err.msg && <p className='fs_13 mb-2 pt-0 mt-2 text-danger'>{err.msg}</p>}
                {err.review && <p className='fs_13 mb-2 pt-0 mt-2 text-danger'>{err.review}</p>}
            </Modal>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </>
    );
};


const ShareModal = ({ url, isVisible, onClose }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const copyLinkToClipboard = async () => {
        const input = document.createElement('input');
        input.value = url;
        document.body.appendChild(input);

        input.select();
        document.execCommand('copy');

        document.body.removeChild(input);

        setCopySuccess('Link copied successfully!');
        setTimeout(() => setCopySuccess(''), 2000);
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            onClose(false);
        }, 1000);
    };

    return (
        <Modal
            title={<span style={{ color: '#666' }}>Share Using</span>}
            styles={{
                header: {
                    padding: '15px 0px 0 15px',
                },
                body: {
                    padding: '10px 20px', height: 'auto'
                }
            }}
            footer={null}
            open={isVisible}
            onOk={handleOk}
            onCancel={() => {
                onClose(false);
            }}
        >
            <>
                <div className='bg-warning1 d-flex justify-content-between align-items-center flex-wrap'>
                    <a href={`https://wa.me/?text=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" width="40" />
                    </a>
                    <a href={`https://telegram.me/share/url?url=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4'>
                        <img src="https://th.bing.com/th/id/OIP.5qPAKGvRR4-4MNQOV9td3wAAAA?rs=1&pid=ImgDetMain" alt="Telegram" width="35" />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" width="31" />
                    </a>
                    <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=&su=Check this out!&body=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4'>
                        <img src="https://static.vecteezy.com/system/resources/previews/025/267/307/large_2x/google-toolbar-icon-search-symbol-map-gmail-calendar-drive-photo-google-play-meet-chat-and-my-ad-center-free-png.png" alt="Gmail" width="30" />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4 rd_50'>
                        <img src="https://th.bing.com/th/id/OIP.if2ieS05NYxAYyY1alBpFwHaHa?w=180&h=180&c=7&r=0&o=5&dpr=1.4&pid=1.7" alt="Instagram" width="33" className='rd_50' />
                    </a>
                    <a href={`https://twitter.com/share?url=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4 rd_6'>
                        <img src="https://th.bing.com/th/id/OIP.eBo4UfoCOevLR1Rc8e7tcgHaHa?w=179&h=180&c=7&r=0&o=5&dpr=1.4&pid=1.7" alt="Twitter" width="33" className='rd_6' />
                    </a>
                    <a href={`https://join.snapchat.com/share?text=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" className='mx-3 mb-4'>
                        <img src="https://th.bing.com/th?q=Snapchat+User+Icon&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.4&pid=InlineBlock&mkt=en-US&cc=US&setlang=en&adlt=moderate&t=1&mw=247" alt="Snapchat" width="34" />
                    </a>
                    <div className='flex-grow-1'></div>
                </div>

                <div className='p-2 d-flex align-items-center justify-content-center bg_lightgray rd_10 px-3 pointer' onClick={copyLinkToClipboard}>
                    <p className='mini-line-1 m-0 mr_10 text_gray2 flex-grow-1'>{url}</p>
                    <i className="fa-regular fa-copy fa-fade bg-white  d-flex align-items-center justify-content-center rd_50 fs_15 p-2"></i>
                </div>
                {copySuccess && <p className='text-success mt-2 fs_12 ms-1 mb-0'>{copySuccess}</p>}
            </>
        </Modal>
    );
};

const HowItWork = ({ isVisible, onClose }) => {
    const videoUrl = "https://www.youtube.com/embed/Ntz602DxHOk";

    const iframeKey = isVisible ? "video" : "video-hidden";

    return (
        <Modal
            className='w-100a'
            title={<span style={{ color: '#666' }}>How It Works</span>}
            styles={{
                body: {
                    padding: '0px 5px',
                    height: 'auto'
                },
                header: {
                    padding: '15px 0px 0 15px',
                },
            }}
            footer={null}
            open={isVisible}
            onCancel={() => {
                onClose(false);
            }}
            onClose={() => onClose(false)}
        >
            <div className="modalOverlay" style={{ width: '100%', height: '100%' }}>
                <div className="modalContent" style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '100%' }}>
                        <div style={{ width: '100%', height: '100%' }}>
                            <iframe
                                key={iframeKey} // Change key to force remount
                                frameBorder="0"
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                title="Winter Spiti - An Unforgettable Journey | Traveler's Review | Go4Explore Community"
                                width="100%"
                                height="100%"
                                src={isVisible ? videoUrl : ""}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}


const MsgNoti = () => {
    const { notificationVisible, notificationData } = useNotification();

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                }
            });
        }
    }, []);


    if (notificationVisible) {

        const notification = new Notification(notificationData.name, {
            body: `${notificationData.msg}`,
            icon: notificationData?.image ? img_base_url + notificationData?.image : '/icon.png',
        });

        // Optional: Handle the notification click
        notification.onclick = () => {
        };

        setTimeout(() => {
            notification.close();
        }, 5000);
    }

    if (notificationVisible) {
        return (
            <div className={`msg-noti mt-2 me-2 rd_6 shadow-slowclean bg-white d_flex_align_justify_center px-2-5 h_75px pot-0 pr-0 z-5 ${notificationVisible ? 'msg-noti-active' : 'msg-noti-hidden'}`}>
                <>
                    <img src='/icons/review_user.png' alt="Notification" className='rd_6 hw_35px mr_5' />
                    <div className='ms-2'>
                        <div className='NunitoSans-Bold fs_14 text-dark'>{notificationData.name}</div>
                        <div className='NunitoSans-Regular fs_11 text-muted'>{notificationData.msg ? notificationData.msg : 'New message received!'}</div>
                    </div>
                </>
            </div>
        )
    }
}

const NavDropdown = ({ isVisible, onClose, className, fontFamily }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedKey, setSelectedKey] = useState([]);
    const words = ["Packages", "Hotels", "Vehicles"];
    const [text, setText] = useState('');
    const [currentWord, setCurrentWord] = useState(words[0]);
    const [isTyping, setIsTyping] = useState(true);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        switch (location.pathname) {
            case '/packages':
                setSelectedKey(['1']);
                break;
            case '/hotels':
                setSelectedKey(['2']);
                break;
            case '/vehicle-search':
                setSelectedKey(['3']);
                break;
            default:
                setSelectedKey([]);
                break;
        }
    }, [location.pathname]);


    const handleMenuClick = (e) => {
        const key = e.key;
        switch (key) {
            case '1':
                navigate('/packages');
                break;
            case '2':
                navigate('/hotels');
                break;
            case '3':
                navigate('/vehicle-search');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let typingInterval;
        let eraseInterval;

        if (isTyping) {
            // Typing effect
            if (text.length < currentWord.length) {
                typingInterval = setInterval(() => {
                    setText((prevText) => prevText + currentWord.charAt(text.length));
                }, 150);
            } else {
                // Wait a bit before starting to erase
                setTimeout(() => {
                    setIsTyping(false); // Start erasing after 2 seconds
                }, 2000);
            }
        } else {
            // Erasing effect
            if (text.length > 0) {
                eraseInterval = setInterval(() => {
                    setText((prevText) => prevText.slice(0, -1));
                }, 100);
            } else {
                // After erasing the word, move to the next word
                setIsTyping(true);
                setIndex((prevIndex) => (prevIndex + 1) % words.length);
            }
        }

        // Cleanup intervals when component unmounts or when the word changes
        return () => {
            clearInterval(typingInterval);
            clearInterval(eraseInterval);
        };
    }, [text, currentWord, isTyping]);

    useEffect(() => {
        setCurrentWord(words[index]);
        setText(""); // Reset text when changing the word
    }, [index]);


    const items = [
        {
            key: '1',
            label: 'Packages',
        },
        {
            key: '2',
            label: `Hotels`,
        },
        {
            key: '3',
            label: 'Vehicles',
        }
    ];

    return (
        <Dropdown
            menu={{
                items,
                selectable: true,
                onSelect: handleMenuClick,
                style: {
                    fontSize: 14,
                    // marginRight: -20
                },
                selectedKeys: selectedKey,
                className: 'custom-dropdown flex-column d-flex align-items-center justify-content-around'
            }}
            open={isVisible}
            onOpenChange={onClose}
            placement="bottomLeft"
            arrow={{
                pointAtCenter: true,
            }}
        >
            <Typography.Link className='bg-primary1'>
                <Space className={`m-0 ${className}`}>
                    <p className='m-0 NunitoSans-Bold fs_15 text-gr '>{text}</p>
                    {/* <i className={`fa-solid fa-chevron-down ${className}`}></i> */}
                </Space>
            </Typography.Link>
        </Dropdown>
    )
}

const TagsSection = ({ tags, categories, cName, hideIs }) => {
    const [showTags, setShowTags] = useState(100);
    const [showTags1, setShowTags1] = useState(100);
    const [showTags2, setShowTags2] = useState(100);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setShowTags(10);
            setShowTags1(10);
            setShowTags2(10);
        } else{
            setShowTags(tags?.length);
            setShowTags1(tags?.length);
            setShowTags2(tags?.length);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tags.length]);


    return (
        <div className='w-100 mt-1 d-flex align-items-center justify-content-center'>
            <div className='w_95 mt-4'>
                {categories && categories?.length > 0 &&
                    <div className='bg-white rd_6 p-3  mb-3'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Categories</h2>

                        {categories ? (
                            <div className='d-flex flex-wrap gap-2'>
                                {categories?.map((it, index) => {
                                    const slug = it.name.toLowerCase().replace(/ /g, '-');

                                    return (
                                        <Link to={`/packages/${slug}`} target='_blank' rel="noopener noreferrer" key={it._id} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                            {it.name} Packages
                                        </Link>
                                    )
                                })}

                            </div>
                        ) : (
                            <Spin className='ms-2 mb-2 thm-spin' />
                        )}
                    </div>
                }


                {(tags && tags?.locations?.length > 0 && hideIs !== 'location') &&
                    <div className='bg-white rd_6 p-3  mb-3'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Locations in {cName}</h2>

                        {tags ? (
                            <div className='d-flex flex-wrap gap-2'>
                                {tags?.locations?.slice(0, showTags).map((it, index) => {

                                    return (
                                        <Link to={`/trip/${it.slug}/${it.id}`} target='_blank' rel="noopener noreferrer" key={it._id} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                            Top places to visit in {it.name}
                                        </Link>
                                    )
                                })}
                                {(showTags == 10 || showTags == 120) && <div onClick={() => setShowTags(showTags == 10 ? 120 : 10)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                    {showTags == 10 ? 'See More' : 'See Less'}
                                    <i className={`fa-solid fa-angles-${showTags == 10 ? 'down' : 'up'} fa-fade ml-5px mt-2px`}></i>
                                </div>}
                            </div>
                        ) : (
                            <Spin className='ms-2 mb-2 thm-spin' />
                        )}
                    </div>
                }


                {tags && tags?.states?.length > 0 &&
                    <div className='bg-white rd_6 p-3 mb-3'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States in {cName}</h2>

                        {tags ? (
                            <div className='d-flex flex-wrap gap-2'>
                                {tags && tags?.states?.slice(0, showTags1).map((it, index) => {
                                    return (
                                        <Link to={`/state/${it.slug}/${it.id}`} target='_blank' rel="noopener noreferrer" key={it._id} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                            Top places to visit in {it.name}
                                        </Link>
                                    )
                                })}
                                {(showTags1 == 10 || showTags1 == 120) && <div onClick={() => setShowTags1(showTags1 == 10 ? 120 : 10)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                    {showTags1 == 10 ? 'See More' : 'See Less'}
                                    <i className={`fa-solid fa-angles-${showTags1 == 10 ? 'down' : 'up'} fa-fade ml-5px mt-2px`}></i>
                                </div>}
                            </div>
                        ) : (
                            <Spin className='ms-2 mb-2 thm-spin' />
                        )}
                    </div>
                }

                <div className='bg-white rd_6 p-3 mb-5'>
                    <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>
                    {tags ? (
                        <div className='d-flex flex-wrap gap-2'>
                            {tags && tags?.countries?.slice(0, showTags2).map((it, index) => {

                                return (
                                    <Link key={it._id} to={`/country/${it.slug}/${it.id}`} target='_blank' rel="noopener noreferrer" className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                        Top places to visit in {it.name}
                                    </Link>
                                )
                            })}
                            {(showTags2 == 10 || showTags2 == 120) && <div onClick={() => setShowTags2(showTags2 == 10 ? 120 : 10)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                {showTags2 == 10 ? 'See More' : 'See Less'}
                                <i className={`fa-solid fa-angles-${showTags2 == 10 ? 'down' : 'up'} fa-fade ml-5px pt-5px`}></i>
                            </div>}
                        </div>
                    ) : (
                        <Spin className='ms-2 mb-2 thm-spin' />
                    )}
                </div>
            </div>
        </div>
    )
}


const GridCategories = ({ handleChange, tripCate }) => {
    const [itemsToShow, setItemsToShow] = useState(6);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setItemsToShow(6);
        } else {
            setItemsToShow(tripCate.length);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tripCate.length]);


    return (
        <div className='d-flex align-items-center justify-center flex-column bg-success1 mt-3'>
            <div className="row scrollable-container w-80p w-95p--xs-g m-0 d-flex justify-content-center align-items-start">
                {tripCate && tripCate.slice(0, itemsToShow).map((item, index) => {
                    return (
                        <div key={index} className="xs-1-5 sm-1-5 md-1-5 lg-1-2 xl-1-5 mb-4" onClick={() => handleChange(item._id, 'categoryId')}>
                            <div className="bg-success1 border_gray1 border_gray1 d-flex flex-column align-items-center justify-content-center pointer">
                                <div className="w-100 rd_50 bg-white hw_80px hw_70px d-flex align-items-center justify-content-center shadow-slowclean">
                                    <img
                                        src={`${img_base_url + item.icon}`}
                                        className="h-60p w-60p rd_6"
                                        alt={item.name}
                                    />
                                </div>
                                <p className="m-0 mt-2 fs_14 Source_Medium text-muted text-center">
                                    {item.name}
                                </p>
                            </div>
                        </div>
                    );
                })}

            </div>
            {(itemsToShow == 6 || itemsToShow == 20) && <div onClick={() => setItemsToShow(itemsToShow == 6 ? 20 : 6)} className='pointer px-2 rd_6 Source_Regular fa-fade text_thm fs_12 p-1 pt-5px'>
                {itemsToShow == 6 ? 'See More' : 'See Less'}
                <i className={`fa-solid fa-angles-${itemsToShow == 6 ? 'down' : 'up'} ml-5px`}></i>
            </div>}
        </div>
    )
}


const ModalWrapper = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(true);

    const handleCloseModal = () => {
        navigate('/')
        setModalVisible(false);
    };

    return (
        <SignInModal isOpen={modalVisible} onClose={handleCloseModal} />
    );
};

export { MenuModal, TagsSection, GridCategories, ModalWrapper, ShareModal, ParagraphModal, NavDropdown, OpenImageModal, FormVehicleModal, ReportSection, ActionModal, ModalTwo, FeedbackModal, HowItWork, MsgNoti };
