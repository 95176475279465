import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import PkgIndex from "../Pages/Packages/PkgIndex";
import Nav from "./Nav";
import ScrollToTop from "../Componets/ScrollToTop";
import QueryDetails from "../Pages/Dashboard/Query/QueryDetails";
import AddQuery from "../Pages/Dashboard/Query/AddQuery";
import Queries from "../Pages/Dashboard/Query/Queries";
import Footer from "../Componets/Footer";
import Profile from "../Pages/Menu/Profile";
import Settings from "../Pages/Menu/Settings";
import MyQueries from "../Pages/Menu/MyQueries";
import PkgDetails from "../Pages/Packages/PkgDetails";
import HotelIndex from "../Pages/Hotels/HotelIndex";
import HotelDetails from "../Pages/Hotels/HotelDetails";
import Guider from "../Pages/Guider/Guider";
// import Vehicleindex from "../Pages/Vehicle/Vehicleindex";
import MyBookings from "../Pages/Menu/MyBookings";
import VehicleList from "../Pages/Vehicle/VehicleList";
import GuiderDetails from "../Pages/Guider/GuiderDetails";
import PrivacyPolicy from "../Pages/FooterLinks/PrivacyPolicy";
import TC from "../Pages/FooterLinks/TC";
import RefundCancel from "../Pages/FooterLinks/RefundCancel";
import Blogs from "../Pages/FooterLinks/Blogs/Blogs";
import BlogDetails from "../Pages/FooterLinks/Blogs/BlogDetails";
import About from "../Pages/FooterLinks/About";
import STicket from "../Pages/Ticket/STicket";
import TicketDetails from "../Pages/Ticket/TicketDetails";
import PkgList from "../Pages/Packages/PkgList";
import VehicleDetails from "../Pages/Vehicle/VehicleDetails";
import React, { useState } from "react";
import ContactForm from "../Pages/FooterLinks/ContactForm";
import TripsAbout from "../Pages/Packages/TripsAbout";
import NotFound from "../Componets/NotFound";
import VendorDetails from "../Pages/Dashboard/VendorDetails";
import ProposalDetails from "../Pages/Dashboard/Query/ProposalDetails";
import Home from "../Pages/Dashboard/Home";
import ComingSoon from "../Componets/ComingSoon";
import SignInModal from "../Pages/Auth/SignInModal";
import MessagingPopup from "../Pages/Dashboard/MessagingPopup";
import { ModalWrapper, MsgNoti } from "../Componets/Modal";
import PkgByCategory from "../Pages/Packages/PkgByCategory";
import BottomNavigation from "./BottomNavigation";
import PkgListByCategory from "../Pages/Packages/PkgListByCategory";


// export const RouterParent = createBrowserRouter([
//     {
//         path: '/',
//         element: (<><ScrollToTop /><MsgNoti /><Nav /><Home /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/vendor/:name/:id',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><VendorDetails /><Footer /><MessagingPopup type={'common'} /></>)
//     },

//     // Pkg
//     {
//         path: '/packages',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><PkgIndex /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/packages/list',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><PkgList /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/packages/:category',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><PkgList /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/package/:slug/:id',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><PkgDetails /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/:parent/:slug/:id',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><TripsAbout /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/:parent/:type_pkg/:slug/:id',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><PkgByCategory /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     // Hotels
//     {
//         path: '/hotels',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><HotelIndex /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/hotel/:slug/:id',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><HotelDetails /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     // Query
//     {
//         path: '/post-travel',
//         element:
//             <>
//                 <ScrollToTop />
//                 <Nav /><MsgNoti />
//                 <AddQuery />
//                 <Footer />
//                 <MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/travel-lead/details',
//         element: (<><ScrollToTop /><Nav type="queryDetails" /><MsgNoti /><QueryDetails /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/travel-leads',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><Queries /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/proposal/details',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><ProposalDetails /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     // Guider
//     {
//         path: '/guiders',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><Guider /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/guiders/details',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><GuiderDetails /><Footer /><MessagingPopup type={'common'} /></>
//     },

//     // Vehicle
//     {
//         path: '/vehicle-search',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><Vehicleindex /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/vehicles',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><VehicleList /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     {
//         path: '/vehicle/details/:id',
//         element: <><ScrollToTop /><Nav /><MsgNoti /><VehicleDetails /><Footer /><MessagingPopup type={'common'} /></>
//     },
//     // Prfoile menu
//     {
//         path: '/profile',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><Profile /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/settings',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><Settings /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/my-travels',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><MyQueries /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/my-proposals',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><MyQueries /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/mybookings',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><MyBookings /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/privacy-policy',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><PrivacyPolicy /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/terms-and-conditions',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><TC /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/refund-and-cancellation',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><RefundCancel /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/blogs',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><Blogs /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/blog/:slug/:id',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><BlogDetails /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/about-us',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><About /><Footer /><MessagingPopup type={'common'} /></>)
//     },

//     {
//         path: '/user/tickets',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><STicket /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/user/ticket-details',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><TicketDetails /><Footer /><MessagingPopup type={'common'} /></>)
//     },

//     // invoice

//     // {
//     //     path: '/payment-receipt',
//     //     element: (<><ScrollToTop /><Nav /><MsgNoti /><InvoiceP /><Footer /><MessagingPopup type={'common'} /></>)
//     // },
//     {
//         path: '/contact-us',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><ContactForm /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/not-found',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><NotFound /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '*',
//         element: (
//             <>
//                 <ScrollToTop />
//                 <Nav /><MsgNoti />
//                 <NotFound />
//                 <Footer />
//                 <MessagingPopup type={'common'} /></>
//         ),
//     },
//     {
//         path: '/coming-soon',
//         element: (<><ScrollToTop /><Nav /><MsgNoti /><ComingSoon /><Footer /><MessagingPopup type={'common'} /></>)
//     },
//     {
//         path: '/signin',
//         element: (<ModalWrapper />)
//     },


// ])

const RouterIndex = () => {
    return (
      <>
        <ScrollToTop />
        <MsgNoti />
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<><Nav /><Home /><Footer /><MessagingPopup type="common" /></>} />
          
          {/* Vendor Routes */}
          <Route path="/profile/:name/:id" element={<><Nav /><VendorDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Package Routes */}
          <Route path="/packages" element={<><Nav /><PkgIndex /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/packages/list" element={<><Nav /><PkgList /><Footer /><MessagingPopup type="common" /></>} />
          {/* <Route path="/packages/:category" element={<><Nav /><PkgListByCategory /><Footer /><MessagingPopup type="common" /></>} /> */}
          <Route path="/packages/:category" element={<><Nav /><PkgList /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/package/:slug/:id" element={<><Nav /><PkgDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Trips Routes */}
          <Route path="/:parent/:slug/:id" element={<><Nav /><TripsAbout /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/:parent/:type_pkg/:slug/:id" element={<><Nav /><PkgByCategory /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Hotel Routes */}
          <Route path="/hotels" element={<><Nav /><HotelIndex /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/hotel/:slug/:id" element={<><Nav /><HotelDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Query Routes */}
          <Route path="/post-travel" element={<><Nav /><AddQuery /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/travel-lead/details/:id" element={<><Nav /><QueryDetails /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/travel-leads" element={<><Nav /><Queries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/proposal/details" element={<><Nav /><ProposalDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Guider Routes */}
          <Route path="/guiders" element={<><Nav /><Guider /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/guiders/details" element={<><Nav /><GuiderDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Vehicle Routes */}
          {/* <Route path="/vehicle-search" element={<><Nav /><Vehicleindex /><Footer /><MessagingPopup type="common" /></>} /> */}
          <Route path="/vehicles" element={<><Nav /><VehicleList /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/vehicle/details/:id" element={<><Nav /><VehicleDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Profile Routes */}
          <Route path="/profile" element={<><Nav /><Profile /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/settings" element={<><Nav /><Settings /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/my-travels" element={<><Nav /><MyQueries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/my-proposals" element={<><Nav /><MyQueries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/mybookings" element={<><Nav /><MyBookings /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Legal Routes */}
          <Route path="/privacy-policy" element={<><Nav /><PrivacyPolicy /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/terms-and-conditions" element={<><Nav /><TC /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/refund-and-cancellation" element={<><Nav /><RefundCancel /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Blog Routes */}
          <Route path="/blogs" element={<><Nav /><Blogs /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/blog/:slug/:id" element={<><Nav /><BlogDetails /><Footer /><MessagingPopup type="common" /></>} />
          
          {/* About Us Route */}
          <Route path="/about-us" element={<><Nav /><About /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* User Routes */}
          <Route path="/user/tickets" element={<><Nav /><STicket /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/user/ticket-details" element={<><Nav /><TicketDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Contact Us Route */}
          <Route path="/contact-us" element={<><Nav /><ContactForm /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* 404 Not Found Route */}
          <Route path="/not-found" element={<><Nav /><NotFound /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="*" element={<><Nav /><NotFound /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Coming Soon Route */}
          <Route path="/coming-soon" element={<><Nav /><ComingSoon /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Sign In Route */}
          <Route path="/signin" element={<ModalWrapper />} />
        </Routes>

        <BottomNavigation /> 
      </>
    );
  };
  
  export default RouterIndex;

