import React, { useCallback, useEffect, useState } from 'react'
import useTruncation, { HTMLRender } from '../../Componets/HTMLRender';
import { Col, Row, Spinner } from 'react-bootstrap';
import { dec } from '../../Componets/SecRes';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { openInNewTab } from '../../Componets/CommonFs';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { CommonAPI } from '../../Componets/CommonAPI';
import Carousel from 'react-multi-carousel';
import { Spin } from 'antd';
import SignInModal from '../Auth/SignInModal';
import { Loader } from '../../Componets/Loader';
import { Helmet } from 'react-helmet';
import { TagsSection } from '../../Componets/Modal';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;


const responsive_8 = {
  desktop1: {
    breakpoint: { max: 2000, min: 1200 },
    items: 4,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 1200, min: 868 },
    items: 3,
    slidesToSlide: 3
  },
  xtablet: {
    breakpoint: { max: 868, min: 567 },
    items: 2,
    slidesToSlide: 2
  },
  mobilex: {
    breakpoint: { max: 567, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const responsive = {
  desktop1: {
    breakpoint: { max: 2000, min: 1150 },
    items: 5,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 1150, min: 828 },
    items: 4,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 828, min: 560 },
    items: 3,
    slidesToSlide: 2
  },
  mobilex: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

function TripsAbout() {
  const { parent, slug, id } = useParams();

  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    getDetails();
  }, [slug]);

  const types = ['country', 'trip', 'state']

  const getDetails = (tagId) => {

    if (!types.includes(parent)) {
      navigate('/not-found', { replace: true });
      return
    }

    setIsLoading1(true)
    CommonAPI({}, 'GET', `locationDetails?id=${id}&type=${parent}`, responseBack)
    CommonAPI({}, 'GET', `topTagsList?type=${parent}&typeId=${id || ''}`, responseBack2)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {

      const dec1 = dec(res?.data, key);
      setData(dec1)

      if (dec1?.data?.slug !== slug) {
        navigate(`/${parent}/${dec1?.data?.slug}/${id}`)
        setIsLoading1(false)
        return
      }

    } else {
      navigate('/not-found', { replace: true });
    }
    setIsLoading1(false)
  })

  const responseBack2 = useCallback(res => {

    if (res.status === true) {
      const dec1 = dec(res?.data, key)

      setTagsList(dec1)
    } else {

    }
  })

  const details = data?.data;

  const goToPkgList = (type) => {
    const pkgHome = localStorage.getItem('pkgHome');
    const pkgHomeParams = JSON.parse(pkgHome)

    const newState = {
      ...pkgHomeParams,
      location: parent == 'trip' ? id : '',
      month: []
    }

    if (newState?.cID) {
      openInNewTab('/packages/list', newState)
    }
  }



  const { contentRef, isTruncated } = useTruncation(details?.about);

  const handleLinkClick = (slug1) => {
    window.scrollTo(0, 0);

    if (slug !== slug1) {
      setData([]);
      setTagsList([]);
    }
  };

  const currency = data?.currency


  return (
    <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 bg-gray' style={{ backgroundColor: '#eef4fd' }}>
      {!isLoading1 && details?.image ? (
        <>
          <div className='w_95 mt-4 mb-3 bg-success1'>
            <div className="position-relative rd_10 w-100 h_520px h-250px-sm" style={{ backgroundImage: `url(${img_base_url + details?.image?.replace(/\s+/g, '%20')})`, backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
              <div className='rd_10 hoverthis d-flex justify-content-center align-items-center flex-column' style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                borderRadius: 10,
                background: 'linear-gradient(to top, rgba(0,0,0,0.10), rgba(0,0,0,0.10)) ',
              }}>

                <div className={`px-3 py-15px w-50p rd-10px scrollable-h scrollable flex-column text-white d-flex align-items-center justify-content-center mt-4 ${details?.[0]?.facilities?.length >= 5}`} style={{ background: 'linear-gradient(to top, rgba(0,0,0,0.30), rgba(0,0,0,0.30)) ' }}>
                  <span className='Inter-Bold text-center fs_26 text-white text-capitalize px-1 text-shadow'>{details?.name}</span>

                  <div className='mt-20px'>
                    <span className='Rubik-Light fs_13 mr-10px'>Country: {currency?.country_name}</span>

                    <span className='Rubik-Light fs_13'>Currency: {currency?.symbol} ({currency?.currencyName})</span>
                  </div>
                </div>
              </div>
            </div>


            <Row className='mt-4 w-100 bg-warning1 m-0'>
              <Col md={8} lg={9} className='mb-md-3'>
                <div className='bg-white rd_6 p-3 shadow-slowclean'>
                  <div className='d-flex justify-between align-center'>
                    <div className='Rubik-SemiBold'>About {details?.name}</div>

                    <Link className='Rubik-Regular bg-gradient-dark text-white fs-13px h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center text-capitalize pointer m-0 text-decoration-none' target='_blank' rel='noopener noreferrer' to={`/${parent}/${slug}/${id}`}>
                      <i class="fas fa-location-arrow  me-2 fs_13"></i> View This {parent == 'trip' ? 'location' : parent} Packages
                    </Link>
                  </div>
                  <div className='divider h_05px mt-2 mb-3'></div>
                  <div className={`content-wrapper Source_Regular ${isExpanded ? '' : 'mini-line-1 twentyfive'}`} ref={contentRef}>
                    <HTMLRender htmlContent={details?.about || ''} />
                  </div>
                  {isTruncated && (
                    <div className='text_thmdark pointer fs_12 Inter-Medium mt-1 text-end w-100' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? ' See Less' : ' See More'}</div>
                  )}
                </div>
              </Col>

              <Col className=''>
                <div className='rd_6 d-flex align-items-center flex-column bg-white p-3 px-4 shadow-slowclean'>
                  <img src='/images/man_with_lauggage.png' alt='Traveller Boy' className='img-fluid h_130px w_200px rotate-img-flip' />
                  <p className='m-0 fw-bold fs_16 mt-4'>Tired of Searching?</p>
                  <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Post your travel upcoming travel details on travellinglark.com and get offer quotations from multiple travel agencies. Choose an offer that meets your budget and requirements, save money and enjoy your travel. Post now!</p>

                  <Link to={'/post-travel'}>
                    <button className='bg-gradient-thm btn w-auto mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium px-4'>
                      Post Your Travel
                    </button>
                  </Link>

                </div>
              </Col>
            </Row>

            {tagsList && tagsList?.locations?.length > 0 &&
              <>
                <h2 className="mb-2 Rubik-SemiBold fs_17 mb-4 ms-2 mt-3">Popular Locations {currency?.country_name}</h2>

                <div className='w-100 mt-3'>
                  {isLoading1 ? (
                    <Loader type={'country'} />
                  ) : (
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      responsive={responsive}
                      ssr={true}
                      autoPlay
                      infinite
                      autoPlaySpeed={4500}
                      keyBoardControl={true}
                      customTransition="transform 1000ms ease-in-out"
                      containerClass="carousel-container"
                      itemClass="carousel-item1"
                      customLeftArrow={
                        <CustomLeftArrow
                          direction="left"
                          mt={'mt-30px'}
                        />
                      }
                      customRightArrow={
                        <CustomLeftArrow
                          direction="right"
                          mt={'mt-30px'}
                        />
                      }
                      dotListClass="custom-dot-list-style"
                    >
                      {tagsList?.locations?.map((item, index) => {

                        return (
                          <div key={item._id} className="mb-4 position-relative bg-white rd_30 mx-2-5 ">
                            <Link to={`/trip/packages/${item.slug}/${item.id}`} target='_blank' rel='noopener noreferrer' className='text-decoration-none w-100'>
                              <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                              <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                top: 0,
                                background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                              }}>
                                <div className='Inter-SemiBold fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                              </div>
                            </Link>
                          </div>
                        )
                      })}

                    </Carousel>
                  )}
                </div>
              </>
            }

            {data?.recommended?.length > 0 && (
              <>
                <div className='bg-warning1 d-flex align-items-center mt-4-5 me-2 justify-content-between'>
                  <div className="Rubik-SemiBold fs_17 ms-2">Popular Packages in {currency?.country_name}</div>
                  {data?.recommended.length > 0 && (
                    <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('cId')}>
                      <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                      <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                  )}
                </div>


                <Carousel
                  swipeable={true}
                  draggable={true}
                  responsive={responsive_8}
                  ssr={true}
                  autoPlay
                  autoPlaySpeed={4500}
                  infinite
                  keyBoardControl={true}
                  customTransition="transform 1000ms ease-in-out"
                  containerClass="carousel-container mt-2"
                  itemClass="carousel-item1 "
                  customLeftArrow={
                    <CustomLeftArrow
                      direction="left"
                    />
                  }
                  customRightArrow={
                    <CustomLeftArrow
                      direction="right"
                    />
                  }
                  removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                  dotListClass="custom-dot-list-style"
                >
                  {data?.recommended.length > 0 && data?.recommended?.map((item, index) => {
                    const lowPrice = item.discountPrice
                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice

                    return (
                      <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd_6 mx-2-5 pointer w-xxs-90' style={{ backgroundColor: '#fff' }}>
                        <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: parent == 'country' ? id : '' }} className='text-decoration-none hover-me'>
                          <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100 rd_4' />

                          <div className='p-2-5'>
                            <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                            <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId?.business_name}</span></p>

                            <div className='d-flex align-items-center justify-content-between mt-25px'>
                              <div>
                                <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                {lowPrice != null && lowPrice > 0 && (
                                  <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                )}
                              </div>
                              <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </Carousel>
              </>
            )
            }

          </div>


          <Helmet>
            <title>{details?.name} | Travellinglark</title>
            {details?.metaDescription && (
              <meta name="description" content={details?.metaDescription} />
            )}
            {details?.metaKeyword && (
              <meta name="keywords" content={details?.metaKeyword} />
            )}
          </Helmet>

          <TagsSection tags={tagsList} cName={currency?.country_name} hideIs={'location'} />

        </>
      ) : (
        <div className='d-flex align-items-center justify-content-center mb-4 vh-100 w-100' style={{ background: 'rgba(0,0,0,0.2)' }}>
          <Spin className='bg-white rd_10 hw_80px d-flex align-items-center justify-content-center thm-spin' />
        </div>
      )}

      {/* Modal Sigin */}
      <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
    </div>
  )
}

export default TripsAbout