import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { dateFormat, generateRandomText } from '../../../Componets/CommonFs';
import { tstError, tstSuccess } from '../../../Componets/HotToast';
import { MemContext } from '../../../ContextRedux/Context';
import { ReportSection, ShareModal } from '../../../Componets/Modal';
import { dec, enc } from '../../../Componets/SecRes';
import { CommonAPI } from '../../../Componets/CommonAPI';
import SignInModal from '../../Auth/SignInModal';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { io } from 'socket.io-client';
import { useNotification } from '../../../ContextRedux/NotificationContext';
import { svReceiverDetails } from '../../../ContextRedux/Action';
import { useChatPopup } from '../../../ContextRedux/ChatPopupContext';

const key = process.env.REACT_APP_KEY;
const socket_url = process.env.REACT_APP_SOCKET_URL;
const socket_local_url = process.env.REACT_APP_SOCKET_LOCAL;



function QueryDetails() {
  const { userInfo } = useContext(MemContext);
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const postId = location.state;

  console.log('id', id);



  const [proposal, setProposal] = useState('')
  const [active, setActive] = useState('dt')
  const [type, setType] = useState('')
  const [pId, setPId] = useState('')
  const [chatId, setChatId] = useState({})
  const [isExpanded, setIsExpanded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [err, setError] = useState({})
  const [proposalsList, setProposalsList] = useState([])
  const [details, setDetails] = useState([])
  const [editProposal, setEditProposal] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1)
  const [isLoading1, setIsLoading1] = useState(false);
  const textFieldFocus = useRef(null)
  const socketRef = useRef(null);
  const { showNotification } = useNotification();

  const userId1 = useSelector(reducer => reducer.data?.info?.user)
  const user_info = useSelector(reducer => reducer.data?.info)

  const userId = userInfo !== null && userInfo?._id
  const queryUserId = details && details?.data?.userDetails?._id
  const showDescription = userId == queryUserId
  const item = details && details?.data
  const user = item?.userDetails && item?.userDetails;
  const { isChatOpen, toggleChatPopup } = useChatPopup();
  const [renderUseEffect, setRenderUseEffect] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const url = window.location.href;


  // new msg
  useEffect(() => {
    if (user_info.tkn) {
      socketRef.current = io(socket_url, {
        query: {
          token: user_info.tkn
        }
      });

    }

  }, [renderUseEffect]);



  useEffect(() => {
    setProposal(editProposal[0]?.description || '')

  }, [editProposal])

  useEffect(() => {
    getDeatils();
    // proposalList();
  }, [location.state])

  const getDeatils = async () => {

    setIsLoading(true)
    CommonAPI({}, 'GET', `queryDetails?id=${Number(id)}&vendorId=${userId1 == null ? '' : userId1?._id}`, responseBack2)
  }

  const responseBack2 = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)
      console.log('ree', dec1);

      setDetails({ status: dt.status, data: dec1[0] })
    } else {
      console.log('errr', res);

    }
    setIsLoading(false)
  })

  const proposalList = async (page) => {
    setIsLoading(true)
    CommonAPI({}, 'GET', `queryProposal?queryId=${item._id}&page=${page}`, responseBack1)
  }

  const responseBack1 = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)
      setLoadMore(true);



      if (dec1 == undefined || dec1 == null) {
        setProposalsList(dec1)
      } else {
        setProposalsList(prevData => {
          const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
          return [...prevData, ...newData];
        });
      }

      if (dec1.length < 10) {
        setLoadMore(false);
      }
    }

    setIsLoading1(false)
    setIsLoading(false)
  })

  const val = () => {
    const err = {}

    if (proposal.trim().length < 100) {
      err.desc = "Please enter minimum 100 characters!"
    }

    setError(err);
    return Object.keys(err).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      queryId: item._id,
      description: proposal,
    }

    const edit = {
      queryId: item._id,
      id: editProposal[0]?._id,
      description: proposal,
    }

    const send = editProposal.length > 0 ? edit : data
    const enc1 = enc(send, key);


    if ((userInfo == null || userInfo?.role !== 'vendor')) {
      setIsModalOpen(true)
    } else {
      if (val()) {
        setIsLoading(true)
        CommonAPI(enc1, 'POST', 'createProposal', responseBack)
      }
    }
  };

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res?.data.data, key)

      setProposalsList((prevProposals) => {
        const existingProposalIndex = prevProposals.findIndex(
          (proposal) => proposal._id === dec1?._id
        );

        if (existingProposalIndex !== -1) {
          const updatedProposals = [...prevProposals];
          updatedProposals[existingProposalIndex] = {
            ...updatedProposals[existingProposalIndex],
            ...dec1,
          };
          return updatedProposals;
        } else {
          // Proposal doesn't exist, add the new one
          return [...prevProposals, dec1];
        }
      });

      getDeatils()
      // setProposal('')
      setEditProposal([])
      setPdf(null)
      tstSuccess('Proposal Send Successfully')
      const msg = { receiver_id: queryUserId, name: userId1?.business_name, sender_id: userId1?._id, query_id: item._id }
      const enc1 = enc(msg, key)
      socketRef.current.emit('send_proposal', enc1, (res) => {
      });

    } else {
      if (res.message == 'Invalid token') {
        setIsModalOpen(true)
      }
    }
    setIsLoading(false)
  })



  const date = dateFormat(item?.travelDate, 'date')
  const since = dateFormat(user?.createdAt, 'date')

  const max = 100
  const word = item && item.description && item.description.split(/\s+/);


  const handleShowMore = () => {
    setIsLoading1(true)
    proposalList(page + 1)
    setPage(prevPage => prevPage + 1)
  };

  const handleEdit = () => {
    const filter = proposalsList.filter(item => item.vendorId?._id === userId)
    setEditProposal(filter)

    setTimeout(() => {
      if (textFieldFocus.current) {
        textFieldFocus.current.focus()
      }
    }, 300)
  }


  const switchTab = () => {
    setActive('pr');
    setLoaded(true);
    if (!loaded) {
      proposalList();
    }
  }


  const handleStartChat = async (name, id) => {
    if (user?._id) {
      const createObject = { sender_id: user?._id, receiver_id: id }
      const create = enc(createObject, key)
      try {
        const joinO = { user_id: user?._id }
        const join = enc(joinO, key)
        socketRef.current.emit('join', join)

        socketRef.current.emit('create_chat', create, (res) => {
          dispatch(svReceiverDetails({ receiver_id: id, name: name, chat_id: res.chat_id }))
          toggleChatPopup()
        })
      } catch (error) {
        tstError('Please try again!')
        setRenderUseEffect(true)
      }
    } else {
      setIsModalOpen(true)
    }
  }


  const handleReportClick = (e, item) => {
    e.stopPropagation();

    setPId(item._id); setType('p'); setOpen2(true);
  };




  return (
    <div className='d-flex flex-column align-items-center bg-warnin1g mt-12 pt-3 pb-5 p-0' style={{ backgroundColor: '#fcfcfc' }}>

      {isLoading ? (
        <div className='' style={{ height: '65vh' }}>
          <Spin />
        </div>
      ) : (
        <>
          <div className='mt-2 w_95 mb-4 d-flex p-2 pt-3 pb-3 z-2 sticky-header'>
            <p className='Inter-Medium fs_15 text_gray m-0 flex-grow-1 text-capitalize'> {word?.slice(0, 10).join(' ') + '....'}</p>
          </div>


          <div className='w_95 bg-warning` d-flex align-items-center w-100 flex-column'>
            {details?.status >= 1 && (
              active !== 'pr' &&
              <div className='mb-4 w-100 d-flex align-items-center' style={{ backgroundColor: '#cdf9cd' }}>
                <div className='Source_Medium p-2 flex-grow-1' style={{ color: '#03b403' }}>Proposal Already Submitted for This Post.</div>
                <i className="fa-solid fa-pencil pe-3 pointer" style={{ color: '#03b403' }} onClick={() => handleEdit()}></i>
              </div>
            )}


            <Row className='bg-primary1 w-100'>
              {active == 'dt' ? (
                <Col md={12} lg={9} className='bg-warning11 mt-3'>
                  <div className='bg-warning1 d-flex w-100 justify-between'>
                    <div className='d-flex'>
                      <div className={`text_gray Source_SemiBold mx-3 pointer ${active == 'dt' ? 'active-underline text_thm' : ''}`} onClick={() => setActive('dt')}>Details</div>
                      <div className={`text_gray Source_SemiBold pointer ${active == 'pr' ? 'active-underline text_thm' : ''}`} onClick={() => switchTab()}>Proposals ({item?.totalProposals})</div>
                    </div>
                    <div className='Rubik-Medium bg-gradient-lightblue text_thm border_thmlight fs-13px h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center text-capitalize pointer m-0 text-decoration-none'  onClick={() => setIsModalOpen1(true)}>
                      Share
                      <i className="fas fa-share  ms-2 fs-20px"></i>
                    </div>
                  </div>

                  <div className='rd_6 p-3 shadow-n1 bg-white mt-20px'>

                    {/* first row */}
                    <div className='d-flex justify-between bg-warning1 mb-3'>
                      <p className='m-0 text_gray Rubik-SemiBold'>Budget {item?.currency?.code}{item?.budget}</p>
                      <p className='m-0 Inter-Medium text_gray text-end fs_13 ms-2'>Duration : {item?.duration}</p>
                    </div>

                    {/* description */}
                    <p className=" Source_Regular fs_14 text_gray z-3 mt-1">
                      <span className="show-on-large">
                        {isExpanded ? word?.join(' ') : word?.slice(0, max).join(' ')}
                        {!isExpanded && word?.length > max && '...'}
                        {word?.length > 100 && (
                          <span className='text-primary pointer fs_12 fw-bold z-5' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? ' See Less' : ' See More'}</span>
                        )}
                      </span>
                      <span className="show-on-small">
                        {isExpanded ? word?.join(' ') : word?.slice(0, 70).join(' ')}
                        {!isExpanded && word?.length > 70 && '...'}
                        {word?.length > 70 && (
                          <span className='text-primary pointer fs_12 fw-bold z-5' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? ' See Less' : ' See More'}</span>
                        )}
                      </span>
                    </p>

                    <div className='d-flex justify-content-between flex-wrap'>
                      <p className="Inter-Medium fs_13 text_gray mt-3 m-0 pe-3">Expected Date : {date || ''}</p>
                      <p className="Inter-Medium fs_13 text_gray mt-3 m-0">Travel Country : {item?.queryCountry[0]?.name || ''}</p>
                    </div>

                    <div className='d-flex justify-content-between flex-wrap  mt-3'>
                      <div className='d-flex'>
                        <p className="Inter-Medium fs_13 text_gray">Adults : {item?.adult || ''}</p>
                        {!(item?.child == 0 || item?.child == null) && <p className="Inter-Medium fs_13 text_gray">, Childs : {item?.child == 0 ? '' : item?.child}</p>}
                      </div>
                    </div>
                    <p className="Inter-Medium fs_13 text_gray flex-grow-1">Post Id : {item?.id || ''}</p>

                    <div className='bg-warning1 d-flex align-items-center justify-content-between'>
                      {/* categories */}
                      <p className='Source_Medium fs_14'>Looking for : {item?.looking_for.map((category, index) => (
                        <p className='mr_10 Source_Regular fs_12 rounded-pill p-1-5 px-2-5 text-secondary border_gray m-0 fw-normal badge' key={index}>{category}</p>
                      ))}</p>



                      {/* Report */}
                      {userInfo !== null && (
                        (user && user?._id) !== (userInfo && userInfo._id) && (
                          <p className="Rubik-Regular fs_12 text-primary mt-2 pointer" onClick={() => { setType('q'); setOpen2(true); }}>Report this Post</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className='mt-20px'></div>

                  {/* submit proposal */}
                  {(details?.status == 0 || editProposal.length > 0) && (
                    <>
                      {(userInfo == null || userInfo?.role == 'vendor') &&
                        <div className='px-1 mt-5 mb-5'>
                          <p className='Source_Bold '>Place a proposal on this Post</p>

                          <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                              <label className='Source_Medium fs_14 text-muted mb-1'>Describe your proposal (minimum 100 characters)</label>
                              <textarea
                                ref={textFieldFocus}
                                name="proposal"
                                placeholder='enter your proposal'
                                rows={4}
                                className="form-control Source_Regular fs_14 text_gray outline-none border_gray"
                                value={proposal}
                                onChange={(event) => setProposal(event.target.value)} />
                              {err.desc && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.desc}</p>}
                            </div>

                            <div className='d-flex align-items-end justify-content-end'>
                              <button type="submit" className="btn bg-gradient-thm text-white px-3 btn-block mt-3 fs_14 pt-1" disabled={isLoading}>
                                {isLoading ? <Spinner size='sm' /> : 'Submit Proposal'}
                              </button>
                            </div>
                          </form>

                        </div>}
                    </>
                  )}
                </Col>
              ) : (
                <Col xs={12} sm={12} md={12} lg={9} className='bg-warning1 mt-3'>
                  {proposalsList && proposalsList.length == 0 && (
                    <div className={`tab-pane bg-warning1 d-flex align-items-center flex-column`}>
                      <img src='/searching_nodata.avif' alt='no proposals' className='img-fluid h_250px' />
                      <p className='Source_Bold text_gray ms-5 mr_10'>Proposals not found</p>
                    </div>
                  )}
                  {proposalsList && proposalsList.map((item, i) => {
                    const word = item && item.description && item.description.split(/\s+/);
                    const date = dateFormat(item?.createdAt, 'datetime')
                    const textLength = item?.description?.length
                    const result = generateRandomText(textLength, textLength)

                    const vendor = item?.vendorId

                    return (
                      <div className='rd_6 p-2 shadow-n1 p-2 hover-me bg-white mb-3'>
                        <div className='d-flex w-100'>
                          <img src={item.vendorId.profile_image ? item.vendorId.profile_image : '/icons/blank_profile.png'} alt={vendor?.business_name || 'Vendor Image'} className='img-fluid h_120px w_150px rd_6 position-relative overflow-hidden' />

                          <div className="d-flex ms-2 p-1 w-100 ">
                            <div className='h-100 d-flex flex-column flex-grow-1' onClick={(e) => e.preventDefault()}>
                              <Link className='Rubik-SemiBold text_thmdark fs_15 fs-xs-14 mb-1 text-capitalize item-fixed-width' to={`/profile/${vendor?.slug}/${vendor?.id}`} state={{ id: vendor?._id, queryId: details?.data?._id }}>{vendor?.business_name || ''} <span className='Source_Regular text_thmdark fs_14'>( {vendor?.countryId?.name} )</span></Link>
                              <p className='Rubik-Regular text_gray2 fs_13 mb-1 pt-0 text-capitalize'>{vendor?.services?.join(', ')}</p>

                              <div className='Source_Regular d-show-on-md m-0 text_gray2 fs_13 mb-1'>{date}</div>
                              {showDescription && (
                                <div className='Source_Medium d-show-on-md fs_12 text-primary' onClick={(e) => { e.stopPropagation(); setPId(item._id); setType('p'); setOpen2(true); }}>Report Proposal</div>
                              )}

                              <div className='flex-grow-1' />
                              {(showDescription || userId == item?.vendorId?._id) && (
                                <Link to={'/proposal/details'} state={{ id: item?._id, queryId: details?.data?._id, queryUserId: queryUserId }} className='Rubik-Regular fs_12 text-primary item-fixed-width pointer m-0 fa-fade'>View Details <i className="fa-solid fa-angle-right ms-2 fs_12"></i></Link>
                              )}

                            </div>


                            <div className='d-flex flex-column align-end text-end w_30 d-none-on-md'>
                              {showDescription && (
                                <div className='Source_Medium fs_12 text_thm pointer item-fixed-width' onClick={(e) => handleReportClick(e, item)}><i className='fa fa-flag-o mr_5 fs_9 text_thm'></i>Report Proposal</div>
                              )}

                              <div className='Source_Regular m-0 text_gray2 fs_13 mb-2'>{date}</div>
                              <div className='flex-grow-1' />
                              {showDescription && (
                                <div className='bg-gradient-thm h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendor?.business_name, vendor?._id)}>
                                  <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-comment-dots fa-fade me-1 fs_13"></i> Chat with US</p>
                                </div>)}
                            </div>
                          </div>
                        </div>

                        <Link to={(showDescription || userId == item?.vendorId?._id) ? '/proposal/details' : null} state={{ id: item?._id, queryId: details?.data?._id, queryUserId: queryUserId }} className='pointer-none'>
                          <div className='d-flex justify-content-between '>
                            <div className='bg-warning1 position-relative mt-2'>
                              {(showDescription || userId == item?.vendorId?._id) ? (
                                <p className="Source_Regular fs_14 text_gray mt-3 word-break-line pointer">
                                  <span className="show-on-large">
                                    {isExpanded1 ? word?.join(' ') : word?.slice(0, max).join(' ')}
                                    {!isExpanded1 && word?.length > max && '...'}
                                    {word?.length > 100 && (
                                      <span className='text-primary pointer fs_12 fw-bold z-5' onClick={() => setIsExpanded1(!isExpanded1)}>{isExpanded1 ? ' See Less' : ' See More'}</span>
                                    )}
                                  </span>
                                  <span className="show-on-small">
                                    {isExpanded1 ? word?.join(' ') : word?.slice(0, 70).join(' ')}
                                    {!isExpanded1 && word?.length > 70 && '...'}
                                    {word?.length > 70 && (
                                      <span className='text-primary pointer fs_12 fw-bold z-5' onClick={() => setIsExpanded1(!isExpanded1)}>{isExpanded1 ? ' See Less' : ' See More'}</span>
                                    )}
                                  </span>
                                </p>
                              ) : (
                                <div className='Source_Regular fs_14 text_gray  word-break-line' onClick={(e) => e.stopPropagation()}>
                                  {result}
                                  <div className='blur' onClick={(e) => e.preventDefault()} />
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}

                  {loadMore && !isLoading && proposalsList && proposalsList?.length > 0 && (
                    <div className='d_flex_align_justify_center  mt-4'>
                      <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                    </div>
                  )}
                </Col>
              )}

              {/* About */}
              <Col md={12} lg={3} className='bg-success1 mt-3'>
                <div className='rd_6 p-2 pb-3 px-3 shadow-n1 bg-white'>
                  <p className='Rubik-SemiBold fs_15 mb-2'>About Traveller</p>

                  <p className='Source_Regular text_gray mb-2 fs_14 text-capitalize'><i className="bi bi-person-fill mr_10 text_thm"></i>{user?.first_name + ' ' + user?.last_name}</p>
                  <p className='Source_Regular text_gray mb-2 fs_14'><i className="fa fa-globe mr_10 text_thm"></i>{item?.userCountry?.name}</p>
                  <p className='Source_Regular text_gray  fs_14'><i className='fas fa-clock fs_14 mr_10 text_thm'></i>{since}</p>
                </div>
              </Col>
            </Row>

          </div>
        </>
      )}

      {/* Report */}
      <ReportSection isVisible={open2} onClose={setOpen2} id={type == 'q' ? item?._id : pId} type={type} />

      {/* Modal Sigin */}
      <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

      <ShareModal url={url} isVisible={isModalOpen1} onClose={setIsModalOpen1} />

    </div>
  )
}

export default QueryDetails