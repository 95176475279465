import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

const FaqSection = () => {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key)); // Toggle between opening and closing the item
  };


  return (
    <Row className="w-100 mt-40px">
      <Col xxl={7} xl={7} lg={5} md={12} >
        <Accordion activeKey={activeKey} className="">
          <Accordion.Item eventKey='0' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("0")} className='accordion-header h-50px Inter-Medium'>
              What is TravellingLark?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              TravellingLark is a comprehensive travel platform that connects travelers with vendors offering travel packages, hotel accommodations, vehicles, and travel guides. Our goal is to simplify your travel planning while helping vendors showcase their services.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='1' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("1")} className='h-50px Inter-Medium'>
              How much does it cost to list my services on TravellingLark?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              Currently, listing your services, whether it’s travel packages, hotel accommodations, or other offerings, is completely free of charge. No hidden fees or commissions are involved for now!
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='2' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("2")} className='h-50px Inter-Medium'>
              Who can list services on TravellingLark?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              We welcome travel vendors, hotels, travel agencies, vehicle rental providers, and travel guides to showcase their services on our platform.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='3' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("3")} className='h-50px Inter-Medium'>
              How can vendors benefit from TravellingLark?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white' y>
              Vendors can gain visibility by reaching a wide audience of travelers, manage their offerings easily through our platform, share their services directly with customers, and enjoy zero listing or commission fees for now.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='4' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("4")} className='h-50px Inter-Medium'>
              How can travelers use TravellingLark?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              Travelers can browse through a wide range of travel packages, hotels, and other services to plan their perfect trip. You can also submit your travel details to receive personalized proposals from trusted vendors.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='5' className='mb-3 accordion-item-custom h-50p1x'>
            <Accordion.Header onClick={() => handleToggle("5")} className='h-50px Inter-Medium'>
              Does TravellingLark charge travelers for using the platform?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              No, TravellingLark is free for travelers. You can explore and connect with vendors without any fees.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='6' className='mt-1'>
            <Accordion.Header onClick={() => handleToggle("6")} className='h-50px Inter-Medium'>
              How can I contact TravellingLark for support?
            </Accordion.Header>
            <Accordion.Body className='text-secondary Inter-Regular bg-white'>
              If you have any questions or need assistance, feel free to reach out to us at <strong>support@travellinglark.com</strong>. Our team is here to help!
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      <Col xxl={5} xl={5} lg={5} md={0} className='bg-1danger px-0 d-flex justify-center'>
        <img src={`/images/faq.webp`} alt='Why choose us' className='img-fluid h-410px h-380px--md h-350px--sm h-300px--xs-g remove-image-bg-white  d-hide-on-md' />
      </Col>
    </Row>
  );
};

export default FaqSection;
