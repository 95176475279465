import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import ImgHotel from './ImgHotel';
import { DatePicker, Modal, Spin } from 'antd';
import Counter from './Counter';
import Map from './Map';
import { tstError, tstSuccess } from '../../Componets/HotToast';
import { Link, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { dateFormat, disabledDate, openInNewTab } from '../../Componets/CommonFs';
import useTruncation, { HTMLRender, HTMLRenderNormal } from '../../Componets/HTMLRender';
import { MemContext } from '../../ContextRedux/Context';
import { ActionModal, FeedbackModal, ModalTwo, ShareModal } from '../../Componets/Modal';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import Carousel from 'react-multi-carousel';
import SignInModal from '../Auth/SignInModal';
import RatingStars from '../../Componets/RatingStars';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../Componets/Loader';
import { useLocalStorage } from 'react-fu-hooks';
import { svReceiverDetails, svReviewId } from '../../ContextRedux/Action';
import { io } from 'socket.io-client';
import MessagingPopup from '../Dashboard/MessagingPopup';
import { useChatPopup } from '../../ContextRedux/ChatPopupContext';
const { RangePicker } = DatePicker;

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;
const socket_url = process.env.REACT_APP_SOCKET_URL;
const socket_local_url = process.env.REACT_APP_SOCKET_LOCAL;


const responsive_pkg = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 11,
        slidesToSlide: 8
    },
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 7
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 7,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 788 },
        items: 6,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 787, min: 559 },
        items: 4,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 2
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 3,
        slidesToSlide: 2
    },
    xxsmobile: {
        breakpoint: { max: 380, min: 0 },
        items: 2,
        slidesToSlide: 1
    },
};

const responsive_8 = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2200 },
        items: 7,
        slidesToSlide: 7
    },
    tablet: {
        breakpoint: { max: 2200, min: 968 },
        items: 5,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 967, min: 759 },
        items: 4,
        slidesToSlide: 4
    },
    xmobile: {
        breakpoint: { max: 759, min: 549 },
        items: 3,
        slidesToSlide: 3
    },
    xsmobile: {
        breakpoint: { max: 549, min: 0 },
        items: 2,
        slidesToSlide: 2
    },
};


function HotelDetails() {
    const { slug, id } = useParams();
    const navigate = useNavigate();


    const [faqOpen, setFaqOpen] = useState(0);
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [num, setNum] = useState('');
    const [description, setDescription] = useState('');
    const [vendorId, setVendorId] = useState('');

    const [selectedDate, setSelectedDate] = useState(null);
    const [date, setDate] = useState([])
    const [data, setData] = useState([]);
    const [hotelType, setHotelType] = useState([]);
    const [locations, setLocations] = useState([]);
    const [remHotelType, setRemHotelType] = useState([]);
    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')


    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [hideBtn, setHideBtn] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const [list2, setList2] = useState([])
    const [renderUseEffect, setRenderUseEffect] = useState(false);
    const [list1, setList1] = useState([])
    const [isShow, setIsShow] = useState(false);

    const [err, setErr] = useState({})
    const [roomDetails, setRoomDetails] = useState({
        room: 1,
        adults: 1,
        children: 0
    });

    const userDetails = useSelector(reducer => reducer.data.info)
    const url = window.location.href;
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    const { isChatOpen, toggleChatPopup } = useChatPopup();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        
        if (userDetails?.tkn) {
            socketRef.current = io(socket_url, {
                query: {
                    token: userDetails?.tkn
                }
            });
        }
    }, [renderUseEffect])

    const handleChangeDate = (values) => {
        if (values) {
            const formattedDates = values.map(item => dateFormat(item, 'dmy').replace(/\//g, '-'))
            setDate(formattedDates)
            setSelectedDate(values)
        } else {
            setDate([]);
        }
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen(false);
            setShowModal(false)
        }, 1000);
    };

    const toggleFaq = (index, ty) => {
        setFaqOpen(faqOpen === index ? -1 : index)
    }

    useEffect(() => {
        getDetails();
    }, [id]);


    const getDetails = () => {
        const data = {
            id: Number(id),
            slug: slug
        }

        const enc1 = enc(data, key);

        setIsLoading1(true)
        CommonAPI(enc1, 'POST', `hotelDetails`, responseBack)
        CommonAPI({}, 'GET', `hotelTags?id=${Number(id)}&slug=${slug}`, responseBack3)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);

            setData(dec1?.details)
            setHotelType(dec1?.hoteltype)
            setRemHotelType(dec1?.remainingHoteltype)
            setLocations(dec1?.location)
            setVendorId(dec1?.details[0]?.vendor)

            CommonAPI({}, 'GET', `reviewList?typeId=${dec1?.details[0]?._id}`, responseBack2)
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
            navigate('/not-found', { replace: true });
        }
        setIsLoading1(false)
    })

    const responseBack2 = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key);
            setList2(dec1?.data)

            // if (dec1?.packages == undefined || dec1?.packages == null) {
            //   setList2(dec1?.packages)
            // } else {
            //   setList2(prevData => {
            //     const newData = dec1?.packages?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
            //     return [...prevData, ...newData];
            //   });
            // }

            // if (dec1?.packages?.length < 10) {
            //   setLoadMore(false);
            // }
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }

        }
        setIsLoading(false)
    })

    const responseBack3 = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key);
            setList1(dec1)
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })


    const val = () => {
        const err = {};

        if (!value.trim()) {
            err.name = 'Enter valid name!'
        }
        if (!/^[^\s@]+@gmail\.com$/.test(email)) {
            err.mail = 'Invalid email format!'
        }

        if (num.trim().length < 10 || num.trim().length > 12) {
            err.num = 'Please enter valid number!'
        }

        if (date.length == 0) {
            err.date = 'Please select date!'
        }

        setErr(err)
        return Object.keys(err).length == 0;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const request = {
            name: value,
            email: email,
            phone: num,
            message: description,
            type: 'Hotel',
            type_id: data[0]?._id,
            vendorId: vendorId?._id,
            checkIn: date[0],
            checkOut: date[1],
            room: roomDetails?.room,
            adult: roomDetails?.adults,
            child: roomDetails?.children || '0',
        }

        const enc1 = enc(request, key)

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'requestCall', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            setValue('')
            setEmail('')
            setNum('')
            setDescription('')
            setSelectedDate('')
            setRoomDetails({
                room: 1,
                adults: 1,
                children: 0
            })
            setDate([])

            setData(prev => [{
                ...prev[0],
                leadStatus: 1,
            }])
            tstSuccess('Callback request sent successfully')
        } else {
            if ((res.message == 'Invalid token' || res.message == 'No token provided')) {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })


    const details = data[0] && data[0]
    const images = [...details?.gallery || [], details?.image || []]
    const lowPrice = details?.discountPrice
    const final = (lowPrice == null || lowPrice == 0) ? details?.price : lowPrice


    const reloadPage = (updated, type) => {

        if (type == 'update') {
            setList2(prev =>
                prev.map(item =>
                    item._id === updated._id ?
                        { ...item, message: updated.message, rating: updated.rating } : item
                )
            )
        } else if (type == 'add') {
            list2.push(
                updated
            )
        } else {
            const filter = list2.filter(item => item._id !== updated)
            setList2(filter)
        }
    }

    const handleSave = (value, type) => {
        if (type === 'location') {
            setHotelLocation(value);
        } else {
            setHotelId(value);
            // setHotelId(value[1]);
        }
        const newWindow = window.open(`/hotels`, '_blank')

        if (newWindow) { newWindow.opener = null; newWindow.rel = 'noopener noreferrer'; }
    };


    const handleStartChat = async (name, id) => {

        if (userDetails?.user?._id) {
            const createObject = { sender_id: userDetails?.user?._id, receiver_id: id }
            const create = enc(createObject, key)
            try {
                const joinO = { user_id: userDetails?.user?._id }
                const join = enc(joinO, key)
                socketRef.current.emit('join', join)

                socketRef.current.emit('create_chat', create, (res) => {
                    dispatch(svReceiverDetails({ receiver_id: id, name: name, chat_id: res.chat_id }))
                    toggleChatPopup()
                })
            } catch (error) {
                
                tstError('Please try again!')
                setRenderUseEffect(true)
            }
        } else {
            setIsModalOpen(true)
        }
    }

    const { contentRef, isTruncated } = useTruncation(data?.[0]?.about);



    return (
        <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5 bg-light'>
            {isLoading1 ? (
                <div className='mt-5'>
                    <Spin />
                </div>
            ) : (
                <>
                    <ImgHotel img={images || []} />

                    <div className='w_95 mt-4 d-flex align-items-center flex-column w-100'>


                        <Row className='w-100'>
                            <Col lg={8} className="bg-warning1">
                                {data.length > 0 && (
                                    data.map((item) => {
                                        return (
                                            <div key={item._id} className='rd_6 pt-2 mt-1'>
                                                <div className='d-flex align-items-center justify-between mb-2'>
                                                    <p className='m-0 fw-bold fs_18 text-capitalize me-1'>{item.name}</p>
                                                    <div className='shadow-slowclean text_thm bg-white pointer p-1 px-2-5 rd_10 fs_13' onClick={() => setIsModalOpen1(true)}>
                                                        Share
                                                        <i className="fa-solid fa-share-nodes ms-2"></i>
                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-center text_gray fs_12 rd_50 Source_Regular bg-light'>
                                                    <p className='m-0'>{item.fullAddress}</p>
                                                </div>

                                                <div className='d-flex align-items-center pb-3 mt-3'>
                                                    <div className='m-0 Source_Medium d-flex text_thm bg_thmlightdark px-3 p-1 rd_6 fs_12 mr_10 '>Hotel Price {item?.country?.code}{final}</div>
                                                    <div className='m-0 Source_Medium d-flex text_thm bg_thmlightdark px-3 p-1 rd_6 fs_12 mr_10 '>Country : {item?.country?.name}</div>
                                                    <div className='d-none-on-xs'>
                                                        <div className='m-0 Source_Medium d-show-on-x d-inline-flex text_thm bg_thmlightdark px-3 p-1 mb-15px--xs rd_6 fs_12 mr_10 '>CheckIn Checkout Time : {details?.checkIn} - {details?.checkOut}</div>
                                                    </div>
                                                </div>

                                                <div className='d-show-on-xs'>
                                                    <div className='m-0 Source_Medium  d-inline-flex text_thm bg_thmlightdark px-3 p-1 mb-3 rd_6 fs_12 mr_10 '>CheckIn Checkout Time : {details?.checkIn} - {details?.checkOut}</div>
                                                </div>

                                                <div className='d-flex align-items-center pb-3 scrollable scrollable-h gap-2'>
                                                    <div className='border-light-subtle border d-flex align-items-center text_gray px-2-5 fs_12 p-1-5 rd_50 Source_Regular bg-light'>
                                                        <span className="material-symbols-sharp fs_14 mr_5">star</span>
                                                        <p className='m-0'>{item.property} Star Hotel</p>
                                                    </div>
                                                    <div className='border-light-subtle border d-flex align-items-center text_gray px-2-5 fs_12 p-1-5 rd_50 Source_Regular bg-light'>
                                                        <span className="material-symbols-sharp text_gray fs_14 mr_5">apartment</span>
                                                        <p className='m-0'>{item?.type?.map(item => item?.name).join(', ')}</p>
                                                    </div>
                                                </div>

                                                <div className='px-1 p-3 pb-0 rd_6 mt-4 bg-white d-flex align-items-start flex-wrap shadow-slowclean'>
                                                    {item.amenities.map((it, i) => (
                                                        <div key={i} className='Source_Regular  px-3 bg-warning1 border_gray1 mb-3 fs_12 d-flex align-items-center flex-column'>
                                                            <img className="rd_10 hw_30px" src={`${img_base_url + it.image}`} alt={it.name} />
                                                            <p className='m-0 mt-1 text-center'>{it.name}</p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {item.about && (
                                                    <div className='shadow-slowclean p-2-5 rd_6 bg-white mt-3'>
                                                        <div className={`content-wrapper Source_Regular  ${isExpanded ? '' : 'mini-line-1 ten'}`} ref={contentRef}>
                                                            <HTMLRender htmlContent={item?.about || ''} />
                                                        </div>
                                                        {isTruncated && (
                                                            <div className='text_thmdark pointer fs_12 Inter-Medium mt-1 text-end w-100' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? ' See Less' : ' See More'}</div>
                                                        )}
                                                    </div>
                                                )}


                                                <Map address={item.fullAddress} />
                                            </div>
                                        )
                                    })
                                )}
                            </Col>
                            <Col lg={4} className=''>
                                <form onSubmit={handleSubmit} className='rd_6 bg-white p-3 mt-4 shadow-slowclean'>
                                    <div className='d-flex justify-between'>
                                        <div>
                                            <p className='m-0 fw-bold text-muted fs_15'>Get Callback</p>
                                            <div className='d-flex align-items-end mt-2'>
                                                <p className='m-0 Rubik-Medium text-secondary text_thm fs_14'>{details?.currencyData?.code}{final}</p>
                                                {lowPrice != null && lowPrice > 0 && (
                                                    <p className='Rubik-Regular m-0 ms-2 fs_12 TxtCut text-danger toext-secondary'>{details?.currencyData?.code}{details?.price}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='mt-3 bg-gradient-thm h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendorId?.business_name, vendorId?._id)}>
                                            <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-comment-dots fa-fade me-1 fs_13"></i> Chat with us</p>
                                        </div>
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <input type="text" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.name ? 'mb-1' : 'mb-3'}`} placeholder="Enter Your Name" value={value} onChange={(event) => setValue(event.target.value)} />
                                        {err.name && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.name}</p>}
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <input type="email" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.mail ? 'mb-1' : 'mb-3'}`} placeholder="Enter Your Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                        {err.mail && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.mail}</p>}
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <input type="number" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.num ? 'mb-1' : 'mb-3'}`} placeholder="Phone number" value={num} onChange={(event) => setNum(event.target.value)} />
                                        {err.num && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.num}</p>}
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <RangePicker
                                            value={selectedDate}
                                            className='fs_12 text_gray w-100 h_38px rd_10 border_gray'
                                            format={'DD-MM-YYYY'}
                                            disabledDate={disabledDate}
                                            onChange={handleChangeDate}
                                            placeholder={['Checkin Date', 'Checkout Date']}
                                        />
                                        {err.date && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.date}</p>}
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <div className='form-control fs_13 h_38px rd_10 border_gray d-flex align-items-center  text_dark_medium' onClick={() => setOpen(true)}>{roomDetails.room} Room • {roomDetails.adults} Adults • {roomDetails.children} Child</div>
                                        {err.room && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.room}</p>}
                                    </div>

                                    <div className="form-group mb-3">
                                        <textarea name="description" placeholder='message' rows={4} className="form-control fs_14 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" value={description} onChange={(event) => setDescription(event.target.value)} />
                                    </div>

                                    <button className='bg-gradient-thm btn w-100 mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium p-1-5 px-2' disabled={isLoading} >
                                        {isLoading ? <Spinner size='sm' /> : 'Request Callback'}
                                    </button>
                                </form>

                                <div className='rd_6 d-flex align-items-center flex-column bg-white p-3 px-4 mt-4 shadow-slowclean'>
                                    <img src='/images/man_with_lauggage.png' alt='Traveller Image' className='img-fluid h_130px w_200px rotate-img-flip' />
                                    <p className='m-0 fw-bold fs_16 mt-4'>Tired of Searching?</p>
                                    <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Post your travel upcoming travel details on travellinglark.com and get offer quotations from multiple travel agencies. Choose an offer that meets your budget and requirements, save money and enjoy your travel. Post now!</p>

                                    <Link to={'/post-travel'}>
                                        <button className='bg-gradient-thm btn w-auto mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium px-4' disabled={isLoading}>
                                            Post Your Travel
                                        </button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        {/* Reviews */}
                        <div className='d-flex align-items-center mt-4 w-100 justify-content-between'>
                            <div className='Rubik-Medium text-start1 mt-3 mb-2'>Reviews</div>
                            {list2 && list2.length > 0 && (
                                !hideBtn &&
                                details?.leadStatus == 1 && (
                                    details?.reviewStatus == 0 &&
                                    <div className='mt-3 bg-gradient-thm h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => setOpen3(!open3)}>
                                        <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-star me-1"></i> Submit Review</p>
                                    </div>
                                )
                            )}
                        </div>

                        {list2 && list2.length > 0 ? (
                            <>
                                <Row className='w-100 mt-2 '>
                                    {list2 && list2.map((item, i) => {
                                        const user = item?.userId
                                        const fName = user?.first_name || ''
                                        const lName = user?.last_name || ''

                                        const date = dateFormat(item.createdAt, 'dmy')
                                        const sendTo = {
                                            ...item,
                                            detailsId: details && details?._id,
                                            vendorId: vendorId?._id
                                        }

                                        const dots = lName && lName.length > 7 ? '...' : ''

                                        return (
                                            <Col key={item._id} xs={12} sm={12} md={6} lg={4} xl={4} className='bg-warning1'>
                                                <div className='bg-white rd_4 p-3 bg-warning1 mb-4'>
                                                    <div className='d-flex align-items-center '>
                                                        <img src={`/icons/user.png`} alt='User Image' className='img-fluid mty-image hw_35px mr_10' />
                                                        <div className='w-100'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='Rubik-Medium fs_15 flex-grow-1 text-capitalize'>{fName} {lName.slice(0, 7)}{dots} <p className='m-0 Source_Regular fs_13 text-secondary d-inline-block'>({user?.countryId?.name})</p></div>
                                                                {userDetails?.user?._id == user._id && (
                                                                    <ActionModal isVisible={isShow} onClose={setIsShow} data={sendTo} callback={setHideBtn} reload={reloadPage} type={'review'} />
                                                                )}
                                                            </div>
                                                            <div className='d-flex align-items-center bg1-warning'>
                                                                <RatingStars rating={item.rating} />
                                                                <div className='flex-grow-1' />
                                                                <div className='Source_Regular fs_14 text-end text-secondary d-none-on-xs'>{date}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='Source_Regular fs_14 text-muted mt-3'>{item.message}</div>
                                                    <div className='Source_Regular fs_14 mt-2 text-end text-secondary d-show-on-xs'>{date}</div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </>
                        ) : (
                            <div className={`tab-pane bg-warning1 mb-2 d-flex align-items-center flex-column`}>
                                <img src='/images/not_data.webp' alt='no revews found' className='img-fluid h_180px' />
                                <p className='Source_Medium text_gray ms-5 mr_10'>Reviews not found</p>
                                {!hideBtn &&
                                    details?.leadStatus == 1 && (
                                        details?.reviewStatus == 0 &&
                                        <div className='mt-1 bg-gradient-thm h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => setOpen3(!open3)}>
                                            <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-star me-1"></i> Submit Review</p>
                                        </div>
                                    )
                                }
                            </div>
                        )}

                        {data?.[0]?.faq.length > 0 && (
                            <>
                                <p className='m-0 fw-bold fs_18 mt-1 mb-1 w-100'>FAQ</p>
                                {data[0].faq.map((it, i) => (
                                    <div key={i} className={`card mt-2 w-100 pointer rounded-0 rd_tl_tr_6 ${faqOpen === i ? 'border_thm' : ''}`}>
                                        <div className={`card-header d-flex align-items-center ${faqOpen === i ? 'bg-gradient-thm text-white' : 'bg-white'}`} onClick={() => toggleFaq(i, 'faq')}>
                                            <div className="border-0 bg-transparent flex-grow-1 fs_14 Rubik-Medium">
                                                {it.title}
                                            </div>
                                            {faqOpen === i ?
                                                <i className="fa fa-angle-up fs_14 text-white"></i> :
                                                <i className="fa fa-angle-down fs_14 text_gray2"></i>
                                            }
                                        </div>
                                        <div className={`collapse-content ${faqOpen === i ? 'expanded' : ''}`}>
                                            <p className="card-body m-0 Source_Regular fs_14">
                                                {it.description}
                                            </p>
                                        </div>
                                    </div>
                                ))
                                }
                            </>
                        )}


                        <div className='Inter-Bold fs_16 flex-grow-1 mt-3 fs-xs-14 ms-1 w-100'>Looking for Hotels</div>

                        {isLoading1 ? (
                            <div className='w-100'>
                                <Loader type={'country'} />
                            </div>
                        ) : (
                            <div className='w-100'>
                                <Carousel
                                    responsive={responsive_pkg}
                                    swipeable={true}
                                    draggable={true}
                                    ssr={true}
                                    infinite={false}
                                    autoPlaySpeed={2500}
                                    keyBoardControl={true}
                                    customTransition="transform 1000ms ease-in-out"
                                    containerClass="carousel-container mt-2"
                                    itemClass="carousel-item1 "
                                    customLeftArrow={
                                        <CustomLeftArrow
                                            direction="left"
                                            mt={'mt-30px'}
                                        />
                                    }
                                    customRightArrow={
                                        <CustomLeftArrow
                                            direction="right"
                                            mt={'mt-30px'}
                                        />
                                    }
                                >
                                    {hotelType && hotelType?.map((item, index) => {

                                        return (
                                            <div key={item._id} className='mt-3 mb-4 rd_6 mx-2 pointer hover-me d-flex align-items-center flex-column' onClick={() => handleSave(item?._id, '')}>
                                                <img alt={item?.name} src={item?.image ? `${img_base_url + item?.image}` : `/images/ocean.png`} className='h_130px rd_10 w_120px w-xxs-99' />

                                                <div className='NunitoSans-SemiBold fs_14 w-100 text_dark_medium text-center mt-2'>{item?.name}</div>
                                            </div>
                                        )
                                    })}

                                </Carousel>
                            </div>
                        )}


                        {locations?.length > 0 && (
                            <>
                                <div className='Inter-Bold fs_16 flex-grow-1 mt-2 fs-xs-14 ms-2 w-100'>Popular Locations in {data[0]?.country?.name}</div>

                                <div className='w-100 mt-4'>
                                    {isLoading1 ? (
                                        <Loader type={'country'} />
                                    ) : (
                                        <Carousel
                                            swipeable={true}
                                            draggable={true}
                                            responsive={responsive_8}
                                            ssr={true}
                                            autoPlay
                                            infinite
                                            autoPlaySpeed={2500}
                                            keyBoardControl={true}
                                            customTransition="transform 1000ms ease-in-out"
                                            containerClass="carousel-container"
                                            itemClass="carousel-item1"
                                            customLeftArrow={
                                                <CustomLeftArrow
                                                    direction="left"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            customRightArrow={
                                                <CustomLeftArrow
                                                    direction="right"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            dotListClass="custom-dot-list-style"
                                        >
                                            {locations && locations?.map((item, index) => {
                                                return (
                                                    <div key={item._id} className="mb-4 position-relative bg-white rd_30 mx-2-5 " onClick={() => handleSave(item.name, 'location')}>
                                                        <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                                                        <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                                            top: 0,
                                                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                                        }}>
                                                            <div className='NunitoSans-Medium fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </Carousel>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            <Modal
                title="Room & Guest"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open}
                onOk={() => { handleOk(); }}
                onCancel={() => setOpen(false)}
            >
                <Counter details={roomDetails} setDetails={setRoomDetails} />
            </Modal>

            <FeedbackModal isVisible={open3} onClose={setOpen3} type={'hotel'} id={details && details?._id} vendorId={vendorId?._id} callback={setHideBtn} reload={reloadPage} />
            <ShareModal url={url} isVisible={isModalOpen1} onClose={setIsModalOpen1} />

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    )
}

export default HotelDetails