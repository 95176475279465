const Vehicle = [
    {
        id: 1,
        name: 'Car',
        icon: '/icons/car.png'
    },
    {
        id: 2,
        name: 'Bus',
        icon: '/icons/school-bus.png'
    },
    {
        id: 3,
        name: 'Mini Car',
        icon: '/icons/3d-car.png'
    },
    {
        id: 4,
        name: 'SUV',
        icon: '/icons/suv.png'
    },
    {
        id: 5,
        name: 'Bike',
        icon: '/icons/bycicle.png'
    },
    {
        id: 6,
        name: 'Car',
        icon: '/icons/car.png'
    },
    {
        id: 7,
        name: 'Bus',
        icon: '/icons/school-bus.png'
    },
    {
        id: 8,
        name: 'Mini Car',
        icon: '/icons/3d-car.png'
    },
    {
        id: 9,
        name: 'SUV',
        icon: '/icons/suv.png'
    },
    {
        id: 10,
        name: 'Bike',
        icon: '/icons/bycicle.png'
    },
]

const Hotels = [
    {
        img: 'https://r1imghtlak.ibcdn.com/d87264223f6611e68bd60015c5f4277e.jpg',
        address: 'Bhiwani',
        name: 'Mumbai Metro Hotel'
    },
    {
        img: 'https://r2imghtlak.ibcdn.com/r2-mmt-htl-image/htl-imgs/201712181742204343-8259fb60e9b211eda7790a58a9feac02.jpg',
        address: 'Bhiwani',
        name: 'Hotel Magnet'
    },
    {
        img: 'https://r2imghtlak.ibcdn.com/r2-mmt-htl-image/htl-imgs/201912061819327380-3dc96606351c11eeb8480a58a9feac02.jpg',
        address: 'Bhiwani',
        name: 'J Hotel & Banquets'
    },
    {
        img: 'https://r1imghtlak.ibcdn.com/d87264223f6611e68bd60015c5f4277e.jpg',
        address: 'Bhiwani',
        name: 'Mumbai Metro Hotel'
    },
    {
        img: 'https://r2imghtlak.ibcdn.com/r2-mmt-htl-image/htl-imgs/201712181742204343-8259fb60e9b211eda7790a58a9feac02.jpg',
        address: 'Bhiwani',
        name: 'Hotel Magnet'
    },
    {
        img: 'https://r2imghtlak.ibcdn.com/r2-mmt-htl-image/htl-imgs/201912061819327380-3dc96606351c11eeb8480a58a9feac02.jpg',
        address: 'Bhiwani',
        name: 'J Hotel & Banquets'
    },
]

const PkgList = [
    {
        img: 'https://p4.wallpaperbetter.com/wallpaper/624/380/1000/life-resort-hotel-resort-hotel-wallpaper-preview.jpg',
        name: 'Best Luxury Packages'
    },
    {
        img: 'https://d2qa7a8q0vuocm.cloudfront.net/frontend/assets/img/Bali.png',
        name: 'Best Luxury Packages'
    },
    {
        img: 'https://d2qa7a8q0vuocm.cloudfront.net/frontend/assets/img/Rajasthan.png',
        name: 'Best Luxury Packages'
    },
    {
        img: 'https://www.holidify.com/images/cmsuploads/compressed/309248982_20220223182716.jpg',
        name: 'Best Luxury Packages'
    },
    {
        img: 'https://www.holidify.com/images/cmsuploads/compressed/309248982_20220223182716.jpg',
        name: 'Best Luxury Packages'
    },
    {
        img: 'https://www.holidify.com/images/cmsuploads/compressed/309248982_20220223182716.jpg',
        name: 'Best Luxury Packages'
    },
]

const Country = [
    {
        img: '/icons/india-gate.png',
        name: 'India'
    },
    {
        img: '/icons/big-ben.png',
        name: 'Canada'
    },
    {
        img: '/icons/statue-of-liberty.png',
        name: 'UK'
    },
    {
        img: '/icons/kremlin.png',
        name: 'Russia'
    },
    {
        img: '/icons/federal-palace-of-switzerland.png',
        name: 'Switzerland'
    },
    {
        img: '/icons/torii.png',
        name: 'Japan'
    },
    {
        img: '/icons/bhutan.png',
        name: 'Bhutan'
    },
    {
        img: '/icons/kremlin.png',
        name: 'Russia'
    },
    {
        img: '/icons/india-gate.png',
        name: 'India'
    },
    {
        img: '/icons/big-ben.png',
        name: 'Canada'
    },
    {
        img: '/icons/statue-of-liberty.png',
        name: 'UK'
    },
    {
        img: '/icons/kremlin.png',
        name: 'Russia'
    },
]

const TripCategories = [
    {
        img: '/icons/pkg/road_c.png',
        name: 'Road Trip'
    },
    {
        img: '/icons/pkg/hiking.png',
        name: 'Trekking'
    },
    {
        img: '/icons/pkg/honeymoon.png',
        name: 'Honeymoon'
    },
    {
        img: '/icons/pkg/beach.png',
        name: 'Weekend'
    },
    {
        img: '/icons/pkg/tent.png',
        name: 'Camping Trips'
    },
    {
        img: '/icons/pkg/acquisition.png',
        name: 'Corporate Trips'
    },
    {
        img: '/icons/pkg/traveling.png',
        name: 'Backpacking Trips'
    },
    {
        img: '/icons/pkg/island.png',
        name: 'Island Trips'
    },
    {
        img: '/icons/pkg/desert.png',
        name: 'Desert Expedition'
    },
];

const States = [
    {
        name: "Haryana",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    },
    {
        name: "Maharashtra",
        img: "https://www.ibef.org/assets/images/Uttar-Pradesh-tajmahal.jpg"
    },
    {
        name: "Bihar",
        img: "https://www.shutterstock.com/image-photo/kedarnath-temple-famous-uttrakhand-beautiful-260nw-2371860779.jpg"
    },
    {
        name: "West Bengal",
        img: "https://static.india.com/wp-content/uploads/2018/08/maharashtra-1.jpg?impolicy=Medium_Resize&w=1200&h=800"
    },
    {
        name: "Madhya Pradesh",
        img: "https://www.holidify.com/images/bgImages/WEST-BENGAL.jpg"
    },
    {
        name: "Tamil Nadu",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    },
    {
        name: "Rajasthan",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    },
    {
        name: "Karnataka",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    },
    {
        name: "Gujarat",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    },
    {
        name: "Haryana",
        img: "https://currentaffairs.adda247.com/wp-content/uploads/multisite/sites/5/2022/07/21154417/Haryana.jpg"
    }
];

const lehLadakhTags = [
    "Adventure",
    "Himalayas",
    "Buddhist Monasteries",
    "Pangong Lake",
    "Scenic Beauty",
    "Trekking",
    "Motorbike Trip",
    "High Altitude",
    "Culture",
    "Zanskar Valley"
];

const GuiderList = [
    {
        name: "Rajesh Singh",
        loc:"Taj Mahal",
        img: "https://th.bing.com/th/id/OIP.bTQH9-GsRdSD_P5u2zpU4QHaHD?w=701&h=667&rs=1&pid=ImgDetMain"
    },
    {
        name: "Vikram Kumar",
        loc: "Gateway of India, Mumbai",
        img: "https://www.tajmahaltourguidefamilygroup.com/tour-guides/images/tour-guides/yusuf-khan.jpg"
    },
    {
        name: "Pooja Patel",
        loc: "Mysore Palace",
        img: "https://th.bing.com/th/id/OIP.FqIg260l5ap1vJlS_WXpoQHaE8?rs=1&pid=ImgDetMain"
    },
    {
        name: "Anita Sharma",
        loc: "Charminar, Hyderabad",
        img: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://www.visit-goa.com/wp-content/uploads/2020/12/Goa-travel-guide-for-the-first-time-travellers-400x267.jpg"
    },
    {
        name: "Ravi Gupta",
        loc: "Meenakshi Temple, Madurai",
        img: "https://www.holidify.com/images/bgImages/WEST-BENGAL.jpg"
    },
    {
        name: "Neha Jain",
        loc:"Hawa Mahal, Jaipur",
        img: "https://th.bing.com/th/id/OIP.bTQH9-GsRdSD_P5u2zpU4QHaHD?w=701&h=667&rs=1&pid=ImgDetMain"
    },
    {
        name: "Pooja Patel",
        loc: "Mysore Palace",
        img: "https://th.bing.com/th/id/OIP.FqIg260l5ap1vJlS_WXpoQHaE8?rs=1&pid=ImgDetMain"
    },
    {
        name: "Anita Sharma",
        loc: "Charminar, Hyderabad",
        img: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://www.visit-goa.com/wp-content/uploads/2020/12/Goa-travel-guide-for-the-first-time-travellers-400x267.jpg"
    },
    {
        name: "Ravi Gupta",
        loc: "Meenakshi Temple, Madurai",
        img: "https://www.holidify.com/images/bgImages/WEST-BENGAL.jpg"
    },
    {
        name: "Neha Jain",
        loc:"Hawa Mahal, Jaipur",
        img: "https://th.bing.com/th/id/OIP.bTQH9-GsRdSD_P5u2zpU4QHaHD?w=701&h=667&rs=1&pid=ImgDetMain"
    },
    {
        name: "Pooja Patel",
        loc: "Mysore Palace",
        img: "https://th.bing.com/th/id/OIP.FqIg260l5ap1vJlS_WXpoQHaE8?rs=1&pid=ImgDetMain"
    },
    {
        name: "Anita Sharma",
        loc: "Charminar, Hyderabad",
        img: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://www.visit-goa.com/wp-content/uploads/2020/12/Goa-travel-guide-for-the-first-time-travellers-400x267.jpg"
    },
    {
        name: "Ravi Gupta",
        loc: "Meenakshi Temple, Madurai",
        img: "https://www.holidify.com/images/bgImages/WEST-BENGAL.jpg"
    },
    {
        name: "Neha Jain",
        loc:"Hawa Mahal, Jaipur",
        img: "https://th.bing.com/th/id/OIP.bTQH9-GsRdSD_P5u2zpU4QHaHD?w=701&h=667&rs=1&pid=ImgDetMain"
    },
    {
        name: "Pooja Patel",
        loc: "Mysore Palace",
        img: "https://th.bing.com/th/id/OIP.FqIg260l5ap1vJlS_WXpoQHaE8?rs=1&pid=ImgDetMain"
    },
    {
        name: "Anita Sharma",
        loc: "Charminar, Hyderabad",
        img: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://www.visit-goa.com/wp-content/uploads/2020/12/Goa-travel-guide-for-the-first-time-travellers-400x267.jpg"
    },
    {
        name: "Ravi Gupta",
        loc: "Meenakshi Temple, Madurai",
        img: "https://www.holidify.com/images/bgImages/WEST-BENGAL.jpg"
    },
    {
        name: "Neha Jain",
        loc:"Hawa Mahal, Jaipur",
        img: "https://th.bing.com/th/id/OIP.bTQH9-GsRdSD_P5u2zpU4QHaHD?w=701&h=667&rs=1&pid=ImgDetMain"
    },
];

const Summary = ["Inclusions", "Exclusions", "Gallery"]

const things = [
    "Waterproof and good quality backpack or rucksack is recommended.",
    "Warm woolen jackets, air/water resistant jackets, and extra pairs of socks to protect yourself from wind chill and low temperatures.",
    "Sunglasses, comfortable clothing and sports shoes .",
    "Personal water bottle (avoid single-use plastic) to avoid dehydration issues.",
    "Quick snacks for journeys like dry fruits, biscuits, chocolates, energy bars, glucose sachets.",
    "Personal care items, sunscreen lotion (prefer SPF30 or higher), lip balm, toiletries, wet wipes and hand sanitizer.",
    "Medicines for motion sickness, fever, stomach ache, headache, cold, body pain and any other personal medicines.",
    "Phone, camera, power banks and photography equipment.",
    "ATMs and an adequate amount of cash for shopping, cafe visits.",
    "Personal identity document like Aadhaar, driving licence, passport, etc, since it is needed for security checks.",
];

const Amenities = [
    {
        name:"Restaurant",
        icon:"restaurant"
    },
    {
        name:"Pool",
        icon:"pool"
    },
    {
        name:"GYM",
        icon:"fitness_center"
    },
    {
        name:"Wifi",
        icon:"wifi"
    },
    {
        name:"Bar",
        icon:"local_bar"
    },
    // {
    //     name:"Wifi",
    //     icon:"wifi"
    // },
]

const imagesHt = [
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/83a9716b.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 1' },
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/64fbbb65.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 2' },
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/71590839.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 3' },
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/82f31941.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 4' },
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/82f31941.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 5' },
    { src: 'https://images.trvl-media.com/lodging/6000000/5440000/5435200/5435190/82f31941.jpg?impolicy=resizecrop&rw=1200&ra=fit', alt: 'Image 6' },
];

const imagesPlace = [
    { src: 'https://www.godigit.com/content/dam/godigit/directportal/en/contenthm/karnal.jpg', alt: 'Image 1' },
    { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-nIZIa--Bu25pybmdekU88xDtJCqwA-Gqsfi5lHugN2N_JGaYvDDJjy8J3GZWzWhFQs8&usqp=CAU', alt: 'Image 2' },
    { src: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/00/d5/bf/visit-india-tours-sightseeing.jpg?w=300&h=300&s=1', alt: 'Image 3' },
    { src: 'https://sarkarilist.in/wp-content/uploads/2021/09/kingdom-of-dreams-gurugram-haryana-1200x672.png', alt: 'Image 4' },
    { src: 'https://www.indiatravelblog.com/attachments/Resources/3754-3-Tourist-places-in-gurgaon.jpg', alt: 'Image 5' },
    { src: 'https://assets-news.housing.com/news/wp-content/uploads/2022/09/01122158/Haryana2.png', alt: 'Image 6' },
    { src: 'https://www.godigit.com/content/dam/godigit/directportal/en/contenthm/karnal.jpg', alt: 'Image 1' },
    { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-nIZIa--Bu25pybmdekU88xDtJCqwA-Gqsfi5lHugN2N_JGaYvDDJjy8J3GZWzWhFQs8&usqp=CAU', alt: 'Image 2' },
    { src: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/00/d5/bf/visit-india-tours-sightseeing.jpg?w=300&h=300&s=1', alt: 'Image 3' },
    { src: 'https://sarkarilist.in/wp-content/uploads/2021/09/kingdom-of-dreams-gurugram-haryana-1200x672.png', alt: 'Image 4' },
    { src: 'https://www.indiatravelblog.com/attachments/Resources/3754-3-Tourist-places-in-gurgaon.jpg', alt: 'Image 5' },
    { src: 'https://assets-news.housing.com/news/wp-content/uploads/2022/09/01122158/Haryana2.png', alt: 'Image 6' },
];

const tickets = [
    {
        id: '#442115',
        subject: 'Async problem',
        status: 'closed',
        lastUpdated: 'Sunday, June 9th, 2024 (23:46)'
    },
    {
        id: '#360442',
        subject: '503 Server Unavailable',
        status: 'active',
        lastUpdated: 'Sunday, June 9th, 2024 (13:52)'
    },
    {
        id: '#547800',
        subject: "I'm not able to install Npm INstall",
        status: 'closed',
        lastUpdated: 'Saturday, June 8th, 2024 (18:40)'
    }
];

const options = [
    { value: 1, text: 'Privacy Violation (sharing personal information)' },
    { value: 2, text: 'Advertising another website' },
    { value: 3, text: 'Fake project posted' },
    { value: 4, text: 'Obscenities or harassing behaviour' },
    { value: 5, text: 'Non full time project posted requiring abnormal bidding' },
    { value: 6, text: 'Other' },
    { value: 7, text: 'Vendor did not read project description' },
    { value: 8, text: 'Unclear or does not provide enough information' },
    // { value: 9, text: 'Contains contact information' },
    { value: 9, text: 'Fake News or Misinformation' },
    { value: 10, text: 'Child Sexual Abuse Material' },
    { value: 11, text: 'Fraud/Scam' },
    { value: 12, text: 'Privacy Violation (sharing personal information)' },
    { value: 13, text: 'Other' },
  ];

export { Vehicle, Hotels, Country, TripCategories, States, lehLadakhTags, Summary, things, PkgList, Amenities, imagesHt, GuiderList, imagesPlace, tickets, options }