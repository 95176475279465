import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { dec, enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { openInNewTab, useCountryGet } from '../../Componets/CommonFs';
import { useLocalStorage } from 'react-fu-hooks';
import { GridCategories, HowItWork } from '../../Componets/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { svTripCate } from '../../ContextRedux/Action';
import MessagingPopup from './MessagingPopup';
import { Loader } from '../../Componets/Loader';
import { Spin } from 'antd';
import { Helmet } from 'react-helmet';
import FaqSection from '../../Componets/FaqSection';
import SignInModal from '../Auth/SignInModal';
import { MemContext } from '../../ContextRedux/Context';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;
const vendor_url = process.env.REACT_APP_VENDOR_URL;

const responsive_blog = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 6,
        slidesToSlide: 6
    },
    desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_review = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 2000, min: 1050 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1050, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_pkg = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_type = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 11,
        slidesToSlide: 8
    },
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 7
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 7,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 5,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 559 },
        items: 4,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 2
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 2,
        slidesToSlide: 3
    },
};

const responsive_8 = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2200 },
        items: 7,
        slidesToSlide: 7
    },
    tablet: {
        breakpoint: { max: 2200, min: 968 },
        items: 5,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 967, min: 759 },
        items: 4,
        slidesToSlide: 4
    },
    xmobile: {
        breakpoint: { max: 759, min: 549 },
        items: 3,
        slidesToSlide: 3
    },
    xsmobile: {
        breakpoint: { max: 549, min: 0 },
        items: 2,
        slidesToSlide: 2
    },
};

const categories = [
    { id: 1, name: "Holiday Packages", imageUrl: "/images/package.jpg", route: 'packages' },
    { id: 2, name: "Hotels", imageUrl: "/images/hotel.jpg", route: 'hotels' },
    { id: 3, name: "Vehicle", imageUrl: "/images/taxi.webp", route: 'vehicle-search' },
    { id: 4, name: "Guiders", imageUrl: "/images/Bag.webp", route: 'guiders' },
];

function Home() {
    const { country1 } = useCountryGet();
    const { unId } = useContext(MemContext);


    const carouselRef1 = useRef(null);
    const carouselRef3 = useRef(null);
    const carouselRef4 = useRef(null);
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setopen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blogs, setBlogs] = useState([])
    const [countries, setCountries] = useState([])
    const [hotelType, setHotelType] = useState([])
    const [remhotelType, setRemHotelType] = useState([])
    const [tripCate, setTripCate] = useState([])
    const [vehicleType, setVehicleType] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [locations, setLocations] = useState([])
    const [randomPkg, setRandomPkg] = useState([])
    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const user1 = useSelector(state => state.data.info.user)


    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                }
            });
        }
    }, []);


    const getDetails = () => {
        setIsLoading(true)
        const send = {
            countryName: country1
        }

        const enc1 = enc(send, key)
        CommonAPI(enc1, 'POST', `home`, responseBack)
    }

    const responseBack = useCallback((res) => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);

            setBlogs(dec1?.blog);
            setRandomPkg(dec1?.randmPackage);
            setCountries(dec1?.country);
            setTripCate(dec1?.tripcategories);
            setHotelType(dec1?.hoteltype);
            setRemHotelType(dec1?.remHoteltype);
            setVehicleType(dec1?.vehicletype);
            setTestimonial(dec1?.testimonial);
            setLocations(dec1?.popularloc);

            dispatch(svTripCate(dec1?.tripcategories))
        } else {
        }
        setIsLoading(false)
    })

    const handlePrev = (type) => {
        if (type === 'pkg') {
            if (carouselRef1) {
                carouselRef1.current.previous();
            }
        } else if (type === 'blogs') {
            if (carouselRef3) {
                carouselRef3.current.previous();
            }
        } else {
            if (carouselRef4) {
                carouselRef4.current.previous();
            }
        }
    }

    const handleNext = (type) => {
        if (type === 'pkg') {
            if (currentIndex1 < tripCate.length - 1) {
                carouselRef1.current.next();
            }
        } else if (type === 'blogs') {
            if (currentIndex3 < blogs.length - 1) {
                carouselRef3.current.next();
            }
        } else {
            if (currentIndex4 < testimonial.length - 1) {
                carouselRef4.current.next();
            }
        }

    }

    const handleCallback = async (value, type) => {
        if (type === 'categoryId') {
            const update = { cID: '66a1e7b2e3e1ced4992a2617', month: [], location: '', categoryId: [value], cName: 'India' }
            localStorage.setItem('searchParams', JSON.stringify(update));
            goToPkgList()
        }
    }

    const goToPkgList = (type) => {
        const pkgHome = localStorage.getItem('searchParams');
        const pkgHomeParams = JSON.parse(pkgHome)

        openInNewTab('/packages/list', pkgHomeParams)
    }

    const handleSave = (value, type) => {

        if (type === 'location') {
            setHotelLocation(value);
        } else {
            setHotelId(value);
        }
        const newWindow = window.open(`/hotels`, '_blank')


        if (newWindow) { newWindow.opener = null; newWindow.rel = 'noopener noreferrer'; }
    };


    const [isModalVisible, setModalVisible] = useState(false);


    const videoUrl = "https://www.youtube.com/embed/lqa_y0hV_WI?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Ftravellinglark.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1";


    // const customClasses ={
    //     'h-88px': '88px',
    // }

    // injectCustomCSS(customClasses)

    useEffect(() => {
        const handleShow = (ty) => {
            if (!unId) {
            setTimeout(() => {
                setIsModalOpen(true);
            }, 10000);
        }

        };

        handleShow()
    }, [])


    return (
        <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center bg-gray'>
            <div className='w_92 bg-succes1s mb-4 mt-5 d-flex align-items-center flex-column'>


                {/* Section 1 */}
                <Row className="bg-warning1 w-100">
                    <Col xxl={8} xl={7} lg={7} md={6} className="bg1-warning d-flex align-items-center flex-column justify-content-center pb-2">
                        <div className='bg1-secondary d-flex align-items-center flex-column  justify-content-center'>
                            <div className='Inter-Bold fs_40'>Say Goodbye To Endless  Searching!</div>
                            <div className='Inter-Regular fs_14 text-secondary mt-2'>
                                Post your travel details and budget, and let verified vendors come to you with tailored offers that suit your style and financial needs. Your next adventure is just a post away!
                            </div>

                            <div className='d-flex align-items-center bg1-danger pointer justify-center-xxl w-100 mt-3'>
                                <div className='mt-3 bg_thm h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => navigate('/post-travel')}>
                                    <p className='Rubik-Regular fs_12 text-white m-0'>Post Your Travel</p>
                                </div>
                                <div className='mt-3 bg-transparent border_thm pointer h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => setModalVisible(true)}>
                                    <p className='Rubik-Regular fs_12 text_thm  m-0'>How it works</p>
                                    <i className="fa-solid fa-play bg_thm rd_50 text-white p-1 fs_10 ms-2"></i>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className='bg-danger1 px-0'>
                        <img src={`/images/illus.webp`} alt='travel' className='img-fluid  h_380px' />
                    </Col>
                </Row>

                {isModalVisible && (
                    <div className="modalOverlay" onClick={() => setModalVisible(false)}>
                        <div className="modalContent" onClick={e => e.stopPropagation()}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <iframe
                                        frameBorder="0"
                                        allowFullScreen
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        title="Winter Spiti - An Unforgettable Journey"
                                        width="100%"
                                        height="100%"
                                        src={isModalVisible ? videoUrl : ""}
                                    />
                                </div>
                            </div>
                            <button className="closeButton" onClick={() => setModalVisible(false)}>Close</button>
                        </div>
                    </div>
                )}

                {/* Category Section */}
                <div className='d-flex align-items-center mt-6'>
                    <div className='Inter-Bold fs_24  flex-grow-1'>Explore Travel by Category</div>
                </div>

                <div className='Inter-Regular fs_14 text-secondary text-center mt-2'>
                    Discover the best travel experiences, perfectly categorized for your convenience.
                </div>


                <div className='w-100 d_flex_align_justify_center flex-wrap mt-2 column-gap-4'>
                    {categories && categories?.map((item, index) => {
                        return (
                            <div key={index} className='mt-3 mb-4 rd_6 pointer'>
                                <Link to={`/${item.route}`} className='text-decoration-none hover-me d-flex align-items-center flex-column'>
                                    <img alt={item?.name} src={item?.imageUrl} className='h-150px mx-1 w_120px rd-70px' />

                                    <div className='NunitoSans-SemiBold fs_14 text_dark_medium text-center mt-2'>{item?.name}</div>
                                </Link>
                            </div>
                        )
                    })}
                </div>

                {/* Map */}
                <div className='Inter-Bold fs_24 mt-3'>How it's works</div>

                <div className='bg-warning1 w-100 mt-2 d_flex_align_justify_center'>
                    <img src='/images/F1.webp' className='img-fluid h-520px h-380px--md h-280px--xs-g w-100-md w_70' alt='World Map' />
                </div>

                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>Travel Agency Near Me | Custom Made Holiday Plans | Travellinglark</title>
                    <meta name="description" content="Discover Top Travel Agencies Near You With Travellinglark! Get Custom-made Holiday Plans Tailored to Your Needs. Explore, Compare, and Book Your Dream Trip Today." />
                    <meta name="keywords" content="Travel Platforms in India, Custom Made Holiday Plans, Tour Operators Near Me, Travel Agency Near Me" />
                    <link rel="canonical" href="https://www.travellinglark.com/" />
                </Helmet>

                {/* Country Section */}
                <div className='Inter-Bold fs_24 text-center'>Explore Popular Countries to Visit</div>

                <div className='Inter-Regular fs_14 text-secondary mt-2 text-center'>
                    Discover countries with rich cultures, scenic views, and unforgettable experiences.
                </div>
            </div>

            <div className='w_92 mt-0'>
                {isLoading ? (
                    <Loader type={'country'} />
                ) : (
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive_8}
                        ssr={true}
                        autoPlay
                        infinite
                        autoPlaySpeed={2500}
                        keyBoardControl={true}
                        customTransition="transform 1000ms ease-in-out"
                        containerClass="carousel-container"
                        itemClass="carousel-item1"
                        customLeftArrow={
                            <CustomLeftArrow
                                direction="left"
                                mt={'mt-30px'}
                            />
                        }
                        customRightArrow={
                            <CustomLeftArrow
                                direction="right"
                                mt={'mt-30px'}
                            />
                        }
                        dotListClass="custom-dot-list-style"
                    >
                        {countries && countries?.map((item, index) => {

                            return (
                                <div key={index} className="mb-4 position-relative bg-white rd_30 mx-2-5 ">
                                    <Link to={`/country/packages/${item.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none w-100'>
                                        <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                                        <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                            top: 0,
                                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                        }}>
                                            <div className='NunitoSans-Medium fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </Carousel>
                )}
            </div>

            <div className='w_92 bg-succes1s mb-5 mt-4 d-flex align-items-center flex-column'>

                {/* Package Cate Section */}
                <div className='Inter-Bold fs_20 mb-2 flex-grow-1 mt-3 fs-xs-16'>Explore Packages by Theme</div>

                <div className='w-100'>
                    {isLoading ? (
                        <Loader type={'category'} mt={'mt-2'} />
                    ) : (
                        <GridCategories handleChange={handleCallback} tripCate={tripCate || []} />
                    )}
                </div>

                <h1 className="mb-1 Rubik-SemiBold fs-20px mt-40px fs-xs-16">Custom Made Holiday Plans</h1>
                <h2 className="mb-1 Rubik-Regular fs-15px mt-5px text_gray mb-10px text-center">Experience travel, your way – Personalized holiday plans for every destination and desire!</h2>
                <div className='w-100'>
                    {isLoading ? (
                        <Loader type={'pkg'} mt={'mt-4-5'} />
                    ) : (
                        randomPkg && randomPkg.length > 0 ? (
                            <>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    responsive={responsive_pkg}
                                    ssr={true}
                                    infinite
                                    keyBoardControl={true}
                                    customTransition="transform 1000ms ease-in-out"
                                    containerClass="carousel-container"
                                    itemClass="carousel-item1 "
                                    customLeftArrow={
                                        <CustomLeftArrow
                                            direction="left"
                                        />
                                    }
                                    customRightArrow={
                                        <CustomLeftArrow
                                            direction="right"
                                        />
                                    }
                                    removeArrowOnDeviceType={["desktop1", "desktop",]}
                                    dotListClass="custom-dot-list-style"
                                >
                                    {randomPkg && randomPkg?.slice(0, 4).map((item, index) => {
                                        const lowPrice = item.discountPrice
                                        const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                        const vendor = item?.vendorId

                                        return (
                                            <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd-10px mx-2-5 pointer shadow-slowclean p-5px pb-0px' style={{ backgroundColor: '#fff' }}>
                                                <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package' }} className='text-decoration-none hover-me '>
                                                    <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100p rd-10px' />

                                                    <div className='p-2-5'>
                                                        <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                        <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId[0]?.business_name}</span></p>

                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div>
                                                                <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                                                <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency[0]?.code}{final}</strong>/Person</div>
                                                                {lowPrice != null && lowPrice > 0 && (
                                                                    <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency[0]?.code}{item.price}</div>
                                                                )}
                                                            </div>
                                                            <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </>
                        ) : (
                            <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                                <img src='/images/not_data.webp' alt='No month packages available' className='img-fluid h_130px w_200px' />
                                <div className='Rubik-Regular text_gray2 mt-2'>No month packages available</div>
                            </div>
                        )
                    )
                    }
                </div>

                {/* Why Choose Section */}
                <Row className="bg-warning1 w-100 mt-6">
                    <Col className='bg-1danger px-0'>
                        <img src={`/images/illus1.webp`} alt='Why choose us' className='img-fluid h_400px h-lg-380px' />
                    </Col>
                    <Col xl={7} lg={7} md={6} className="bg1-warning  pb-2">
                        <div className='bg1-secondary'>
                            <div className='Inter-SemiBold fs_29'>Why Choose TravellingLark?</div>
                            <div className='Inter-Medium fs_14 text_gray mt-2'>
                                Experience seamless travel planning with TravellingLark. From discovering exclusive travel packages and premium hotels to booking reliable vehicles and connecting with expert travel guides, we ensure your journey is unforgettable and stress-free.
                            </div>

                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg-white bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white w-auto px-10px d-align-justify-center h-55px'>
                                    <img src={`/icons/tour-guide.png`} alt='tour' className='h-60p w-35px bg-white' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Tailored Tour Packages</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Explore custom-designed travel packages curated by experienced vendors to fit your preferences and create unique travel memories. </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center p-2-5 rd_6 bg1-danger shadow-clean-hover w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white p-2 hw_50px' >
                                    <img src={`/icons/hotel.png`} alt='hotel' className='h-70p w-25px bg-white' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Unforgettable Hotel Stays</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Enjoy handpicked accommodations with exceptional offers and trusted reviews at top-rated hotels across the globe. </div>
                                </div>
                            </div>
                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white w-auto px-10px d-align-justify-center h-55px'>
                                    <img src={`/icons/car.png`} alt='vehicle' className='h-60p w-35px bg-white' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Effortless Vehicle Rentals</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Access a wide range of affordable and convenient vehicle rentals with simple booking processes and 24/7 customer support.</div>
                                </div>
                            </div>
                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white w-auto px-10px d-align-justify-center h-55px'>
                                    <img src={`/icons/travel-guide.png`} alt='trip' className='h-60p w-35px bg-white' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Expert Travel Guides</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Connect with professional travel guides for enriching local experiences and hassle-free exploration.</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>


                {/* Top Featured Hotels Section */}
                <div className='d-flex align-items-center flex-column mt-5 w-100'>
                    <div className='Inter-Bold fs_24 text-center'>Explore Stays by Property Type</div>
                </div>
                <div className='d-flex align-items-center mt-1 pb-3 scrollable-container'>
                    {locations?.slice(0, 10).map((loc, index) => (
                        <div
                            key={index}
                            onClick={() => handleSave(loc.name, 'location')}
                            className={`mt-2 pointer fs_13 p-1 rounded-5 mx-1 text-nowrap px-3 Sansation_Regular text_gray2 border_white brc-secondary text-capitalize `}
                        >
                            {loc.name}
                        </div>
                    ))}
                </div>

                {isLoading ? (
                    <div className='w-100'>
                        <Loader type={'country'} />
                    </div>
                ) : (
                    <Row className='mt-4 w-100'>

                        <Col xl={3} lg={3} md={4} sm={5} xs={12} className="bg-warning1 mb-xs1-20">
                            <div className="mb-4 position-relative rd_30" onClick={() => handleSave(hotelType[0]?._id, '')}>
                                <img src={hotelType?.[0]?.image ? `${img_base_url + hotelType?.[0]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[0]?.name} className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[0]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative rd_30" onClick={() => handleSave(hotelType?.[1]?._id, '')}>
                                <img src={hotelType?.[1]?.image ? `${img_base_url + hotelType?.[1]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[1]?.name} className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[1]?.name}</div>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} lg={3} md={4} sm={7} className="bg-secondary1 mt">
                            <div className="mb-4 position-relative h-100 rd_30" onClick={() => handleSave(hotelType?.[2]?._id, '')}>
                                <img src={hotelType?.[2]?.image ? `${img_base_url + hotelType?.[2]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[2]?.name} className='rd_15 w-100 pointer h-100' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[2]?.name}</div>
                                </div>
                            </div>
                        </Col>

                        <Col className="bg-warning1 d-flex align-items-center flex-wrap">
                            <div className="mb-4 mt-4-md me-2-md me-0-xs position-relative w-100-xs rd_30 w-100 w-49-md" onClick={() => handleSave(hotelType?.[3]?._id, '')}>
                                <img src={hotelType?.[3]?.image ? `${img_base_url + hotelType?.[3]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[3]?.name} className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[3]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative rd_30 w_49 me-md-2 w-100-xs w-100-lg w-49-md" onClick={() => handleSave(hotelType?.[4]?._id, '')}>
                                <img src={hotelType?.[4]?.image ? `${img_base_url + hotelType?.[4]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[4]?.name} className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>

                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[4]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative d-none-on-lg rd_30 w_49 d-inline-flex" onClick={() => handleSave(hotelType?.[5]?._id, '')}>
                                <img src={hotelType?.[5]?.image ? `${img_base_url + hotelType?.[5]?.image}` : `/images/hotel_1.jpg`} alt={hotelType?.[5]?.name} className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[5]?.name}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {isLoading ? (
                    <div className='w-100'>
                        <Loader type={'country'} />
                    </div>
                ) : (
                    <div className='w-100 mt-4'>
                        <Carousel
                            ref={carouselRef1}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex1(currentSlide)}
                            responsive={responsive_type}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={true}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                        >
                            {remhotelType && remhotelType?.map((item, index) => {

                                return (
                                    <div key={item._id} className='mt-3 mb-4 rd_6 mx-2 pointer w-xxs-90 hover-me d-flex align-items-center flex-column' onClick={() => handleSave(item?._id, '')}>
                                        <img alt={item?.name} src={item?.image ? `${img_base_url + item?.image}` : `/images/ocean.png`} className='h_130px rd_10 w_120px w-xxs-99' />

                                        <div className='NunitoSans-SemiBold fs_14 w-100 text_dark_medium text-center mt-2'>{item?.name}</div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    </div>
                )}

                {/* Travel Point */}
                <Row className="bg-warning1 w-100 mt-6 row-gap-4">
                    <Col xxl={7} xl={7} lg={6} md={12} className="bg1-warning  pb-2 order-sm-2">
                        <div className='Inter-SemiBold fs-26px mt-10px'>Grow Your Business with TravellingLark – Join Us Today!</div>
                        <p className='Inter-Medium fs_14 mt-2 gradient-text-voilet m-0'>Travel Agency, Hotels B&B HomeStays Spa Igloo etc, Vehicles/Cabs, Photographer, Visa Consultancy, Activities, All type of travel agents</p>
                        <div className='Inter-Medium fs_14 text_gray mt-2'>Reach more customers, showcase your services, and expand your business with zero hassle.</div>

                        <h3 className='fs-14px Inter-Reguar text-muted mt-20px'><strong>• Free Listings:</strong> List your travel packages, hotels, and services for free. </h3>
                        <h3 className='fs-14px Inter-Reguar text-muted mt-20px'><strong>• Verified Travel Leads:</strong> Get quality leads from travelers actively planning trips.</h3>
                        <h3 className='fs-14px Inter-Reguar text-muted mt-20px'><strong>• Direct Communication:</strong>  Connect directly with potential customers through our platform.</h3>
                        <h3 className='fs-14px Inter-Reguar text-muted mt-20px'><strong>• No Commissions (For Now):</strong> Keep 100% of your earnings—no commission charges.</h3>
                        <h3 className='fs-14px Inter-Reguar text-muted mt-20px'><strong>• Showcase Your Services:</strong> Highlight your offerings with detailed descriptions and photos.</h3>

                        <Link className='mt-3 bg_thm h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' rel='noopener noreferrer' to={vendor_url} target='_blank'>
                            <p className='Rubik-Regular fs_12 text-white m-0'>Sign Up for Free</p>
                        </Link>
                    </Col>
                    <Col xxl={5} xl={5} lg={6} md={0} className='bg-1danger px-0 order-sm-1'>
                        <img src={`/images/international.webp`} alt='Why choose us' className='remove-image-bg-white img-fluid h-450px h-380px--md h-350px--sm h-300px--xs-g  d-hide-on-md' />
                    </Col>
                </Row>

                {/* Review Section */}
                {testimonial && testimonial.length > 0 &&
                    <div className='d-flex align-items-center mt-7 w-100'>
                        <div className='Inter-Bold fs_20  flex-grow-1'>Our Clients Review</div>
                        <div className='bg-warning1 d-flex'>
                            <div onClick={() => handlePrev('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 ${currentIndex4 === 0 ? 'border border-dark' : 'bg-gradient-dark'}`}>
                                <i className='fas fa-angle-left' style={{ color: currentIndex4 === 0 ? '#000' : '#fff' }}></i>
                            </div>
                            <div onClick={() => handleNext('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 bg-gradient-dark`}>
                                <i className='fas fa-angle-right' style={{ color: '#fff' }}></i>
                            </div>
                        </div>
                    </div>}

                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef4}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex4(currentSlide)}
                            responsive={responsive_review}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                        >
                            {testimonial && testimonial?.map((item, index) => {
                                return (
                                    <div key={item._id} className="reviews-box bg-white mb-4 mt-3 p-2-5 me-3 shadow-slowclean rd_10">
                                        <img src='/icons/review_user.png' alt={item.name} className='rd_6 hw_55px' />
                                        <h5 className='Source_SemiBold spacing m-0 mt-3'>{item.name}</h5>
                                        <div className='NunitoSans-Regular text_gray fs_12 text-center m-0 mt-2'>
                                            {item.message}
                                        </div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>



                {/* Blogs Section */}
                <div className='d-flex align-items-center mt-5 ms-1 w-100'>
                    <div className='Inter-Bold fs_20  flex-grow-1'>Blogs</div>
                    {/* <div className='bg-warning1 d-flex'>
                        <div onClick={() => handlePrev('blogs')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 ${currentIndex3 === 0 ? 'border_thm' : 'bg-gradient-thm'}`}>
                            <i className={`fas fa-angle-left ${currentIndex3 === 0 ? 'text_thm' : 'text-white'}`}></i>
                        </div>
                        <div onClick={() => handleNext('blogs')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 bg-gradient-thm`}>
                            <i className='fas fa-angle-right' style={{ color: '#fff' }}></i>
                        </div>
                    </div> */}
                    <div className='d-flex align-items-center pointer' onClick={() => navigate('/blogs')}>
                        <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                        <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                </div>


                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef3}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex3(currentSlide)}
                            responsive={responsive_blog}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                        // arrows={false}
                        >
                            {blogs && blogs?.map((item) => {
                                return (
                                    <div key={item._id} className="reviews-box h_280px bg-white mb-4 mt-3 d-flex flex-column mx-2 shadow-clean position-relative rd_10 hover_btt ">
                                        <Link to={`/blog/${item.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none hover-me d-flex justify-content-center flex-column'>
                                            <img src={`${img_base_url + item?.image}`} alt={item.name} className='rd_tl_tr_6 w-100 h_165px pointer' />
                                            <h5 className='Source_SemiBold text_gray fs_15 px-15px fs-xs-14 spacing pointer m-0px mt-10px text-capitalize mini-line-1'>{item.title}</h5>
                                            <h5 className='Inter-Regular text-secondary fs_12 px-15px spacing pointer m-0 mt-2 text-capitalize mini-line-1 two'>{item.sortDescription}</h5>
                                            <p className='m-0 Source_Regular px-15px d-flex align-items-center mt-2-5 text_thm fs_14 pointer move_ltr pb-2'>Read More <i className='fa-solid fa-angle-right ms-2 fs_12'></i></p>
                                        </Link>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>

                <div className='Inter-Bold fs-22px  flex-grow-1 mt-50px w-95p text-center'>Frequently Asked Questions</div>

                <FaqSection />


                <p className='fs-13px Inter-Regular mt-70px'>
                    Discover customized holiday plans, unique travel packages, and expert travel agencies near you with TravellingLark. Connect with experienced tour operators to craft personalized travel itineraries tailored to your preferences. Find local travel agencies offering exclusive deals on hotels, travel vehicles, and guides. Post your travel leads and receive competitive proposals from top vendors. Explore bespoke holiday ideas, group tours, family vacations, and honeymoon packages curated by professional travel planners. Whether you seek adventure tours, luxury escapes, or budget-friendly trips, our platform helps you find the perfect travel solution. Search for travel services, tour operators, and holiday planners near you to make your journey unforgettable.
                </p>
            </div>

            {/* <GoogleTranslate/>
            </> */}


            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

            <HowItWork isVisible={open} onClose={setopen} />
        </div>
    )
}

export default Home