import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Router, RouterProvider } from 'react-router-dom';
// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'future-tint/dist/common.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import './CSS/common.css'
import './CSS/Fonts.css'
import './CSS/Footer.css'
import './CSS/Index.css'

// Components
import RouterIndex from './Routes/RouterParent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Context from './ContextRedux/Context';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { store } from './ContextRedux/Store';
import { NotificationProvider } from './ContextRedux/NotificationContext';
import { LoadScript } from '@react-google-maps/api';
import { ChatPopupProvider } from './ContextRedux/ChatPopupContext';
import ShowWlcmModal from './Componets/ShowWlcmModal';
import BottomNavigation from './Routes/BottomNavigation';

const g_id = process.env.REACT_APP_G_ID;
const g_map_key = process.env.REACT_APP_G_MAP_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then((registration) => {
    })
    .catch((error) => {
    });
}
const libraries = ['places'];


root.render(
  <Provider store={store}>
    <Context>

      <NotificationProvider>
        <ChatPopupProvider>
          <GoogleOAuthProvider clientId={g_id}>
            <LoadScript googleMapsApiKey={g_map_key} libraries={libraries} loadingElement={<div style={{ display: 'none' }}></div>}>
              {/* <React.StrictMode> */}
              <BrowserRouter>
              <RouterIndex/>
              </BrowserRouter>
              {/* <RouterProvider router={RouterParent} /> */}
              <Toaster />
              <ShowWlcmModal />
              {/* </React.StrictMode> */}
            </LoadScript>
          </GoogleOAuthProvider>
        </ChatPopupProvider>
      </NotificationProvider>
    </Context>
  </Provider>
);

// {/* <UserDetail /> */}
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if (module.hot) {
//   module.hot.accept();
// }